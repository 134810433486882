const initialState = {
  cartCourses: null, // Set an initial theme mode
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COURSEDATA":
      return {
        ...state,
        cartCourses: action.payload,
      };
    default:
      return state;
  }
};

export default cartReducer;
