import React, { useState, useRef } from "react";
import "./certificate.css";
import { Form, Alert, Modal, Button } from "react-bootstrap";
import ChangeColor from "../../../assets/images/color-picker.png";
import { Link } from "react-router-dom";
import Preview from "./Preview";
import certificate from "../../../assets/images/GAA-Certificate.png";

import { Stage, Layer, Text, Line, Group, Image } from "react-konva";

import SettingsService from "../../../services/settings.service";
import { useEffect } from "react";
import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CertificateConfigure = () => {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);

  const [uploaded, setUploaded] = useState(false);

  const dragItem = useRef();
  const dragOverItem = useRef();
  const [elimaid, setElimaid] = useState("");

  const [selectedTemp, setSelectedTemp] = useState("");

  const [cerTemps, setCerTemps] = useState([]);

  const getCerTemps = () => {
    SettingsService.getCertTemps()
      .then((resp) => setCerTemps(resp.data))
      .catch((e) => console.log(e));
  };

  const [did, setDid] = useState("");

  const [uid, setUid] = useState("");

  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [chnageBg, setChangeBg] = useState("");

  const [applyCer, setApplyCer] = useState([]);

  const [cerTemp, setCerTemp] = useState("");

  const [certEle, setCertiEle] = useState({
    elementname: "",
    type: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const [certificateEles, setCertificateEles] = useState([]);

  const [addedElements, setAddedElements] = useState([]);

  const stageRef = useRef(null);

  const rectWidth = 100;
  const rectHeight = 100;

  const canvasRef = useRef(null);

  const certificateImage = new window.Image();

  certificateImage.src = certificate;

  const stageWidth = 700; // Width of the stage (adjust as needed)
  const stageHeight = 600; // Height of the stage (adjust as needed)

  const imageX = (stageWidth - 650) / 2; // Horizontal centering
  const imageY = (stageHeight - 550) / 2; // Vertical centering

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUid(user.user);

    if (user) {
      SettingsService.getCertificateElements(user.user)
        .then((response) => {
          setCertificateEles(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    getCerTemps();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCertiEle({ ...certEle, [name]: value });
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setCertiEle({ ...certEle, type: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);

      toast.warn("*Please fill all required fields.!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const data = {
      elementname: certEle.elementname,
      type: certEle.type,
    };

    if (data.elementname !== "" && data.type !== "") {
      SettingsService.addCertificateelements(uid, data)
        .then((response) => {
          toast.success("Certificate Element Added Successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            setCertiEle({
              elementname: "",
              type: "",
            });
            const user = AuthService.getCurrentUser();

            SettingsService.getCertificateElements(user.user)
              .then((response) => {
                setCertificateEles(response.data);
              })
              .catch((e) => {
                console.log(e);
              });
          }, 2000);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const deleteelement = (e) => {
    e.preventDefault();

    SettingsService.deleteCertificateElement(uid, did)
      .then((response) => {
        toast.success("Element deleted successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setShow(false);
        setCertificateEles(
          certificateEles.filter((certificateEle) => certificateEle.id !== did)
        );

        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [chnageBGShow, setChnageBGShow] = useState(false);
  const [presetTemp, setPresetTemp] = useState(false);
  const [uploadTemp, setUploadTemp] = useState(false);

  const handleChangeBGClose = () => setChnageBGShow(false);
  const handleChangeBGShow = (e) => {
    setChnageBGShow(true);
    // setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handlePresetTempClose = () => setPresetTemp(false);
  const handlePresetTempShow = (e) => {
    setPresetTemp(true);
    // setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleUploadTempClose = () => setUploadTemp(false);
  const handleUploadTempShow = (e) => {
    setUploadTemp(true);
    // setElimaid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleChangeBG = (e) => {
    // setSelectCertEle({ ...selectCertEle, [name]: e.target.files[0] });
    setChangeBg(e.target.files[0]);
    setUploaded(true);
  };

  const handleUploadTemp = (e) => {
    // setSelectCertEle({ ...selectCertEle, [name]: e.target.files[0] });
    setCerTemp(e.target.files[0]);
    setUploaded(true);
  };

  const selectTemp = (tempid) => {
    setSelectedTemp(tempid);
  };

  const chnageCerBg = () => {
    const formData = new FormData();

    formData.append("cerbg", chnageBg);

    setChnageBGShow(false);
  };

  const uploadTemplate = () => {
    const formData = new FormData();

    const user = AuthService.getCurrentUser();

    formData.append("certemp", cerTemp);
    formData.append("usp", user.user);

    SettingsService.uploadCertTemps(formData)
      .then((resp) => {
        toast.success("Certificate Template Uploaded Successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        getCerTemps();

        setUploadTemp(false);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="container-fluid certificate">
        <div className="row">
          <div className="col-xl-5 col-lg-5">
            <div className="box-outer">
              <div className="heading">
                <h1>Create Preset Certificate components:</h1>
              </div>
              <div className="addElements">
                <ToastContainer />
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicIndustry">
                    <Form.Label>Element Name</Form.Label>
                    <div className="input-box">
                      <Form.Control
                        type="text"
                        placeholder="Begin Typing...."
                        name="elementname"
                        value={certEle.elementname}
                        onChange={handleInputChange}
                        required
                      />
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 20.199 20.201"
                        >
                          <g
                            id="Group_11227"
                            data-name="Group 11227"
                            transform="translate(-2402.326 -6200.833)"
                          >
                            <path
                              id="Path_11890"
                              data-name="Path 11890"
                              d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                              transform="translate(752.656 5510.435)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              id="Path_11892"
                              data-name="Path 11892"
                              d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                              transform="translate(751.221 5511.583)"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicIndustry">
                    <Form.Label>Types</Form.Label>
                    <div className="flex">
                      <Form.Select
                        aria-label="Default select example"
                        name="type"
                        onChange={handleSelectChange}
                      >
                        <option>Select Types</option>
                        <option value="1">Text</option>
                        <option value="2">Image</option>
                        <option value="3">Dynamic Data</option>
                      </Form.Select>
                      <input
                        type="submit"
                        className="onboard-btn-f"
                        value="Enter"
                      />
                    </div>
                  </Form.Group>
                </Form>
              </div>
              <div className="addedElements">
                <div className="elements-flex elements-text">
                  {certificateEles.map((certificateEle, index) => (
                    <>
                      {certificateEle.type === "1" ? (
                        <>
                          <div className="item">
                            <span>{certificateEle.elementname}</span>
                            <div
                              className="deleteIcon"
                              onClick={handleShow}
                              data-bs-target={certificateEle.id}
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 16 16"
                                >
                                  <g
                                    id="incorrect"
                                    transform="translate(-42.855 468.853)"
                                  >
                                    <path
                                      id="Path_11894"
                                      data-name="Path 11894"
                                      d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </div>
                <div className="elements-flex elements-upload">
                  {certificateEles.map((certificateEle, index) => (
                    <>
                      {certificateEle.type === "2" ? (
                        <>
                          <div className="elements-list">
                            <div className="img-item">
                              <span>{certificateEle.elementname}</span>
                              <div
                                className="deleteIcon"
                                onClick={handleShow}
                                data-bs-target={certificateEle.id}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 16 16"
                                  >
                                    <g
                                      id="incorrect"
                                      transform="translate(-42.855 468.853)"
                                    >
                                      <path
                                        id="Path_11894"
                                        data-name="Path 11894"
                                        d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-xl-8 col-lg-12">
            <div className="box-outer">
              <div className="heading">
                <h1>Certificate Preview</h1>
              </div>
              <div className="certificate-preview mb-3">
                {/* <Preview /> */}
                <Stage width={stageWidth} height={stageHeight} ref={stageRef}>
                  <Layer>
                    <Image
                      x={imageX}
                      y={imageY}
                      image={certificateImage}
                      width={650}
                      height={500}
                    />
                  </Layer>
                </Stage>
              </div>
              <div className="elements-flex action-btn">
                <button className="item" onClick={handlePresetTempShow}>
                  <span>Preset Templates</span>
                </button>
                <button className="item" onClick={handleUploadTempShow}>
                  <span>Upload Templates</span>
                </button>
              </div>
              <div className="preview-act-flex">
                {/* <div className="preview-act flex-row">
                  <button className="act-btns" onClick={handleChangeBGShow}>
                    Change Background
                  </button>
                  <button className="act-btns" onClick={handlePresetTempShow}>
                    Set Pre-set Templates
                  </button>
                  <button className="act-btns" onClick={handleUploadTempShow}>
                    Upload Templates
                  </button>
                </div> */}
                {/* <div className="chnage-color">
                  <div className="flex-btn">
                    <div className="input-color">
                      <div className="color-area">
                        <input type="color" />
                        <img src={ChangeColor} alt="" />
                        <span>Change Color</span>
                      </div>
                      <div className="color-area">
                        <input type="file" />
                        <img src={ChangeColor} alt="" />
                        <span>Upload Image as Background</span>
                      </div>
                    </div>
                    <div className="flex-end">
                      <input
                        type="submit"
                        className="onboard-btn-f"
                        value="submit"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Element</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Element?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteelement}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={chnageBGShow} onHide={handleChangeBGClose} centered>
        <Modal.Header>
          <Modal.Title>Change Background</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleChangeBGClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form className="select-cerele">
            <Form.Group className="" controlId="formBasicName">
              <Form.Label>Upload File</Form.Label>
              <div className="input-box">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={handleChangeBG}
                />
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20.199 20.201"
                  >
                    <g
                      id="Group_11227"
                      data-name="Group 11227"
                      transform="translate(-2402.326 -6200.833)"
                    >
                      <path
                        id="Path_11890"
                        data-name="Path 11890"
                        d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                        transform="translate(752.656 5510.435)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_11892"
                        data-name="Path 11892"
                        d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                        transform="translate(751.221 5511.583)"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleChangeBGClose}>
            No
          </button>
          <button className="yes" onClick={chnageCerBg}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={presetTemp}
        onHide={handlePresetTempClose}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>View Preset Templates</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handlePresetTempClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="templates-list">
            {Array.isArray(cerTemps)
              ? cerTemps.map((cerTemp) => {
                  return (
                    <div
                      key={cerTemp.id}
                      className={
                        selectedTemp === cerTemp.id
                          ? "template-item active"
                          : "template-item"
                      }
                      onClick={(e) => selectTemp(e, cerTemp.id)}
                    >
                      <img
                        src={
                          "https://aeonlms.aeonsoftware.net/cit/" +
                          cerTemp.filepath
                        }
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handlePresetTempClose}>
            No
          </button>
          <button className="yes" onClick={handlePresetTempClose}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={uploadTemp} onHide={handleUploadTempClose} centered>
        <Modal.Header>
          <Modal.Title>Upload Preset Templates</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleUploadTempClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form className="select-cerele">
            <Form.Group className="" controlId="formBasicName">
              <Form.Label>Upload File</Form.Label>
              <div className="input-box">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={handleUploadTemp}
                />
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20.199 20.201"
                  >
                    <g
                      id="Group_11227"
                      data-name="Group 11227"
                      transform="translate(-2402.326 -6200.833)"
                    >
                      <path
                        id="Path_11890"
                        data-name="Path 11890"
                        d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                        transform="translate(752.656 5510.435)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_11892"
                        data-name="Path 11892"
                        d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                        transform="translate(751.221 5511.583)"
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleUploadTempClose}>
            No
          </button>
          <button className="yes" onClick={uploadTemplate}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CertificateConfigure;
