import React, { useState, useEffect } from "react";
import "./OrgManagement.css";
import "./responsive.css";
import { Form, Alert } from "react-bootstrap";
import EditInput from "../../../assets/images/edit-input.png";
import OrganisationService from "../../../services/organisation.service";
import AuthService from "../../../services/auth.service";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditOrg = () => {
  const [validated, setValidated] = useState(false);

  const [message, setMessage] = useState("");

  const [uploaded, setUploaded] = useState(false);

  const [dataUpload, setDataUpload] = useState(false);

  const [logoImg, setLogoImg] = useState("");

  const [userid, setUserId] = useState();

  const [changeData, setChangeData] = useState(false);

  const [orgData, setOrgData] = useState({
    orgname: "",
    industry: "",
    logo: "",
  });

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setOrgData({ ...orgData, user_id: user.user });

    setUserId(user.user);

    OrganisationService.getOrg(user.user)
      .then((response) => {
        setOrgData({
          orgname: response.data[0].organization_name,
          industry: response.data[0].Industry,
          logo: response.data[0].logo,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrgData({ ...orgData, [name]: value });
    setChangeData(true);
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setOrgData({ ...orgData, [name]: e.target.files[0] });
    setLogoImg(URL.createObjectURL(e.target.files[0]));
    setUploaded(true);
    setChangeData(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const formData = new FormData();

    formData.append("usp", userid);
    formData.append("orgname", orgData.orgname);
    formData.append("industry", orgData.industry);
    formData.append("logo", orgData.logo);

    if (changeData) {
      OrganisationService.createOrg(formData)
        .then((response) => {
          setOrgData({
            orgname: response.data.organization_name,
            industry: response.data.Industry,
            logo: response.data.logo,
          });

          toast.success("Organisation Updated Successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setChangeData(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer addUser">
              <div className="box-inner">
                <ToastContainer />
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="action-flex">
                    <div className="heading">
                      <h1>Edit Organisation</h1>
                    </div>
                    <div className="courses-act">
                      <Link to="/ManageOrganisation" className="action-item">
                        <div>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 19.379 19.382"
                            >
                              <g
                                id="left-arrow-in-circular-button-black-symbol"
                                transform="translate(-0.6 511.567)"
                              >
                                <path
                                  id="Path_13057"
                                  data-name="Path 13057"
                                  d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                  transform="translate(0 0)"
                                />
                              </g>
                            </svg>
                          </span>
                          &nbsp;&nbsp;
                          <span>Back</span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <Form.Group controlId="formBasicOrgName">
                    <Form.Label>Organization Name</Form.Label>
                    <div className="input-box">
                      <Form.Control
                        type="text"
                        placeholder="Begin Typing...."
                        name="orgname"
                        onChange={handleInputChange}
                        value={orgData.orgname}
                      />
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 20.199 20.201"
                        >
                          <g
                            id="Group_11227"
                            data-name="Group 11227"
                            transform="translate(-2402.326 -6200.833)"
                          >
                            <path
                              id="Path_11890"
                              data-name="Path 11890"
                              d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                              transform="translate(752.656 5510.435)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              id="Path_11892"
                              data-name="Path 11892"
                              d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                              transform="translate(751.221 5511.583)"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicIndustry">
                    <Form.Label>Industry</Form.Label>
                    <div className="input-box">
                      <Form.Control
                        type="text"
                        placeholder="Begin Typing...."
                        name="industry"
                        onChange={handleInputChange}
                        value={orgData.industry}
                      />
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 20.199 20.201"
                        >
                          <g
                            id="Group_11227"
                            data-name="Group 11227"
                            transform="translate(-2402.326 -6200.833)"
                          >
                            <path
                              id="Path_11890"
                              data-name="Path 11890"
                              d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                              transform="translate(752.656 5510.435)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <path
                              id="Path_11892"
                              data-name="Path 11892"
                              d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                              transform="translate(751.221 5511.583)"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formBasicLogo">
                    <Form.Label>Add your Organization Logo</Form.Label>
                    <div className="upload-flex">
                      <div className="upload-area">
                        <input
                          type="file"
                          name="logo"
                          id="logo"
                          onChange={handleFileChange}
                        />
                        <button className="upload-btn">
                          {uploaded === true ? (
                            <>
                              <img
                                src={logoImg}
                                className="previewImg"
                                width="100px"
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              <>
                                <i className="uil uil-upload"></i> Drop Here
                              </>
                            </>
                          )}
                        </button>
                      </div>
                      <div className="img-area">
                        {orgData.logo !== "" ? (
                          <>
                            <img
                              src={
                                "https://aeonlms.aeonsoftware.net/cit/" +
                                orgData.logo
                              }
                              className="previewImg"
                              width="100px"
                              alt=""
                            />{" "}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                  <div className="flex-end">
                    <input
                      type="submit"
                      className="onboard-btn-f"
                      value="Submit"
                      disabled={!changeData}
                      // onClick={nextPage}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOrg;
