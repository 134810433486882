import React, { useState, useEffect } from "react";
import PermissionService from "../../../services/permission.service";
import { Form, Alert } from "react-bootstrap";
import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditPermission = ({ eid }) => {
  const [rolesPermission, setRolesPermission] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [validated, setValidated] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [allRoles, setAllRoles] = useState([]);

  const [roleUpdate, setRoleUpdate] = useState({
    usp: "",
    roleName: "",
    roleid: "",
    permission: [],
  });

  const [changeData, setChangeData] = useState(false);

  const [uid, setUid] = useState("");

  const getRole = (eid) => {
    const user = AuthService.getCurrentUser();

    PermissionService.getRolePermission(eid, user.user)
      .then((response) => {
        const rolePerm = response.data[0].permissions.split(",");
        setRolesPermission(rolePerm);

        setRoleUpdate({
          roleName: getRoleName(response.data[0].role_id, allRoles),
          roleid: response.data[0].role_id,
          permission: rolePerm,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUid(user.user);

    PermissionService.getAllPermission(user.user)
      .then((response) => {
        setPermissions(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    PermissionService.getAllRoles(user.user)
      .then((response) => {
        setAllRoles(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    if (eid) {
      getRole(eid);
    }
  }, []);

  function getRoleName(roleId, allRoles) {
    const roleName = allRoles.find((r) => r.id === roleId);
    return roleName ? roleName.name : "Unknown role";
  }

  const updateRole = (e) => {
    const { value, checked } = e.target;
    const { permission } = roleUpdate;

    const isPermissionSelected = rolesPermission.includes(value);

    if (isPermissionSelected) {
      setRolesPermission((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== value)
      );
    } else {
      setRolesPermission((prevPermissions) => [...prevPermissions, value]);
    }

    if (checked) {
      setRoleUpdate({ ...roleUpdate, permission: [...permission, value] });
    } else {
      setRoleUpdate({
        ...roleUpdate,
        permission: permission.filter((e) => e !== value),
      });
    }
    setChangeData(true);
  };

  const updateRoles = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const data = {
      usp: uid,
      roleid: roleUpdate.roleid,
      permission: rolesPermission,
    };

    PermissionService.updateRole(data)
      .then((response) => {
        toast.success("Role Updated Successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setChangeData(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <ToastContainer />
      <Form noValidate validated={validated} onSubmit={updateRoles}>
        <Form.Group className="mb-3" controlId="formBasicOrgName">
          <Form.Label>
            Update Permission for {getRoleName(roleUpdate.roleid, allRoles)}
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicOrgName">
          <div className="permission-flex flex-column active">
            <div className="grid">
              {Array.isArray(permissions) &&
                permissions.map((permission, index) => {
                  return (
                    <>
                      <div className="check-box" key={index}>
                        <input
                          type="checkbox"
                          onChange={updateRole}
                          value={permission.permission_code}
                          checked={rolesPermission.includes(
                            permission.permission_code
                          )}
                        />
                        <div className="round"></div>
                        <span>{permission.permission_name}</span>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </Form.Group>
        <div className="flex-end">
          <input
            type="submit"
            className="onboard-btn"
            value="Apply"
            disabled={!changeData}
          />
        </div>
      </Form>
    </>
  );
};
export default EditPermission;
