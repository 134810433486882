import React from "react";
import { Link } from "react-router-dom";
import "./learn.css";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import CourseImg from "../../assets/images/course-placeholder.png";

import AllEvents from "../../common/AllEvents";

import ProgressBar from "react-bootstrap/ProgressBar";
// import Calendar from "../../common/Calendar";
import AdminUser from "../../assets/images/admin-user.png";
import CourseService from "../../services/course.service";
import AuthService from "../../services/auth.service";

import { useEffect } from "react";
import { useState } from "react";
import PathwayService from "../../services/pathway.service";

import RecommendedCourses from "../../common/RecommendedCourses";
import Conversation from "../../common/Conversation";
import ChatBot from "../../common/ChatBot";

import Loading from "../../assets/images/Loading.gif";

import { ColorRing } from "react-loader-spinner";

import { useSelector } from "react-redux";

import GraphService from "../../services/graph.service";

const Learn = () => {
  const userdata = useSelector((state) => state.userDataReducer);

  const [currentUser, setCurrentUser] = useState(userdata);
  const [courses, setCourses] = useState([]);

  const [incompCourses, setIncompCourses] = useState("");

  const [pathwayCourses, setPathwayCourses] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoadingCourses, setIsLoadingCourses] = useState(true);

  const [chat, setChat] = useState(false);

  const [value, onChange] = useState(new Date());

  const showChat = () => {
    setChat(true);
  };
  const hideChat = () => {
    setChat(false);
  };

  const getLearnersCoursesData = (uid) => {
    CourseService.getLearnersPathwayCourses(uid)
      .then((resp) => {
        setPathwayCourses(resp.data);
        setIsLoading(false);
      })
      .catch((e) => console.log(e));

    CourseService.getLearnersCoursesWithoutPath(uid)
      .then((resp) => {
        setCourses(resp.data);
        setIsLoadingCourses(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      getLearnersCoursesData(user.user);

      GraphService.getStudentCourseProgress(user.user)
        .then((resp) => {
          console.log(resp.data);
          const inCompCourses =
            resp.data.inprocess_course + resp.data.pending_course;
          setIncompCourses(inCompCourses);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  return (
    <>
      <div className="container-fluid learn">
        <div className="row">
          <div className="col-xxl-9 col-xl-9 col-lg-8">
            <div className="outer-box learn mb-3">
              <div className="cflex">
                <div className="heading">
                  <h1>Learn</h1>
                </div>
              </div>
              <div
                className={
                  courses.length > 0
                    ? "inner-box mb-3 custom-hight"
                    : "inner-box mb-3 custom-hight"
                }
              >
                {isLoadingCourses ? (
                  <>
                    <div className="loading">
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {courses.length > 0 ? (
                      <>
                        <div
                          className={
                            incompCourses == "0"
                              ? "course-lists course grid1"
                              : "course-lists course"
                          }
                        >
                          {Array.isArray(courses)
                            ? courses.map((course) => (
                                <>
                                  {course.compsts === "0" ? (
                                    <>
                                      <div key={course.corsid}>
                                        <div className="course-item">
                                          <div className="course-info">
                                            <div className="course-img">
                                              {course.cimg ? (
                                                <>
                                                  <img
                                                    src={
                                                      "https://aeonlms.aeonsoftware.net/cit/" +
                                                      course.cimg
                                                    }
                                                    alt=""
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    className="list-course-img"
                                                    src={CourseImg}
                                                    alt=""
                                                  />
                                                </>
                                              )}
                                            </div>
                                            <div className="cinf-flex">
                                              <div className="cinfo">
                                                <span className="cname">
                                                  {course.coursename}
                                                </span>
                                                <span className="catname">
                                                  {course.course_catename}
                                                </span>
                                              </div>
                                              <div className="range">
                                                <ProgressBar
                                                  now={
                                                    course.compsts === "1"
                                                      ? "100"
                                                      : `${course.compercent}`
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="course-act">
                                            <button>
                                              <Link
                                                to={"/Course"}
                                                state={{
                                                  cid: course.corsid,
                                                }}
                                              >
                                                {course.compercent > 0 &&
                                                course.compercent < 100 ? (
                                                  <>Finish Now</>
                                                ) : course.compsts === "1" ? (
                                                  <>View Course</>
                                                ) : (
                                                  <>Start Now</>
                                                )}
                                              </Link>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : incompCourses == "0" ? (
                                    <>
                                      <div className="loading">
                                        <h1>
                                          You have completed all your assigned
                                          courses. Keep it up.
                                        </h1>
                                        <img
                                          src={Loading}
                                          width="200px"
                                          alt=""
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))
                            : null}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="loading">
                          <h1>
                            You have not been enrolled in any courses yet.
                            Explore courses in the course catalog to start with.
                          </h1>
                          <img src={Loading} width="200px" alt="" />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="inner-box pathway-list custom-hight">
                {isLoading ? (
                  <>
                    <div className="loading">
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {pathwayCourses.length > 0 ? (
                      <>
                        <div className="path-list">
                          {Array.isArray(pathwayCourses)
                            ? pathwayCourses.map((pathwayCourse) => (
                                <>
                                  <div
                                    className="pathway-item"
                                    key={pathwayCourse.pathid}
                                  >
                                    <div className="pathway-info">
                                      <div className="cinfo">
                                        <span className="cname">
                                          {pathwayCourse.pathname}
                                        </span>
                                        {/* <span className="catname">Details</span> */}
                                        <span className="catname">
                                          {pathwayCourses.length} Courses
                                        </span>
                                      </div>
                                      <div className="circles-flex">
                                        {Array.isArray(
                                          pathwayCourse.pathcourses
                                        )
                                          ? pathwayCourse.pathcourses.map(
                                              (pathCourse) => (
                                                <>
                                                  <div
                                                    className={
                                                      pathCourse.compsts === "1"
                                                        ? "circle active"
                                                        : "circle"
                                                    }
                                                  ></div>
                                                </>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="course-lists pathway">
                                      {Array.isArray(pathwayCourse.pathcourses)
                                        ? pathwayCourse.pathcourses.map(
                                            (pathCourse, index) => (
                                              <>
                                                <div className="course-item">
                                                  <div className="course-info">
                                                    <div className="course-img">
                                                      <img
                                                        src={
                                                          "https://aeonlms.aeonsoftware.net/cit/" +
                                                          pathCourse.corsimg
                                                        }
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="cinf-flex">
                                                      <div className="cinfo">
                                                        <span className="cname">
                                                          {pathCourse.corsname}
                                                        </span>
                                                        <span className="catname">
                                                          {
                                                            pathCourse.course_catename
                                                          }
                                                        </span>
                                                      </div>
                                                      <div className="range">
                                                        <ProgressBar
                                                          now={
                                                            pathCourse.compsts ===
                                                            "1"
                                                              ? "100"
                                                              : `${pathCourse.compercent}`
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="course-act">
                                                    {index == "0" ||
                                                    pathwayCourse.pathcourses[
                                                      index - 1
                                                    ].compsts === "1" ? (
                                                      <>
                                                        <button>
                                                          <Link
                                                            to={"/Course"}
                                                            state={{
                                                              cid: pathCourse.corsid,
                                                            }}
                                                          >
                                                            {pathCourse.compercent >
                                                              0 &&
                                                            pathCourse.compercent <
                                                              100 ? (
                                                              <>Finish Now</>
                                                            ) : pathCourse.compsts ===
                                                              "1" ? (
                                                              <>View Course</>
                                                            ) : (
                                                              <>Start Now</>
                                                            )}
                                                          </Link>
                                                        </button>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <button
                                                          title="Complete Previous Course to Unlock"
                                                          disabled
                                                        >
                                                          <span>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="12px"
                                                              height="12px"
                                                              enable-background="new 0 0 24 24"
                                                              viewBox="0 0 24 24"
                                                              id="lock"
                                                            >
                                                              <path
                                                                d="M17,9V7c0-2.8-2.2-5-5-5S7,4.2,7,7v2c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-7C20,10.3,18.7,9,17,9z M9,7
	                                                                                                    c0-1.7,1.3-3,3-3s3,1.3,3,3v2H9V7z M13.1,15.5c0,0-0.1,0.1-0.1,0.1V17c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1.4c-0.6-0.6-0.7-1.5-0.1-2.1
	                                                                                                    c0.6-0.6,1.5-0.7,2.1-0.1C13.6,13.9,13.7,14.9,13.1,15.5z"
                                                              ></path>
                                                            </svg>
                                                          </span>
                                                          <span>
                                                            Course Locked
                                                          </span>
                                                        </button>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          )
                                        : null}
                                    </div>
                                  </div>
                                </>
                              ))
                            : null}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="loading">
                          <h1>
                            You have not been enrolled in any pathway courses
                            yet. Explore courses in course catalog to start with
                          </h1>
                          <img src={Loading} width="200px" alt="" />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="outer-box recomd-courses">
              <div className="cflex">
                <div className="heading">
                  <h1>Recommended Courses</h1>
                </div>
              </div>
              <RecommendedCourses />
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4">
            <div className="flex-right">
              <div className="outer-box mb-4">
                <div className="cflex">
                  <div className="heading">
                    <h1>Schedule and Submissions</h1>
                  </div>
                </div>
                <div className="flex-cal">
                  <Calendar onChange={onChange} value={value} />
                  <AllEvents />
                </div>
              </div>
              <div className="outer-box chat learn">
                <div className="cflex">
                  <div className="heading">
                    <h1>Conversation</h1>
                  </div>
                </div>
                <Conversation showChat={showChat} />
              </div>
              <ChatBot chat={chat} hideChat={hideChat} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Learn;
