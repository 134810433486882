import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const passedHeaders = {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}

const getEstoreCourseById = (data) => {
  return http.post(`/api/getestabid`, data, { headers: passedHeaders});
};

const updateEstoreCourses = (data) => {
    return http.post(`/api/upestocs`, data, { headers: passedHeaders});
};

const deleteEstoreCourses = (data) => {
    return http.post(`/api/delestocs`, data, { headers: passedHeaders});
};
  
const filterEstoreCourses = (data) => {
    return http.post(`/api/getestafiltcs`, data, { headers: passedHeaders});
};

const EstoreService = {
    getEstoreCourseById,
    updateEstoreCourses,
    deleteEstoreCourses,
    filterEstoreCourses
};

export default EstoreService;