import React, { useState, useEffect } from "react";
import "./Reports.css";
import { Modal, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

import "../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import UserService from "../../services/user.service";

const ViewReport = () => {
  const [selectedOption, setSelectedOption] = useState();
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disableBulkDelete, setDisableBulkDelete] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const [did, setDid] = useState("");
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [learners, setLearners] = useState([]);

  const [options, setOptions] = useState([]);

  const [activeLearners, setActiveLearners] = useState("0");
  const [inactiveLearners, setInactiveLearners] = useState("0");
  const [unassigned, setUnassigned] = useState("0");

  const [courses, setCourses] = useState({
    selectedUser: [],
    delid: [],
    courseId: [],
  });

  const reports = [
    {
      id: "1",
      first_name: "John",
      last_name: "Doe",
      email: "john.doe@gmail.com",
      coursename: "ASA",
      activityname: "ASA",
      acitivitytype: "scrom",
      activitygrade: "95:00%",
      completionsts: "Completed",
      completiondate: "17-08-2023",
      dateenrolled: "15-08-2023",
    },
  ];

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#allLearners")) {
      function filterGlobal() {
        $("#allLearners")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#allLearners")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#allLearners").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleDeleteClose = () => setDeleteSelected(false);

  const handleDeleteShow = (e) => {
    setDeleteSelected(true);
  };

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    setSelectedValues(values);
    const valSplit = values.split(",");

    if (valSplit[0] !== "") {
      setCourses({ ...courses, courseId: valSplit });
    } else {
      setCourses({ ...courses, courseId: [] });
    }
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allLearners = reports.map((l) => l.id);
      setChecked(allLearners);
      setCourses({ ...courses, delid: allLearners });
      setCourses({ ...courses, selectedUser: allLearners });
      setDisableBulkDelete(false);
    } else {
      setChecked([]);
      setCourses({ ...courses, delid: [] });
      setCourses({ ...courses, selectedUser: [] });
      setDisableBulkDelete(true);
    }
  };

  const handleLearnerChange = (e, l) => {
    let selectedUserArr = [];
    if (e.target.checked) {
      selectedUserArr = [...checked, l.id];
      setChecked([...checked, l.id]);
      setCourses({ ...courses, delid: [...checked, l.id] });
      setCourses({ ...courses, selectedUser: [...checked, l.id] });
    } else {
      selectedUserArr = checked.filter((item) => item !== l.id);
      setChecked(checked.filter((item) => item !== l.id));
      setCourses({
        ...courses,
        delid: checked.filter((item) => item !== l.id),
      });
      setCourses({
        ...courses,
        selectedUser: checked.filter((item) => item !== l.id),
      });
    }

    if (selectedUserArr.length > 0) {
      setDisableBulkDelete(false);
    } else {
      setDisableBulkDelete(true);
    }
  };

  const deleteSelectedUser = (e) => {
    e.preventDefault();
    const data = {
      delid: courses.delid,
    };

    UserService.deleteMultipleLearners(data)
      .then((response) => {
        setMessage("Selected Learners deleted successfully!");
        setDataUpload(true);
        setShow(false);
        setLearners(learners.filter((learner) => learner.user_id !== did));

        setDeleteSelected(false);
        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteLearner = (e) => {
    e.preventDefault();

    UserService.deleteLearners(did)
      .then((response) => {
        setMessage("Learner deleted successfully!");
        setDataUpload(true);
        setShow(false);
        setLearners(learners.filter((learner) => learner.user_id !== did));

        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer right">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="heading">
                    <h1>Activity Overview Reports</h1>
                  </div>
                  <div className="courses-act">
                    <div className="action-item">
                      <Link to="/Reports">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 19.379 19.382"
                          >
                            <g
                              id="left-arrow-in-circular-button-black-symbol"
                              transform="translate(-0.6 511.567)"
                            >
                              <path
                                id="Path_13057"
                                data-name="Path 13057"
                                d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        &nbsp;&nbsp;
                        <span>Back</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>
                {dataUpload ? (
                  <>
                    <Alert className="m-auto mb-5" variant="success">
                      {message}
                    </Alert>
                  </>
                ) : (
                  <></>
                )}
                <div className="admin-users">
                  <div className="table-responsive">
                    <table className="table" id="allLearners">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Course Name</th>
                          <th>Activity Name</th>
                          <th>Activity Type</th>
                          <th>Acitivity Grade</th>
                          <th>Completion Status</th>
                          <th>Completion Date</th>
                          <th>Date Enrolled</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports.map((report, index) => (
                          <>
                            <tr key={index}>
                              <td>
                                <span>{report.first_name}</span>
                              </td>
                              <td>
                                <span>{report.last_name}</span>
                              </td>
                              <td>
                                <span>{report.email}</span>
                              </td>
                              <td>
                                <span>{report.coursename}</span>
                              </td>
                              <td>
                                <span>{report.activityname}</span>
                              </td>
                              <td>
                                <span>{report.acitivitytype}</span>
                              </td>
                              <td>
                                <span>{report.activitygrade}</span>
                              </td>
                              <td>
                                <span>{report.completionsts}</span>
                              </td>
                              <td>
                                <span>{report.activitygrade}</span>
                              </td>
                              <td>
                                <span>{report.dateenrolled}</span>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Learner</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this learner?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteLearner}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteSelected} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Selected Learners</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleDeleteClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete selected Learners?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleDeleteClose}>
            No
          </button>
          <button className="yes" onClick={deleteSelectedUser}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewReport;
