import React, { useState, useEffect } from "react";
import PermissionService from "../../../services/permission.service";
import { Form } from "react-bootstrap";
import AuthService from "../../../services/auth.service";

const ViewPermission = ({ vid }) => {
  const [rolesPermission, setRolesPermission] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const getRole = (vid) => {
    const user = AuthService.getCurrentUser();
    PermissionService.getRolePermission(vid, user.user)
      .then((response) => {
        const rolePerm = response.data[0].permissions.split(",");
        setRolesPermission(rolePerm);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    PermissionService.getAllPermission(user.user)
      .then((response) => {
        setPermissions(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    if (vid) {
      getRole(vid);
    }
  }, [vid]);

  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicOrgName">
        <Form.Label></Form.Label>
        <div className="permission-flex flex-column active">
          <div className="grid">
            {Array.isArray(permissions) &&
              permissions.map((permission, index) => {
                return (
                  <>
                    <div className="check-box" key={index}>
                      <input
                        type="checkbox"
                        disabled
                        value={permission.permission_code}
                        checked={rolesPermission.includes(
                          permission.permission_code
                        )}
                      />
                      <div className="round"></div>
                      <span>{permission.permission_name}</span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </Form.Group>
    </>
  );
};
export default ViewPermission;
