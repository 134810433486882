import http from "../http-common";

const token = JSON.parse(localStorage.getItem("token"));

const getErrorReq = () => {
  return http.get("/api/getdtkt", {
    headers: { 'Authorization': 'Bearer ' + token },
  });
};

const getErrorReqById = (rid, baps) => {
  return http.get(`/api/getdtkt?rid=${rid}&baps=${baps}`, {
    headers: {'Authorization': 'Bearer ' + token },
  });
};

const createErrorReq = (data) => {
  return http.post("/api/adddtkt", data, {
    headers: {
      'Authorization': 'Bearer ' + token,
      "Content-Type": "multipart/form-data",
    },
  });
};

const asssinErrorReq = (data) => {
  return http.post("/api/upasgtkt", data, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    },
  });
};

const solveErrorReq = (data) => {
  return http.post("/api/upaststkt", data, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    },
  });
};





const ErrorreqService = {
  getErrorReq,
  createErrorReq,
  getErrorReqById,
  asssinErrorReq,
  solveErrorReq
};

export default ErrorreqService;
