import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import "./Onboarding.css";
import { useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Modal, Button, Alert } from "react-bootstrap";
import OccupcatService from "../../../services/occupcat.service";

import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OccupationCat = ({ prevStep, nextStep, skip }) => {
  const [inputBox, setInputBox] = useState(false);
  const [validated, setValidated] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [did, setDid] = useState("");
  const [catData, setCatData] = useState([]);

  const [chnageData, setChangeData] = useState(false);

  const [occpCat, setOccpCat] = useState({
    usp: "",
    data: [],
    sts: "0",
  });

  const handleChange = (tags) => {
    if (tags.some((tag) => tag.trim() === "")) {
    } else {
      setOccpCat({ ...occpCat, data: tags });
      setChangeData(true);
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setOccpCat({ ...occpCat, usp: user.user });

    OccupcatService.getOccupCat(user.user)
      .then((response) => {
        setCatData(response.data);
        setInputBox(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();
  };

  let value = "0";

  const showInputBox = () => {
    setInputBox(!inputBox);
    value = inputBox ? "0" : "1";
    setOccpCat({ ...occpCat, sts: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      usp: occpCat.usp,
      data: occpCat.data,
      sts: occpCat.sts,
    };

    if (data.data.length === 0) {
      nextStep();
    } else {
      if (chnageData) {
        OccupcatService.createOccupCat(data)
          .then((response) => {
            // setDataUpload(true);
            // setMessage("Occupation Category Added Successfully!");
            setCatData(response.data);

            toast.success("Occupation Category Added Successfully!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            setTimeout(() => {
              nextStep();
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        nextStep();
      }
    }
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleClose = () => {
    setShow(false);
  };

  const deleteOccupcat = (e) => {
    e.preventDefault();

    const user = AuthService.getCurrentUser();

    OccupcatService.deleteOccupCat(did)
      .then((response) => {
        setCatData(catData.filter((cat) => cat.id !== did));
        setShow(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ToastContainer />
        <Form.Group className="mb-3" controlId="formBasicOrgName">
          <div className="flex-column">
            <Form.Label>
              Does your organization have occupational Categories?
            </Form.Label>
            <label className="switch">
              <input
                type="checkbox"
                onChange={showInputBox}
                name="sts"
                value={occpCat.sts}
                checked={inputBox}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </Form.Group>
        <div className={inputBox ? "multiple-input active" : "multiple-input"}>
          <Form.Group className="mb-5" controlId="formBasicIndustry">
            <div className="input-box">
              <TagsInput
                value={occpCat.data}
                name="data"
                onChange={handleChange}
                inputProps={{ placeholder: "Begin Typing...." }}
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20.199 20.201"
                >
                  <g
                    id="Group_11227"
                    data-name="Group 11227"
                    transform="translate(-2402.326 -6200.833)"
                  >
                    <path
                      id="Path_11890"
                      data-name="Path 11890"
                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                      transform="translate(752.656 5510.435)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_11892"
                      data-name="Path 11892"
                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                      transform="translate(751.221 5511.583)"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </Form.Group>
          <Form.Label>Added occupational Categories?</Form.Label>
          <div className="addedCat grid">
            {Array.isArray(catData) &&
              catData.map((occpcat, index) => {
                return (
                  <div className="addedCat-item" key={index}>
                    <span>{occpcat.category_name}</span>
                    <div className="deleteIcon">
                      <button onClick={handleShow} data-bs-target={occpcat.id}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16 16"
                        >
                          <g
                            id="incorrect"
                            transform="translate(-42.855 468.853)"
                          >
                            <path
                              id="Path_11894"
                              data-name="Path 11894"
                              d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            onClick={prevPage}
          />
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Occupation Category</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Occupation Category?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteOccupcat}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default OccupationCat;
