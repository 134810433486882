import React, { useState, useEffect } from "react";
import "./theme.css";
import "./responsive.css";
import { Form, Alert } from "react-bootstrap";

import SettingsService from "../../../services/settings.service";
import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setTheme } from "../../../actions/themeActions";

import { useSelector, useDispatch } from "react-redux";

const ThemeSettings = ({ chnageMode }) => {
  const [checked, setChecked] = useState("0");
  const [color, setColor] = useState("1");
  const [validated, setValidated] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const [themeSettings, setThemeSettings] = useState({
    mode: "",
    themeid: "",
    customColor: "",
  });

  const [uid, setUid] = useState("");

  const [chnageData, setChangeData] = useState(false);

  const getThemeData = (uids) => {
    SettingsService.getThemeSettings(uids)
      .then((response) => {
        if (response.data !== false) {
          // setThemeSettings(response.data[0]);
          setColor(response.data[0].themeid);
          setChecked(response.data[0].mode);

          setThemeSettings({ ...themeSettings, mode: response.data[0].mode });
          dispatch(setTheme(response.data[0].mode));
          if (response.data !== false) {
            setThemeSettings({ ...themeSettings, mode: response.data[0].mode });

            dispatch(setTheme(response.data[0].mode));

            if (response.data[0].mode === "1") {
              if (response.data[0].themeid === "1") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#58A6FF"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#C9FCFC"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#58A6FF"
                );
              } else if (response.data[0].themeid === "2") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#8BC34A"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#E2C5FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#57BE17"
                );
              } else if (response.data[0].themeid === "3") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#9D65F2"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#A5D6FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#9D65F2"
                );
              } else if (response.data[0].themeid === "4") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#6767FF"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#FFBEDD"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#DB1EA3"
                );
              } else if (response.data[0].themeid === "5") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#F6428B"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#FFBEDD"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#150E80"
                );
              }

              document.documentElement.style.setProperty(
                "--progress-icon",
                "rgba(255, 255, 255, 0.7)"
              );

              document.documentElement.style.setProperty(
                "--chips-color",
                "#eef2f6"
              );
              document.documentElement.style.setProperty(
                "--body-dark-bg",
                "#ffffff"
              );
              document.documentElement.style.setProperty(
                "--dark-bg",
                "#ffffff"
              );
              document.documentElement.style.setProperty(
                "--whiteColor",
                "#010001"
              );
              document.documentElement.style.setProperty(
                "--nav-inactive",
                "#9FA6AC"
              );
              document.documentElement.style.setProperty(
                "--nav-active",
                "#4B7AEF"
              );
              document.documentElement.style.setProperty(
                "--table-row",
                "#CCCDCF"
              );
              document.documentElement.style.setProperty(
                "--off-white",
                "#faf9f6"
              );
            } else {
              if (response.data[0].themeid === "1") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#24DFFE"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#C9FCFC"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#24DFFE"
                );
              } else if (response.data[0].themeid === "2") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#87FE61"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#7EE787"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#87FE61"
                );
              } else if (response.data[0].themeid === "3") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#9D65F2"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#E2C5FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#9D65F2"
                );
              } else if (response.data[0].themeid === "4") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#0DAFD2"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#A5D6FF"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#0DAFD2"
                );
              } else if (response.data[0].themeid === "5") {
                document.documentElement.style.setProperty(
                  "--primary-color",
                  "#FF4CCA"
                );
                document.documentElement.style.setProperty(
                  "--secondary-color",
                  "#FFBEDD"
                );
                document.documentElement.style.setProperty(
                  "--nav-active",
                  "#FF4CCA"
                );
              }

              document.documentElement.style.setProperty(
                "--progress-icon",
                "rgba(255, 255, 255, 0.3)"
              );

              document.documentElement.style.setProperty(
                "--chips-color",
                "#3A3A3A"
              );
              document.documentElement.style.setProperty(
                "--body-dark-bg",
                "#010001"
              );
              document.documentElement.style.setProperty(
                "--dark-bg",
                "#28282B"
              );
              document.documentElement.style.setProperty(
                "--whiteColor",
                "#f5f5f5"
              );
              document.documentElement.style.setProperty(
                "--nav-inactive",
                "#f5f5f5"
              );
              document.documentElement.style.setProperty(
                "--table-row",
                "#010001"
              );
              document.documentElement.style.setProperty(
                "--off-white",
                "#010101"
              );
            }
          } else {
            setThemeSettings({ ...themeSettings, mode: "dark" });
            dispatch(setTheme("0"));
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const changecolor = (cid) => {
    setColor(cid);
    setChangeData(true);
  };

  const changeDarkMode = () => {
    // setChecked(!checked);
    if (checked === "0") {
      setChecked("1");
      // setThemeSettings({ ...themeSettings, mode: "1" });
    } else {
      setChecked("0");
      // setThemeSettings({ ...themeSettings, mode: "0" });
    }
    setChangeData(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setThemeSettings({ ...themeSettings, [name]: value });
    setChangeData(true);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setUid(user.user);
    if (user) {
      getThemeData(user.user);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      mode: checked,
      themeid: color,
      customColor: themeSettings.customColor,
    };

    if (chnageData) {
      SettingsService.createThemeSettings(uid, data)
        .then((response) => {
          getThemeData(uid);
          setDataUpload(true);

          toast.success("Theme chnaged successfuly!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          dispatch(setTheme(data.mode));
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <div className="container-fluid theme">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer">
              <div className="heading">
                <h2>Appearance</h2>
              </div>
              <ToastContainer />
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="changemode-flex">
                  <div className="changemode">
                    <label htmlFor="">Change Mode</label>
                    <p>Change Dark mode to Light mode</p>
                  </div>
                  <div className="c-switch">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={checked === "1"}
                        onChange={changeDarkMode}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="interface-theme">
                  <div className="changemode">
                    <label htmlFor="">Interface theme</label>
                    <p>Customise your workspace theme</p>
                  </div>
                </div>
                <div className="theme-list-flex">
                  <div
                    className={
                      color === "1" &&
                      (themeSettings.mode === "0" || chnageMode === "dark")
                        ? "theme-item darkone active"
                        : color === "1" &&
                          (themeSettings.mode === "1" || chnageMode === "light")
                        ? "theme-item lightone"
                        : "theme-item"
                    }
                    onClick={(e) => changecolor("1")}
                  >
                    {themeSettings.mode === "0" || chnageMode === "dark" ? (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.767 170.333"
                        >
                          <g
                            id="Group_11333"
                            data-name="Group 11333"
                            transform="translate(-0.282 0.119)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(0.282 0.261)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(0.356 -0.119)"
                              fill="#0d130c"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(0.356 28.881)"
                              fill="#101915"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(11.356 39.881)"
                              fill="#24DFFE"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(11.356 74.881)"
                              fill="#2e3531"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(11.356 92.881)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(11.356 110.881)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(11.356 128.881)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17252"
                              data-name="Rectangle 17252"
                              width="190"
                              height="141"
                              transform="translate(98.356 28.881)"
                              fill="#1d1d1d"
                            />
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(177.356 39.881)"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(116.356 74.881)"
                              stroke="#24DFFE"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(116.356 102.881)"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(116.356 130.881)"
                              stroke="#24DFFE"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(13.474 11.678)"
                            >
                              <circle
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(-0.119 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.881 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.881 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.767 170.333"
                        >
                          <defs>
                            <filter
                              id="Rectangle_17252"
                              x="89.074"
                              y="23"
                              width="208"
                              height="159"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g
                            id="Group_11669"
                            data-name="Group 11669"
                            transform="translate(-8552.926 -24233)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(8552.926 24233.381)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(8553 24233)"
                              fill="#7e7e7e"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(8553 24262)"
                              fill="#f9f9f9"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8564 24273)"
                              fill="#58A6FF"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(8564 24308)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(8564 24326)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8564 24344)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8564 24362)"
                              fill="#f2f2f2"
                            />
                            <g
                              transform="matrix(1, 0, 0, 1, 8552.93, 24233)"
                              filter="url(#Rectangle_17252)"
                            >
                              <rect
                                id="Rectangle_17252-2"
                                data-name="Rectangle 17252"
                                width="190"
                                height="141"
                                transform="translate(98.07 29)"
                                fill="#f9f9f9"
                              />
                            </g>
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(8730 24273)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(8669 24308)"
                              fill="#f2f2f2"
                              stroke="#58A6FF"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(8669 24336)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(8669 24364)"
                              fill="#f2f2f2"
                              stroke="#58A6FF"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(8566.119 24244.797)"
                            >
                              <circle
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(-0.119 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.881 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.881 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    )}
                    <div className="theme-name">
                      {themeSettings.mode === "0" || chnageMode === "dark" ? (
                        <>
                          <span>Neon Blue (Default)</span>
                        </>
                      ) : (
                        <>
                          <span>Blue (Default)</span>
                        </>
                      )}
                      <span className="check-circle">
                        {color === "1" ? (
                          <>
                            {themeSettings.mode === "0" ||
                            chnageMode === "dark" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#24DFFE"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#24DFFE"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#58A6FF"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#58A6FF"
                                  />
                                </svg>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <svg
                              id="check-mark-_1_"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 22.165 22.168"
                            >
                              <path
                                id="Path_13260"
                                data-name="Path 13260"
                                d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                transform="translate(-0.5 511.567)"
                                fill="#707070"
                              />
                            </svg>
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      color === "2" &&
                      (themeSettings.mode === "0" || chnageMode === "dark")
                        ? "theme-item darktwo"
                        : color === "2" &&
                          (themeSettings.mode === "1" || chnageMode === "light")
                        ? "theme-item lighttwo"
                        : "theme-item"
                    }
                    onClick={(e) => changecolor("2")}
                  >
                    {themeSettings.mode === "0" || chnageMode === "dark" ? (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.767 170.333"
                        >
                          <g
                            id="Group_11333"
                            data-name="Group 11333"
                            transform="translate(-0.282 0.119)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(0.282 0.261)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(0.356 -0.119)"
                              fill="#0d130c"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(0.356 28.881)"
                              fill="#101915"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(11.356 39.881)"
                              fill="#87FE61"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(11.356 74.881)"
                              fill="#2e3531"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(11.356 92.881)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(11.356 110.881)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(11.356 128.881)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17252"
                              data-name="Rectangle 17252"
                              width="190"
                              height="141"
                              transform="translate(98.356 28.881)"
                              fill="#1d1d1d"
                            />
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(177.356 39.881)"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(116.356 74.881)"
                              stroke="#87FE61"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(116.356 102.881)"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(116.356 130.881)"
                              stroke="#87FE61"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(13.474 11.678)"
                            >
                              <circle
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(-0.119 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.881 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.881 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.767 170.333"
                        >
                          <defs>
                            <filter
                              id="Rectangle_17252"
                              x="89.074"
                              y="23"
                              width="208"
                              height="159"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g
                            id="Group_11669"
                            data-name="Group 11669"
                            transform="translate(-8552.926 -24233)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(8552.926 24233.381)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(8553 24233)"
                              fill="#7e7e7e"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(8553 24262)"
                              fill="#f9f9f9"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8564 24273)"
                              fill="#8BC34A"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(8564 24308)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(8564 24326)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8564 24344)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8564 24362)"
                              fill="#f2f2f2"
                            />
                            <g
                              transform="matrix(1, 0, 0, 1, 8552.93, 24233)"
                              filter="url(#Rectangle_17252)"
                            >
                              <rect
                                id="Rectangle_17252-2"
                                data-name="Rectangle 17252"
                                width="190"
                                height="141"
                                transform="translate(98.07 29)"
                                fill="#f9f9f9"
                              />
                            </g>
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(8730 24273)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(8669 24308)"
                              fill="#f2f2f2"
                              stroke="#8BC34A"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(8669 24336)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(8669 24364)"
                              fill="#f2f2f2"
                              stroke="#8BC34A"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(8566.119 24244.797)"
                            >
                              <circle
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(-0.119 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.881 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.881 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    )}
                    <div className="theme-name">
                      {themeSettings.mode === "0" || chnageMode === "dark" ? (
                        <>
                          <span>Avocado Alien</span>
                          <span className="check-circle">
                            {color === "2" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#87fe61"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#87fe61"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>Green</span>
                          <span className="check-circle">
                            {color === "2" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#8BC34A"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#8BC34A"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      color === "3" &&
                      (themeSettings.mode === "0" || chnageMode === "dark")
                        ? "theme-item darkthree"
                        : color === "3" &&
                          (themeSettings.mode === "1" || chnageMode === "light")
                        ? "theme-item lightthree"
                        : "theme-item"
                    }
                    onClick={(e) => changecolor("3")}
                  >
                    {themeSettings.mode === "0" || chnageMode === "dark" ? (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.796 170.334"
                        >
                          <g
                            id="Group_11462"
                            data-name="Group 11462"
                            transform="translate(-658.999 -561)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(659.028 561.381)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(658.999 561)"
                              fill="#0d130c"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(658.999 590)"
                              fill="#101915"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(669.999 601)"
                              fill="#9d65f2"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(669.999 636)"
                              fill="#2e3531"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(669.999 654)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(669.999 672)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(669.999 690)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17252"
                              data-name="Rectangle 17252"
                              width="190"
                              height="141"
                              transform="translate(756.999 590)"
                              fill="#1d1d1d"
                            />
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(835.999 601)"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(774.999 636)"
                              stroke="#9d65f2"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(774.999 664)"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(774.999 692)"
                              stroke="#9d65f2"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(672.221 572.797)"
                            >
                              <circle
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(-0.22 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.78 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.78 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.767 170.333"
                        >
                          <defs>
                            <filter
                              id="Rectangle_17252"
                              x="89"
                              y="23"
                              width="208"
                              height="159"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g
                            id="Group_11670"
                            data-name="Group 11670"
                            transform="translate(-8754.202 -23809.332)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(8754.231 23809.713)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(8754.202 23809.332)"
                              fill="#7e7e7e"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(8754.202 23838.332)"
                              fill="#f9f9f9"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8765.202 23849.332)"
                              fill="#9d65f2"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(8765.202 23884.332)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="75"
                              height="15"
                              rx="3"
                              transform="translate(8765.202 23902.332)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8765.202 23920.332)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="75"
                              height="14"
                              rx="3"
                              transform="translate(8765.202 23938.332)"
                              fill="#f2f2f2"
                            />
                            <g
                              transform="matrix(1, 0, 0, 1, 8754.2, 23809.33)"
                              filter="url(#Rectangle_17252)"
                            >
                              <rect
                                id="Rectangle_17252-2"
                                data-name="Rectangle 17252"
                                width="190"
                                height="141"
                                transform="translate(98 29)"
                                fill="#f9f9f9"
                              />
                            </g>
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(8931.202 23849.332)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(8870.202 23884.332)"
                              fill="#f2f2f2"
                              stroke="#f6f6f6"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(8870.202 23912.332)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(8870.202 23940.332)"
                              fill="#f2f2f2"
                              stroke="#f6f6f6"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(8767.424 23821.129)"
                            >
                              <circle
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(-0.22 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.78 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.78 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    )}
                    <div className="theme-name">
                      {themeSettings.mode === "0" || chnageMode === "dark" ? (
                        <>
                          <span>Rainbow Candy</span>
                          <span className="check-circle">
                            {color === "3" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#9D65F2"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#9D65F2"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>Purple</span>
                          <span className="check-circle">
                            {color === "3" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#9D65F2"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#9D65F2"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      color === "4" &&
                      (themeSettings.mode === "0" || chnageMode === "dark")
                        ? "theme-item darkfour"
                        : color === "4" &&
                          (themeSettings.mode === "1" || chnageMode === "light")
                        ? "theme-item lightfour"
                        : "theme-item"
                    }
                    onClick={(e) => changecolor("4")}
                  >
                    {themeSettings.mode === "0" || chnageMode === "dark" ? (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.897 170.334"
                        >
                          <g
                            id="Group_11463"
                            data-name="Group 11463"
                            transform="translate(-1055.999 -561)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(1056.13 561.381)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(1055.999 561)"
                              fill="#0d130c"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(1055.999 590)"
                              fill="#101915"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(1067.999 601)"
                              fill="#0dafd2"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(1067.999 636)"
                              fill="#2e3531"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(1067.999 654)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(1067.999 672)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(1067.999 690)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17252"
                              data-name="Rectangle 17252"
                              width="190"
                              height="141"
                              transform="translate(1153.999 590)"
                              fill="#1d1d1d"
                            />
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(1232.999 601)"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(1171.999 636)"
                              stroke="#11b1d4"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(1171.999 664)"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(1171.999 692)"
                              stroke="#11b1d4"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(1069.322 572.797)"
                            >
                              <ellipse
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="4"
                                cy="3.5"
                                rx="4"
                                ry="3.5"
                                transform="translate(-0.322 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.678 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.678 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.767 170.333"
                        >
                          <defs>
                            <filter
                              id="Rectangle_17252"
                              x="89"
                              y="23"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g
                            id="Group_11673"
                            data-name="Group 11673"
                            transform="translate(-9281.499 -24233)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(9281.63 24233.381)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(9281.499 24233)"
                              fill="#7e7e7e"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(9281.499 24262)"
                              fill="#f9f9f9"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(9293.499 24273)"
                              fill="#6767FF"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(9293.499 24308)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(9293.499 24326)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(9293.499 24344)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(9293.499 24362)"
                              fill="#f2f2f2"
                            />
                            <g
                              transform="matrix(1, 0, 0, 1, 9281.5, 24233)"
                              filter="url(#Rectangle_17252)"
                            >
                              <rect
                                id="Rectangle_17252-2"
                                data-name="Rectangle 17252"
                                width="190"
                                height="141"
                                transform="translate(98 29)"
                                fill="#f9f9f9"
                              />
                            </g>
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(9458.499 24273)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(9397.499 24308)"
                              fill="#f2f2f2"
                              stroke="#6767FF"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(9397.499 24336)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(9397.499 24364)"
                              fill="#f2f2f2"
                              stroke="#6767FF"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(9294.822 24244.797)"
                            >
                              <ellipse
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="4"
                                cy="3.5"
                                rx="4"
                                ry="3.5"
                                transform="translate(-0.322 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.678 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.678 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    )}
                    <div className="theme-name">
                      {themeSettings.mode === "0" || chnageMode === "dark" ? (
                        <>
                          <span>Honeydew Punch</span>
                          <span className="check-circle">
                            {color === "4" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#0dafd2"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#0dafd2"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>Navy Blue</span>
                          <span className="check-circle">
                            {color === "4" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#6767FF"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#6767FF"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      color === "5" &&
                      (themeSettings.mode === "0" || chnageMode === "dark")
                        ? "theme-item darkfive"
                        : color === "5" &&
                          (themeSettings.mode === "1" || chnageMode === "light")
                        ? "theme-item lightfive"
                        : "theme-item"
                    }
                    onClick={(e) => changecolor("5")}
                  >
                    {themeSettings.mode === "0" || chnageMode === "dark" ? (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.999 170.332"
                        >
                          <g
                            id="Group_11464"
                            data-name="Group 11464"
                            transform="translate(-1453.998 -561)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(1454.23 561.379)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(1453.998 561)"
                              fill="#0d130c"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(1453.998 590)"
                              fill="#101915"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(1465.998 601)"
                              fill="#ff4cca"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(1465.998 636)"
                              fill="#2e3531"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(1465.998 654)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(1465.998 672)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(1465.998 690)"
                              fill="#1f2723"
                            />
                            <rect
                              id="Rectangle_17252"
                              data-name="Rectangle 17252"
                              width="190"
                              height="141"
                              transform="translate(1551.998 590)"
                              fill="#1d1d1d"
                            />
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(1630.998 601)"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(1569.998 636)"
                              stroke="#ff4cca"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(1569.998 664)"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(1569.998 692)"
                              stroke="#ff4cca"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(1467.424 572.797)"
                            >
                              <ellipse
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="4"
                                cy="3.5"
                                rx="4"
                                ry="3.5"
                                transform="translate(-0.424 0.203)"
                                fill="#e87161"
                              />
                              <ellipse
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="4"
                                cy="3.5"
                                rx="4"
                                ry="3.5"
                                transform="translate(8.576 0.203)"
                                fill="#e7ce6b"
                              />
                              <ellipse
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="4"
                                cy="3.5"
                                rx="4"
                                ry="3.5"
                                transform="translate(17.576 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          className="theme-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 288.767 170.333"
                        >
                          <defs>
                            <filter
                              id="Rectangle_17252"
                              x="89"
                              y="23"
                              width="208"
                              height="159"
                              filterUnits="userSpaceOnUse"
                            >
                              <feOffset dy="3" input="SourceAlpha" />
                              <feGaussianBlur stdDeviation="3" result="blur" />
                              <feFlood flood-opacity="0.161" />
                              <feComposite operator="in" in2="blur" />
                              <feComposite in="SourceGraphic" />
                            </filter>
                          </defs>
                          <g
                            id="Group_11710"
                            data-name="Group 11710"
                            transform="translate(-9281.499 -24233)"
                          >
                            <path
                              id="Path_13262"
                              data-name="Path 13262"
                              d="M9.576,0h279.19V169.954H0V9.606A9.591,9.591,0,0,1,9.576,0Z"
                              transform="translate(9281.63 24233.381)"
                              fill="none"
                            />
                            <path
                              id="Rectangle_17245"
                              data-name="Rectangle 17245"
                              d="M14,0H288a0,0,0,0,1,0,0V29a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V14A14,14,0,0,1,14,0Z"
                              transform="translate(9281.499 24233)"
                              fill="#7e7e7e"
                            />
                            <rect
                              id="Rectangle_17246"
                              data-name="Rectangle 17246"
                              width="98"
                              height="141"
                              transform="translate(9281.499 24262)"
                              fill="#f9f9f9"
                            />
                            <rect
                              id="Rectangle_17247"
                              data-name="Rectangle 17247"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(9293.499 24273)"
                              fill="#F6428B"
                            />
                            <rect
                              id="Rectangle_17248"
                              data-name="Rectangle 17248"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(9293.499 24308)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17249"
                              data-name="Rectangle 17249"
                              width="74"
                              height="15"
                              rx="3"
                              transform="translate(9293.499 24326)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17250"
                              data-name="Rectangle 17250"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(9293.499 24344)"
                              fill="#f2f2f2"
                            />
                            <rect
                              id="Rectangle_17251"
                              data-name="Rectangle 17251"
                              width="74"
                              height="14"
                              rx="3"
                              transform="translate(9293.499 24362)"
                              fill="#f2f2f2"
                            />
                            <g
                              transform="matrix(1, 0, 0, 1, 9281.5, 24233)"
                              filter="url(#Rectangle_17252)"
                            >
                              <rect
                                id="Rectangle_17252-2"
                                data-name="Rectangle 17252"
                                width="190"
                                height="141"
                                transform="translate(98 29)"
                                fill="#f9f9f9"
                              />
                            </g>
                            <rect
                              id="Rectangle_17253"
                              data-name="Rectangle 17253"
                              width="73"
                              height="14"
                              rx="3"
                              transform="translate(9458.499 24273)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17254"
                              data-name="Rectangle 17254"
                              transform="translate(9397.499 24308)"
                              fill="#f2f2f2"
                              stroke="#F6428B"
                              stroke-width="1"
                            >
                              <rect
                                width="74"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="73"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <rect
                              id="Rectangle_17255"
                              data-name="Rectangle 17255"
                              width="156"
                              height="15"
                              rx="3"
                              transform="translate(9397.499 24336)"
                              fill="#f2f2f2"
                            />
                            <g
                              id="Rectangle_17256"
                              data-name="Rectangle 17256"
                              transform="translate(9397.499 24364)"
                              fill="#f2f2f2"
                              stroke="#F6428B"
                              stroke-width="1"
                            >
                              <rect
                                width="156"
                                height="15"
                                rx="3"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="155"
                                height="14"
                                rx="2.5"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Group_11335"
                              data-name="Group 11335"
                              transform="translate(9294.822 24244.797)"
                            >
                              <ellipse
                                id="Ellipse_87"
                                data-name="Ellipse 87"
                                cx="4"
                                cy="3.5"
                                rx="4"
                                ry="3.5"
                                transform="translate(-0.322 0.203)"
                                fill="#e87161"
                              />
                              <circle
                                id="Ellipse_88"
                                data-name="Ellipse 88"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(8.678 0.203)"
                                fill="#e7ce6b"
                              />
                              <circle
                                id="Ellipse_89"
                                data-name="Ellipse 89"
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                transform="translate(17.678 0.203)"
                                fill="#17680a"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    )}
                    <div className="theme-name">
                      {themeSettings.mode === "0" || chnageMode === "dark" ? (
                        <>
                          <span>Hot Pink</span>
                          <span className="check-circle">
                            {color === "5" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#ff4cca"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#ff4cca"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      ) : (
                        <>
                          <span>Pink</span>
                          <span className="check-circle">
                            {color === "5" ? (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#F6428B"
                                  />
                                  <path
                                    id="Path_13261"
                                    data-name="Path 13261"
                                    d="M146.209-342.885c-.1.039-1.115,1.028-2.806,2.72l-2.659,2.65-1.115-1.11c-.616-.607-1.18-1.136-1.249-1.176a.848.848,0,0,0-1.171.377.782.782,0,0,0-.022.737,18.084,18.084,0,0,0,1.592,1.679,10.9,10.9,0,0,0,1.679,1.557.97.97,0,0,0,.733-.091c.208-.143,5.912-5.843,6.073-6.068a.634.634,0,0,0,.139-.486.624.624,0,0,0-.13-.473A.886.886,0,0,0,146.209-342.885Z"
                                    transform="translate(-131.179 350.267)"
                                    fill="#F6428B"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <svg
                                  id="check-mark-_1_"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.165 22.168"
                                >
                                  <path
                                    id="Path_13260"
                                    data-name="Path 13260"
                                    d="M10.554-511.543a11.1,11.1,0,0,0-6.814,3.21A11.092,11.092,0,0,0,.578-502a14.8,14.8,0,0,0,0,3.036,11.1,11.1,0,0,0,3.153,6.324,11.092,11.092,0,0,0,6.333,3.162,14.8,14.8,0,0,0,3.036,0,11.1,11.1,0,0,0,6.324-3.153,11.092,11.092,0,0,0,3.162-6.333,14.8,14.8,0,0,0,0-3.036,11.1,11.1,0,0,0-3.153-6.324,11.067,11.067,0,0,0-6.289-3.153A16.622,16.622,0,0,0,10.554-511.543Zm2.438,1.8A9.263,9.263,0,0,1,18.2-507.1a9.272,9.272,0,0,1,2.655,5.248,9.012,9.012,0,0,1,.074,1.366,9.012,9.012,0,0,1-.074,1.366,9.272,9.272,0,0,1-2.655,5.248,9.272,9.272,0,0,1-5.248,2.655,12,12,0,0,1-2.733,0,9.272,9.272,0,0,1-5.248-2.655,9.272,9.272,0,0,1-2.655-5.248,12,12,0,0,1,0-2.733A9.272,9.272,0,0,1,4.968-507.1a9.281,9.281,0,0,1,5.856-2.728A16.186,16.186,0,0,1,12.992-509.742Z"
                                    transform="translate(-0.5 511.567)"
                                    fill="#707070"
                                  />
                                </svg>
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="changemode-flex">
                  <div className="changemode">
                    <label htmlFor="">Customize primary color</label>
                    <p>
                      Customise the look of your workspace. Feeling adventurous?
                    </p>
                  </div>
                  <div className="input-color">
                    <input
                      type="text"
                      name="customColor"
                      value={themeSettings.customColor}
                      onChange={handleInputChange}
                      minLength={0}
                      maxLength={7}
                    />
                  </div>
                </div>
                <div className="act-btn">
                  <button disabled={!chnageData}>Submit</button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThemeSettings;
