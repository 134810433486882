import React, { useEffect, useState, useRef } from "react";
import "./CourseManagement.css";
import { Form, FloatingLabel, Modal, Button, Alert } from "react-bootstrap";
import SettingsService from "../../../services/settings.service";
import { Stage, Layer, Text, Line, Group, Image } from "react-konva";
import certificate from "../../../assets/images/GAA-Certificate.png";
import Select from "react-select";

import CourseService from "../../../services/course.service";
import AuthService from "../../../services/auth.service";

const EditCertificateElement = ({
  elimaid,
  setShow,
  setDataUpload,
  setMessage,
  setCertificateEles,
  setAddedElements,
}) => {
  const [draggedElements, setDraggedElements] = useState({});

  const [options, setOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState();

  const [selectCertEle, setSelectCertEle] = useState({
    element_id: "",
    file: "",
    fontsize: "",
    fontfamily: "",
    color: "",
    corsid: "",
  });

  const [showFileInput, setShowFileInput] = useState(false);

  const [fontFamilyOpts, setFontFamilyOpts] = useState([
    { label: "Courier", value: "Courier" },
    { label: "Helvetica", value: "Helvetica" },
    { label: "Calibri", value: "Calibri" },
    { label: "Times New Roman", value: "Times New Roman" },
    { label: "Roboto", value: "Roboto" },
    { label: "Open Sans", value: "Open Sans" },
  ]);

  const [selectedFontFamilyOpts, setSelectedFontFamilyOpts] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      SettingsService.getCertificateElements(user.user)
        .then((response) => {
          // setCertificateEles(response.data);
          setOptions(
            response.data.map((cert) => ({
              value: cert.id,
              label: cert.elementname,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }

    CourseService.getCourseCerEleById(elimaid)
      .then((response) => {
        setSelectCertEle({
          element_id: response.data[0].element_id,
          file: response.data[0].file,
          fontsize: response.data[0].font_size,
          fontfamily: response.data[0].font_family,
          color: response.data[0].color,
          corsid: response.data[0].course_id,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectCertEle({ ...selectCertEle, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setSelectCertEle({ ...selectCertEle, [name]: e.target.files[0] });
    // setUploaded(true);
  };

  const handleSelectOptionChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
    const value = selectedOption ? selectedOption.value : "";
    setSelectCertEle({ ...selectCertEle, element_id: value });

    if (value == "5" || value == "6" || value == "7") {
      setShowFileInput(true);
    } else {
      setShowFileInput(false);
    }
  };

  const handleFontFamilyChange = (selectedFontFamilyOpts) => {
    setSelectedFontFamilyOpts(selectedFontFamilyOpts);
    const value = selectedFontFamilyOpts ? selectedFontFamilyOpts.value : "";
    setSelectCertEle({ ...selectCertEle, fontfamily: value });
  };

  const updateCourseCerEle = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    const user = AuthService.getCurrentUser();

    formData.append("elima", elimaid);
    formData.append("element_id", selectCertEle.element_id);
    formData.append("file", selectCertEle.file);
    formData.append("fontsize", selectCertEle.fontsize);
    formData.append("fontfamily", selectCertEle.fontfamily);
    formData.append("color", selectCertEle.color);
    formData.append("corsid", selectCertEle.corsid);
    formData.append("usp", user.user);

    CourseService.addCourseCerEle(formData)
      .then((response) => {
        setDataUpload(true);
        setMessage("Certificate Element Updated Successfully.");
        setShow(false);

        CourseService.getCourseCerEle(selectCertEle.corsid)
          .then((response) => {
            setCertificateEles(response.data);
          })
          .catch((e) => {
            console.log(e);
          });

        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };

  return (
    <>
      <div className="">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Certificate Elements</Form.Label>
            <div className="input-box">
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--chips-color)"
                      : "var(--chips-color)",
                    height: "45px",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--body-dark-bg)",
                    color: isSelected
                      ? "var(--body-dark-bg)"
                      : isFocused
                      ? "var(--body-dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                // className="react-select-container"
                // classNamePrefix="react-select"
                onChange={handleSelectOptionChange}
                // value={selectedOptions}
                options={options}
                issearchable="true"
                placeholder="Select Course Category"
                value={
                  Array.isArray(options)
                    ? options.filter(
                        (option) => selectCertEle.element_id == option.value
                      )
                    : []
                }
              />
            </div>
          </Form.Group>
          {showFileInput ? (
            <>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Upload File</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="file"
                    name="file"
                    value={selectCertEle.file}
                    onChange={handleFileChange}
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20.199 20.201"
                    >
                      <g
                        id="Group_11227"
                        data-name="Group 11227"
                        transform="translate(-2402.326 -6200.833)"
                      >
                        <path
                          id="Path_11890"
                          data-name="Path 11890"
                          d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                          transform="translate(752.656 5510.435)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_11892"
                          data-name="Path 11892"
                          d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                          transform="translate(751.221 5511.583)"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Font Size</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="text"
                    placeholder="Enter Font Size"
                    name="fontsize"
                    value={selectCertEle.fontsize}
                    onChange={handleInputChange}
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20.199 20.201"
                    >
                      <g
                        id="Group_11227"
                        data-name="Group 11227"
                        transform="translate(-2402.326 -6200.833)"
                      >
                        <path
                          id="Path_11890"
                          data-name="Path 11890"
                          d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                          transform="translate(752.656 5510.435)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_11892"
                          data-name="Path 11892"
                          d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                          transform="translate(751.221 5511.583)"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Font Family</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--chips-color)"
                          : "var(--chips-color)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--body-dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    // className="react-select-container"
                    // classNamePrefix="react-select"
                    onChange={handleFontFamilyChange}
                    // value={selectedFontFamilyOpts}
                    options={fontFamilyOpts}
                    issearchable="true"
                    placeholder="Select Font Family"
                    value={
                      Array.isArray(fontFamilyOpts)
                        ? fontFamilyOpts.filter(
                            (option) => selectCertEle.fontfamily == option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="font-color" controlId="formBasicName">
                <Form.Label>Font Color *</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="color"
                    placeholder="Enter Font Color"
                    name="color"
                    value={selectCertEle.color}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </Form.Group>
            </>
          )}
          <div className="elements-flex modal-footer">
            <button className="no" variant="danger" onClick={handleClose}>
              No
            </button>
            <button
              className="yes"
              variant="primary"
              onClick={updateCourseCerEle}
            >
              Yes
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditCertificateElement;
