import React, { useEffect } from "react";
import { useState } from "react";
import CourseService from "../services/course.service";
import LoadingGif from "../../src/assets/images/Loading.gif";
import ReportService from "../services/report.service";
import axios from "axios";

import { useParams } from "react-router-dom";

const PlayerNew = () => {
  const { userData } = useParams();

  // const [userDataFromApp, setUserDataFromApp] = useState({
  //   aid: "",
  //   cid: "",
  //   fileName: "",
  //   attempt: "",
  //   attemptCount: "",
  //   token: "",
  //   user: "",
  // });

  const userDataFromApp = JSON.parse(decodeURIComponent(userData));

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [activityResult, setActivityResult] = useState({
    strscore_max: "",
    strscore_min: "",
    strscore_raw: "",
    strsession_time: "",
    strlesson_status: "",
    course_id: userDataFromApp && userDataFromApp.cid,
    activity_id: userDataFromApp && userDataFromApp.aid,
    activity_typeid: "",
    student_id: "",
  });

  const [startPage, setStartPage] = useState(
    "https://aeonlms.aeonsoftware.net/cit/" +
      encodeURIComponent(userDataFromApp.fileName) +
      "/index_lms.html"
  );

  // useEffect(() => {
  //   const decodedUserData = JSON.parse(decodeURIComponent(userData));
  //   // setUserDataFromApp({
  //   //   aid: decodedUserData.aid,
  //   //   cid: decodedUserData.cid,
  //   //   fileName: decodedUserData.fileName,
  //   //   attempt: decodedUserData.attempt,
  //   //   attemptCount: decodedUserData.attemptCount,
  //   //   token: decodedUserData.token,
  //   //   user: decodedUserData.user,
  //   // });
  // }, []);

  const scromRun = (cid, aid, uid) => {
    let activityRes = {};

    const tspInit = (window, storage, prefix, callback) => {
      prefix = typeof prefix !== "undefined" ? prefix : "";
      callback = typeof callback !== "undefined" ? callback : console.log;

      window.API = {};

      window.scormStatus = {
        lesson_status: "",
        score_raw: 0,
        score_max: 100,
        score_min: 0,
        session_time: 0,
        detailed_answers: {},
        lesson_location: "",
        student_id: uid,
        course_id: cid,
      };

      window.API.LMSInitialize = function () {
        console.log("LMSInitialize");
      };
      window.API.LMSTerminate = function () {
        console.log("LMSTerminate");
      };

      window.API.LMSGetValue = function (varname) {
        varname = prefix + varname;
        var ret = storage.getItem(varname);
        if (
          ret == null &&
          varname.indexOf("_count", this.length - "_count".length) !== -1
        ) {
          ret = 0;
          storage.setItem(varname, ret);
        }

        return ret;
      };

      window.API.LMSSetValue = function (varname, varvalue) {
        varname = prefix + varname;

        var m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.id/);
        if (m != null) {
          storage.setItem(
            "{{scorm.id}}.cmi.interactions._count",
            parseInt(m[2]) + 1
          );
        }

        m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.result/);
        if (m != null) {
          var key = storage.getItem(
            prefix + "cmi.interactions." + parseInt(m[2]) + ".id"
          );
          window.scormStatus.detailed_answers[key] = varvalue;
        }

        if (varname === prefix + "cmi.core.lesson_status")
          window.scormStatus.lesson_status = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });
        if (varname === prefix + "cmi.core.score.raw")
          window.scormStatus.score_raw = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_raw: window.scormStatus.score_raw,
        });
        if (varname === prefix + "cmi.core.score.max")
          window.scormStatus.score_max = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_max: window.scormStatus.score_max,
        });
        if (varname === prefix + "cmi.core.score.min")
          window.scormStatus.score_min = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_min: window.scormStatus.score_min,
        });
        if (varname === prefix + "cmi.core.session_time")
          window.scormStatus.session_time = varvalue;
        setActivityResult({
          ...activityResult,
          strsession_time: window.scormStatus.session_time,
        });

        if (varname === prefix + "cmi.core.lesson_location")
          window.scormStatus.lesson_location = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });

        if (varname === prefix + "cmi.core.student_id") {
          window.scormStatus.student_id = varvalue;
          setActivityResult({
            ...activityResult,
            student_id: window.scormStatus.student_id,
          });
        }

        if (varname === prefix + "cmi.core.course_id") {
          window.scormStatus.course_id = varvalue;
          setActivityResult({
            ...activityResult,
            student_id: window.scormStatus.course_id,
          });
        }

        storage.setItem(varname, varvalue);
      };

      window.API.LMSCommit = function () {
        // console.log("LMSCommit");
        callback(window.scormStatus);
        return true;
      };

      window.API.LMSFinish = function () {
        // console.log("LMSFinish");
        const keys = Object.keys(localStorage);
        const newData = {};
        keys.forEach((key) => {
          if (key.startsWith(`CORS.${cid}.ACT.${aid}.UID.${uid}.`)) {
            const storedValue = localStorage.getItem(key);
            newData[key] = storedValue;
          }
        });

        const scromdata = {
          userid: uid,
          csid: cid,
          activity_id: aid,
          scrdata: newData,
        };

        uploadScromData(scromdata);

        uploadResult(activityRes);
      };

      window.API.LMSGetLastError = function () {};
      window.API.LMSGetErrorString = function () {};
      window.API.LMSGetDiagnostic = function () {};
    };

    const supports_html5_storage = () => {
      try {
        return "localStorage" in window && window["localStorage"] !== null;
      } catch (e) {
        return false;
      }
    };

    if (!supports_html5_storage()) {
      window.localStorage = {};
    }

    tspInit(
      window,
      window.localStorage,
      `CORS.${userDataFromApp && userDataFromApp.cid}.ACT.${
        userDataFromApp && userDataFromApp.aid
      }.UID.${userDataFromApp && userDataFromApp.user}.`,
      function (progress) {
        console.log("progress", progress);
        var progressString = JSON.stringify(progress);
        localStorage.setItem("std_progress", progressString);
        activityRes = {
          strscore_max: progress.score_max,
          strscore_min: progress.score_min,
          strscore_raw: progress.score_raw,
          strsession_time: progress.session_time,
          strlesson_status: progress.lesson_status,
          course_id: userDataFromApp && userDataFromApp.cid,
          activity_id: userDataFromApp && userDataFromApp.aid,
          student_id: userDataFromApp && userDataFromApp.user,
          activity_typeid: "1",
        };
      }
    );
  };

  const uploadScromData = async (scromdata) => {
    try {
      const response = await axios.post(
        "https://aeonlms.aeonsoftware.net/cit/api/scrmenterdata",
        scromdata,
        {
          headers: {
            Authorization: `Bearer ${userDataFromApp.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const uploadResult = async (data) => {
    try {
      const response = await axios.post(
        "https://aeonlms.aeonsoftware.net/cit/api/pvrpro",
        data,
        {
          headers: {
            Authorization: `Bearer ${userDataFromApp.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        alert("Result Uploaded Successfully");
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userDataFromApp) {
      setActivityResult({
        ...activityResult,
        student_id: userDataFromApp.user,
      });
      scromRun(userDataFromApp.cid, userDataFromApp.aid, userDataFromApp.user);
    }
  }, [userDataFromApp && userDataFromApp.aid]);

  return (
    <>
      {userDataFromApp &&
      userDataFromApp.attempt !== "0" &&
      userDataFromApp &&
      userDataFromApp.attemptCount >= userDataFromApp &&
      userDataFromApp.attempt ? (
        <>
          <div className="loadinggif-flex">
            <img src={LoadingGif} className="loadinggif" alt="" />
            <span>You have reached the maximum number of attempts.</span>
          </div>
        </>
      ) : (
        <>
          <iframe
            src={startPage}
            style={{ width: "100%", height: "100vh", border: "none" }}
            title="Content"
          />
        </>
      )}
    </>
  );
};
export default PlayerNew;
