import React, { useEffect, useState } from "react";
import { Form, Accordion, FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import CourseService from "../../../services/course.service";
import AuthService from "../../../services/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CourseConditions = ({ actid, cid, handleClose }) => {
  const [courseCons, setCourseCons] = useState({
    course_id: cid,
    activityId: actid,
    gradingMethod: "2",
    maxGrade: "100",
    attemptsCount: "",
    attemptsGrading: "1",
    forNewAttempt: "1",
    lockAfterFinalAttempt: "1",
    forceCompleted: "1",
    autoContinue: "1",
    autoCommit: "1",
    masteryScore: "2",
    restrictAccess: "1",
    completionTracking: "",
    commonRestrictions: [],
    minScore: "",
    completionStatus: [],
    completedOn: "",
    compactper: "",
  });

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      getCourseConds(user.user, cid, actid);
    }
  }, []);

  const getCourseConds = (uid, cid, actid) => {
    CourseService.getCourseConditions(uid, cid, actid)
      .then((resp) => {
        setCourseCons({
          course_id: resp.data[0].course_id,
          activityId: resp.data[0].activityId,
          gradingMethod: resp.data[0].gradingMethod,
          maxGrade: resp.data[0].maxGrade,
          attemptsCount: resp.data[0].attemptsCount,
          attemptsGrading: resp.data[0].attemptsGrading,
          forNewAttempt: resp.data[0].forNewAttempt,
          lockAfterFinalAttempt: resp.data[0].lockafatempt,
          forceCompleted: resp.data[0].forceCompleted,
          autoContinue: resp.data[0].autoContinue,
          autoCommit: resp.data[0].autoCommit,
          masteryScore: resp.data[0].masteryScore,
          restrictAccess: resp.data[0].restrictAccess,
          completionTracking: resp.data[0].completionTracking,
          commonRestrictions: resp.data[0].commonRestrictions.split(","),
          minScore: resp.data[0].minScore,
          completionStatus: resp.data[0].completionStatus.split(","),
          completedOn: resp.data[0].completedOn,
          compactper: resp.data[0].courseactcomplpercent,
        });
      })
      .catch((e) => console.log(e));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseCons({ ...courseCons, [name]: value });
  };

  const conditionOpts = [
    { value: "1", label: "None" },
    { value: "2", label: "After previous activity has been completed" },
  ];

  const gradingMethod = [
    { value: "1", label: "Learning Objects" },
    { value: "2", label: "Highest Grade" },
    { value: "3", label: "Average Grade" },
    { value: "4", label: "Sum Grade" },
  ];

  const attemptsOpts = [
    { value: "0", label: "Unlimitted" },
    { value: "1", label: "1 Attempt" },
    { value: "2", label: "2 Attempt" },
    { value: "3", label: "3 Attempt" },
    { value: "4", label: "4 Attempt" },
    { value: "5", label: "5 Attempt" },
    { value: "6", label: "6 Attempt" },
  ];

  const gradingAttempts = [
    { value: "1", label: "Highest Attempt" },
    { value: "2", label: "Average Attempts" },
    { value: "3", label: "First Attempt" },
    { value: "4", label: "Last Completed Attempt" },
  ];

  const forceNewAttemptsOpts = [
    { value: "1", label: "No" },
    { value: "2", label: "When previous attempt completed, passed or failed" },
    { value: "3", label: "Always" },
  ];

  const lockFinalAttemptsOpts = [
    { value: "1", label: "No" },
    { value: "2", label: "Yes" },
  ];

  const completionTrackingOpts = [
    { value: "1", label: "Do not indicate activity completion" },
    {
      value: "2",
      label: "Students can manually mark the activity as completed",
    },
    { value: "3", label: "Show activity as complete when conditions are met" },
  ];

  const activityViewOpts = [
    { value: "1", label: "Student must view this activity to complete it" },
    { value: "2", label: "Must be completed to complete course" },
    {
      value: "3",
      label: "Student must receive a grade to complete this activity",
    },
  ];

  const completionStatusOpts = [
    { value: "1", label: "Passed" },
    { value: "2", label: "Completed" },
  ];

  const handleSelectChange = (event, name) => {
    const conditions = event ? event.value : "";
    setCourseCons({ ...courseCons, [name]: conditions });
  };

  const handleOptionChange = (event, name) => {
    const values = Array.isArray(event)
      ? event.map((option) => option.value).toString()
      : null;
    const valSplit = values.split(",");

    setCourseCons({ ...courseCons, [name]: valSplit });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const user = AuthService.getCurrentUser();

    const data = {
      usp: user.user,
      course_id: cid,
      activityId: actid,
      gradingMethod: courseCons.gradingMethod,
      maxGrade: courseCons.maxGrade,
      attemptsCount: courseCons.attemptsCount,
      attemptsGrading: courseCons.attemptsGrading,
      forNewAttempt: courseCons.forNewAttempt,
      lockAfterFinalAttempt: courseCons.lockAfterFinalAttempt,
      forceCompleted: courseCons.forceCompleted,
      autoContinue: courseCons.autoContinue,
      autoCommit: courseCons.autoCommit,
      masteryScore: courseCons.masteryScore,
      restrictAccess: courseCons.restrictAccess,
      completionTracking: courseCons.completionTracking,
      commonRestrictions: courseCons.commonRestrictions,
      minScore: courseCons.minScore,
      completionStatus: courseCons.completionStatus,
      completedOn: courseCons.completedOn,
      compactper: courseCons.compactper,
    };

    CourseService.addCourseConditions(data)
      .then((resp) => {
        toast.success("Course conditions applied succesfully.!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        handleClose();
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <ToastContainer />
      <Form>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Activity Percentage *</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                {/* <Form.Label>Maximum grade</Form.Label> */}
                <div className="input-box">
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    placeholder="Completion Percentage"
                    name="compactper"
                    value={courseCons && courseCons.compactper}
                    onChange={handleInputChange}
                    required
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20.199 20.201"
                    >
                      <g
                        id="Group_11227"
                        data-name="Group 11227"
                        transform="translate(-2402.326 -6200.833)"
                      >
                        <path
                          id="Path_11890"
                          data-name="Path 11890"
                          d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                          transform="translate(752.656 5510.435)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_11892"
                          data-name="Path 11892"
                          d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                          transform="translate(751.221 5511.583)"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Grade</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Grading method</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                        color: "var(--whiteColor)",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={gradingMethod}
                    issearchable="true"
                    placeholder="Select"
                    name="gradingMethod"
                    onChange={(event) =>
                      handleSelectChange(event, "gradingMethod")
                    }
                    value={
                      courseCons && Array.isArray(gradingMethod)
                        ? gradingMethod.filter(
                            (option) =>
                              courseCons.gradingMethod === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Maximum grade</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    placeholder="Maximum grade"
                    name="maxGrade"
                    value={courseCons && courseCons.maxGrade}
                    onChange={handleInputChange}
                    required
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20.199 20.201"
                    >
                      <g
                        id="Group_11227"
                        data-name="Group 11227"
                        transform="translate(-2402.326 -6200.833)"
                      >
                        <path
                          id="Path_11890"
                          data-name="Path 11890"
                          d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                          transform="translate(752.656 5510.435)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_11892"
                          data-name="Path 11892"
                          d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                          transform="translate(751.221 5511.583)"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Attempts management</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>No. of Attempts</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={attemptsOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="attemptsCount"
                    onChange={(event) =>
                      handleSelectChange(event, "attemptsCount")
                    }
                    value={
                      courseCons && Array.isArray(attemptsOpts)
                        ? attemptsOpts.filter(
                            (option) =>
                              courseCons.attemptsCount === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Attempts Grading</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={gradingAttempts}
                    issearchable="true"
                    placeholder="Select"
                    name="attemptsGrading"
                    onChange={(event) =>
                      handleSelectChange(event, "attemptsGrading")
                    }
                    value={
                      courseCons && Array.isArray(gradingAttempts)
                        ? gradingAttempts.filter(
                            (option) =>
                              courseCons.attemptsGrading === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Force new attempt</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={forceNewAttemptsOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="forNewAttempt"
                    onChange={(event) =>
                      handleSelectChange(event, "forNewAttempt")
                    }
                    value={
                      courseCons && Array.isArray(forceNewAttemptsOpts)
                        ? forceNewAttemptsOpts.filter(
                            (option) =>
                              courseCons.forNewAttempt === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Lock after final attempt</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={lockFinalAttemptsOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="lockAfterFinalAttempt"
                    onChange={(event) =>
                      handleSelectChange(event, "lockAfterFinalAttempt")
                    }
                    value={
                      courseCons && Array.isArray(lockFinalAttemptsOpts)
                        ? lockFinalAttemptsOpts.filter(
                            (option) =>
                              courseCons.lockAfterFinalAttempt === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Compatibility settings</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Force completed</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={lockFinalAttemptsOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="forceCompleted"
                    onChange={(event) =>
                      handleSelectChange(event, "forceCompleted")
                    }
                    value={
                      courseCons && Array.isArray(lockFinalAttemptsOpts)
                        ? lockFinalAttemptsOpts.filter(
                            (option) =>
                              courseCons.forceCompleted === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Auto-continue</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={lockFinalAttemptsOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="autoContinue"
                    onChange={(event) =>
                      handleSelectChange(event, "autoContinue")
                    }
                    value={
                      courseCons && Array.isArray(lockFinalAttemptsOpts)
                        ? lockFinalAttemptsOpts.filter(
                            (option) => courseCons.autoContinue === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Auto-commit</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={lockFinalAttemptsOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="autoCommit"
                    onChange={(event) =>
                      handleSelectChange(event, "autoCommit")
                    }
                    value={
                      courseCons && Array.isArray(lockFinalAttemptsOpts)
                        ? lockFinalAttemptsOpts.filter(
                            (option) => courseCons.autoCommit === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="" controlId="formBasicName">
                <Form.Label>Mastery score overrides status</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={lockFinalAttemptsOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="masteryScore"
                    onChange={(event) =>
                      handleSelectChange(event, "masteryScore")
                    }
                    value={
                      courseCons && Array.isArray(lockFinalAttemptsOpts)
                        ? lockFinalAttemptsOpts.filter(
                            (option) => courseCons.masteryScore === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Restrict Access</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="" controlId="formBasicName">
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={conditionOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="restrictAccess"
                    onChange={(event) =>
                      handleSelectChange(event, "restrictAccess")
                    }
                    value={
                      courseCons && Array.isArray(conditionOpts)
                        ? conditionOpts.filter(
                            (option) =>
                              courseCons.restrictAccess === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Activity completion</Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Completion tracking</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={completionTrackingOpts}
                    issearchable="true"
                    placeholder="Select"
                    name="completionTracking"
                    onChange={(event) =>
                      handleSelectChange(event, "completionTracking")
                    }
                    value={
                      courseCons && Array.isArray(completionTrackingOpts)
                        ? completionTrackingOpts.filter(
                            (option) =>
                              courseCons.completionTracking === option.value
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Common Restrictions</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={activityViewOpts}
                    issearchable="true"
                    isMulti={true}
                    placeholder="Select"
                    name="commonRestrictions"
                    onChange={(event) =>
                      handleOptionChange(event, "commonRestrictions")
                    }
                    value={
                      courseCons && Array.isArray(activityViewOpts)
                        ? activityViewOpts.filter(
                            (option) =>
                              courseCons.commonRestrictions &&
                              courseCons.commonRestrictions.includes(
                                option.value
                              )
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Require minimum score</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="number"
                    placeholder="Require minimum score"
                    max={100}
                    min={0}
                    name="minScore"
                    value={courseCons && courseCons.minScore}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Completion Status Restrictions</Form.Label>
                <div className="input-box">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        outline: "none",
                        border: "none",
                        borderRadius: "10px",
                        backgroundColor: state.isFocused
                          ? "var(--dark-bg)"
                          : "var(--dark-bg)",
                        height: "45px",
                      }),
                      option: (baseStyles, { isFocused, isSelected }) => ({
                        ...baseStyles,
                        backgroundColor: isSelected
                          ? "var(--primary-color)"
                          : isFocused
                          ? "var(--secondary-color)"
                          : "var(--dark-bg)",
                        color: isSelected
                          ? "var(--body-dark-bg)"
                          : isFocused
                          ? "var(--body-dark-bg)"
                          : "var(--whiteColor)",
                      }),
                    }}
                    options={completionStatusOpts}
                    issearchable="true"
                    isMulti={true}
                    placeholder="Select"
                    name="completionStatus"
                    onChange={(event) =>
                      handleOptionChange(event, "completionStatus")
                    }
                    value={
                      courseCons && Array.isArray(completionStatusOpts)
                        ? completionStatusOpts.filter(
                            (option) =>
                              courseCons.completionStatus &&
                              courseCons.completionStatus.includes(option.value)
                          )
                        : []
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group controlId="formBasicOrgName" className="mb-3">
                <Form.Label>Expect completed on</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="date"
                    name="completedOn"
                    style={{ width: "100%" }}
                    onChange={handleInputChange}
                    value={courseCons && courseCons.completedOn}
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14.02"
                      viewBox="0 0 81.923 76.58"
                    >
                      <g id="calendar" transform="translate(-15 482)">
                        <path
                          id="Path_13206"
                          data-name="Path 13206"
                          d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                        />
                        <path
                          id="Path_13207"
                          data-name="Path 13207"
                          d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                          transform="translate(0 -108.781)"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="modal-footer">
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={(event) => handleSubmit(event)}>
            Yes
          </button>
        </div>
      </Form>
    </>
  );
};

export default CourseConditions;
