import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./login.css";
import { Alert } from "react-bootstrap";

import AuthService from "../../services/auth.service";
import VerigyOTPService from "../../services/verifyotp.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Forgot = () => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [mailSending, setMailSending] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [showOTP, setShowOTP] = useState(false);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const onChangeEmail = (e) => {
    const username = e.target.value;
    setEmail(username);
  };

  const onChangeOTP = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const sendOTP = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      setMailSending(false);
      setError(true);
      setErrMessage("*Please fill all required fields!");

      setTimeout(() => {
        setError(false);
      }, 2000);

      return;
    }

    const data = {
      eml: email,
    };

    setMailSending(true);

    VerigyOTPService.sendOTP(data)
      .then((res) => {
        setMailSending(false);
        setShowOTP(true);
        setDataUpload(true);
        setMessage(
          "OTP Sent successfully to your registered email with us. OTP is valid for 5 minutes only."
        );

        localStorage.setItem("email", email);

        setTimeout(() => {
          setDataUpload(false);
          navigate("/ChangePassword");
        }, 5000);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Form noValidate validated={validated} onSubmit={sendOTP}>
                <div className="login active">
                  <div className="heading">
                    <h1>Forget Password</h1>
                  </div>
                  {mailSending ? (
                    <>
                      <Alert className="m-auto mb-5" variant="success">
                        Mail Sending Process
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  {dataUpload ? (
                    <>
                      <Alert className="m-auto mb-5" variant="success">
                        {message}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <>
                      <Alert className="m-auto mb-5" variant="danger">
                        {errMessage}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="form">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="input"
                        required
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required]}
                      />
                      <label htmlFor="">Email</label>
                    </div>
                    <div className="form-group action">
                      <div className="flex_form-group">
                        <button
                          className="fill_btn"
                          type="submit"
                          name="btn_login"
                        >
                          Send OTP
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Forgot;
