import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseModules from "./CourseModules";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import { Tab, Col, Nav, Row, Tabs } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import AdminUser from "../assets/images/admin-user.png";
import CourseService from "../services/course.service";
import Player from "./Player";
// import "./learn.css";
import AuthService from "../services/auth.service";

const ViewActivity = () => {
  const { cid } = useParams();
  const { aid } = useParams();

  const [currentCourse, setCurrentCourse] = useState({});
  const [currentActivity, setCurrentActivity] = useState({});
  const [highestScore, setHighestScore] = useState("");

  const [activityResult, setActivityResult] = useState([]);
  const [activitySts, setActivitySts] = useState("");
  const [attempt, setAttempt] = useState(5);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (cid && user) {
      getCurrentCourse(cid, user.user);
    }

    const data = {
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    if (user.role_id === "4") {
      fetchActivityResults(data);
    }

    if (cid) {
      fetchActivityDetails(cid);
    }
  }, []);

  const fetchActivityDetails = (cid) => {
    CourseService.getCourseActivity(cid)
      .then((response) => {
        const activityData = response.data;
        const filteredActivity = Array.isArray(activityData)
          ? activityData.filter((data) => data.id === aid)
          : null;
        setCurrentActivity(filteredActivity[0]);
      })
      .catch((e) => console.log(e));
  };

  const fetchActivityResults = (data) => {
    CourseService.fetchActivityResult(data)
      .then((res) => {
        setActivityResult(res.data);

        const activityComSts = res.data.map((item) => item.res_sts);

        const lastItem = activityComSts[activityComSts.length - 1];

        setActivitySts(lastItem);

        const scores = res.data.map((item) => item.score);

        const highestScore = scores.reduce((max, currentValue) => {
          return Math.max(max, currentValue);
        }, -Infinity);

        setHighestScore(highestScore);
      })
      .catch((e) => console.log(e));
  };

  const getCurrentCourse = (user, cid) => {
    CourseService.getCourseById(user, cid)
      .then((resp) => {
        setCurrentCourse(resp.data.course_info[0]);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="container-fluid learner">
        <div className="row" style={{ display: "none" }}>
          <div className="col-xl-4 col-lg-4">
            <div className="outer-box">
              <div className="action-flex">
                <Link to="/Learn">
                  <div className="action-item">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 19.379 19.382"
                      >
                        <g
                          id="left-arrow-in-circular-button-black-symbol"
                          transform="translate(-0.6 511.567)"
                        >
                          <path
                            id="Path_13057"
                            data-name="Path 13057"
                            d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                            transform="translate(0 0)"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>Back</span>
                  </div>
                </Link>
              </div>
              <div className="course-info">
                <div className="course-name">
                  <span>{currentCourse.name}</span>
                </div>
                <div className="course-modules">
                  <label htmlFor="">Course Module</label>
                  <CourseModules cid={cid} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8">
            <div className="row mb-3">
              <div className="col-xl-12 col-lg-12">
                <div className="outer-box">
                  <div className="course-overview player">
                    <div className="course-img">
                      <Player aid={aid} cid={cid} highestScore={highestScore} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8 col-xl-8">
                <div className="outer-box">
                  <div className="cflex">
                    <div className="heading">
                      <h1>{currentActivity.activity_name}</h1>
                    </div>
                  </div>
                  <div className="inner-box-flex">
                    <div className="inner-box left">
                      <p>Number of attempts allowed: {attempt}</p>
                      <p>
                        Number of attempts you have made:{" "}
                        {activityResult.length >= 0
                          ? activityResult.length
                          : "0"}
                      </p>
                      {Array.isArray(activityResult)
                        ? activityResult.map((activity, index) => (
                            <p key={activity.id}>
                              Grade for attempt {index + 1}: {activity.score}%
                            </p>
                          ))
                        : null}
                      <p>Grading method: Highest attempt</p>
                      <p>
                        Grade reported: {highestScore >= 0 ? highestScore : "0"}
                        %
                      </p>
                      <p>Result : {activitySts}</p>
                      {activityResult.length >= attempt ? (
                        <>
                          <p>
                            You have reached the maximum number of attempts.
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xl-4">
                <div className="outer-box chat">
                  <div className="cflex">
                    <div className="heading">
                      <h1>Chat Box</h1>
                    </div>
                  </div>
                  <div className="inner-box-flex">
                    <div className="inner-box left">
                      <div className="search-bar">
                        <input type="text" placeholder="Search..." />
                      </div>
                      <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                      >
                        <Tab eventKey="home" title="All">
                          <div className="user-list">
                            <div className="user-flex">
                              <div className="user-pic">
                                <img src={AdminUser} width="40px" alt="" />
                              </div>
                              <div className="user-info">
                                <span className="name">Name</span>
                                <span className="catname">
                                  You: Preview of message
                                </span>
                              </div>
                            </div>
                            <div className="user-flex">
                              <div className="user-pic">
                                <img src={AdminUser} width="40px" alt="" />
                              </div>
                              <div className="user-info">
                                <span className="name">Name</span>
                                <span className="catname">
                                  You: Preview of message
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="profile" title="People">
                          <div className="user-list">
                            <div className="user-flex">
                              <div className="user-pic">
                                <img src={AdminUser} width="40px" alt="" />
                              </div>
                              <div className="user-info">
                                <span className="name">Name</span>
                                <span className="catname">
                                  You: Preview of message
                                </span>
                              </div>
                            </div>
                            <div className="user-flex">
                              <div className="user-pic">
                                <img src={AdminUser} width="40px" alt="" />
                              </div>
                              <div className="user-info">
                                <span className="name">Name</span>
                                <span className="catname">
                                  You: Preview of message
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="contact" title="Group">
                          <div className="user-list">
                            <div className="user-flex">
                              <div className="user-pic">
                                <img src={AdminUser} width="40px" alt="" />
                              </div>
                              <div className="user-info">
                                <span className="name">Name</span>
                                <span className="catname">
                                  You: Preview of message
                                </span>
                              </div>
                            </div>
                            <div className="user-flex">
                              <div className="user-pic">
                                <img src={AdminUser} width="40px" alt="" />
                              </div>
                              <div className="user-info">
                                <span className="name">Name</span>
                                <span className="catname">
                                  You: Preview of message
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                    <div className="inner-box right">
                      <div className="top">
                        <div className="user-flex">
                          <div className="user-pic">
                            <img src={AdminUser} width="30px" alt="" />
                          </div>
                          <div className="user-info">
                            <span className="name">Name</span>
                          </div>
                        </div>
                      </div>
                      <div className="body">
                        <div className="left-m">
                          <div className="user-pic">
                            <img src={AdminUser} width="20px" alt="" />
                          </div>
                          <div className="message box3 sb14">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout.
                          </div>
                        </div>
                        <div className="right-m">
                          <div className="message box3 sb13">
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout.
                          </div>
                          <div className="user-pic">
                            <img src={AdminUser} width="20px" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Tab 1</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Tab 2</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="outer-box">
                  <div className="action-flex">
                    <Link to="/Learn">
                      <div className="action-item">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 19.379 19.382"
                          >
                            <g
                              id="left-arrow-in-circular-button-black-symbol"
                              transform="translate(-0.6 511.567)"
                            >
                              <path
                                id="Path_13057"
                                data-name="Path 13057"
                                d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        <span>Back</span>
                      </div>
                    </Link>
                  </div>
                  <div className="course-info">
                    <div className="course-name">
                      <span>{currentCourse.name}</span>
                    </div>
                    <div className="course-modules">
                      <label htmlFor="">Course Module</label>
                      <CourseModules cid={cid} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">First tab content</Tab.Pane>
                  <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default ViewActivity;
