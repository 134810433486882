import React from "react";

import Iframe from "react-iframe";

const Demo = () => {
  const data = {
    aid: "1",
    attempt: "1",
    attemptCount: "2",
    cid: "8",
    fileName: "uploads/extracted/Communication Skills Builder",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJsb2NhbGhvc3QiLCJhdWQiOiJsb2NhbGhvc3QiLCJzdWIiOiJ1c2VyIGxvZ2luIiwiaWF0IjoxNzIyMjQ3MDUxLCJleHAiOjIwODIyNDcwNTEsImVtYWlsIjoiYWVvbnNvZnQxQGdtYWlsLmNvbSIsInVzZXIiOiIyNiJ9.AuMoia-pFOI78FUdD_vsOwcFN3Ixw8knxD5jUaAnxE0",
    user: "26",
  };

  const jsondata = encodeURIComponent(JSON.stringify(data))

  return (
    <Iframe
      url={`http://aeonlms.aeonsoftware.net/PlayerNew/${jsondata}`}
      id="pdfPlayer"
      width="640px"
      height="320px"
      display="block"
      position="relative"
      className="mb-3"
    />
  );
};

export default Demo;
