import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import "./Onboarding.css";
import "./Responsive.css";
import AuthService from "../../services/auth.service";
import PermissionService from "../../services/permission.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminOnboard = () => {
  const [validated, setValidated] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState([]);

  const [currentUser, setCurrentUser] = useState("");

  const [permCode, setPermCode] = useState([]);

  const [adminPermissions, setAdminPermissions] = useState([]);

  const handleAdminPermissionChange = (e, l) => {
    let selectedUserArr = [];
    if (e.target.checked) {
      selectedUserArr = [...checked, l.id];
      setChecked([...checked, l.id]);
    } else {
      selectedUserArr = checked.filter((item) => item !== l.id);
      setChecked(checked.filter((item) => item !== l.id));
    }
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allLearners = adminPermissions.map((l) => l.id);
      setChecked(allLearners);
    } else {
      setChecked([]);
    }
  };

  const getPermCatalog = (user) => {
    PermissionService.getPermCata(user.user)
      .then((respcat) => {
        PermissionService.getRolePermission(user.role_id, user.user)
          .then((res) => {
            const rolePermission = res.data[0].permissions.split(",");

            const filteredData = respcat.data.filter((data) => {
              return rolePermission.some((permission) =>
                permission.startsWith(data.shortname)
              );
            });

            localStorage.setItem("adminPerm", JSON.stringify(filteredData));

            setAdminPermissions(filteredData);
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));

    const adminPermData = localStorage.getItem("adminPerm");

    if (adminPermData) {
      const existingData = JSON.parse(adminPermData);
      setAdminPermissions(existingData);
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    getPermCatalog(user);
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      adminonboard: checked,
    };

    if (checked.length > 0) {
      toast.success("Dashboard Configured Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.warn("You have not selected anything.!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="admin-onboard flex-center"
      >
        <ToastContainer />
        <Form.Group controlId="formBasicOrgName" className="admin-per">
          <Form.Label>
            Which are most important to you on LMS Dashboard?
          </Form.Label>
          <div className="multiple-input active admin">
            <div className="addedCat grid">
              {Array.isArray(adminPermissions) &&
                adminPermissions.map((adminPermission, index) => {
                  return (
                    <div
                      className={
                        checked.includes(adminPermission.id)
                          ? "addedCat-item checked"
                          : "addedCat-item"
                      }
                      key={index}
                    >
                      <input
                        type="checkbox"
                        value="1"
                        id=""
                        name=""
                        checked={checked.includes(adminPermission.id)}
                        onChange={(e) =>
                          handleAdminPermissionChange(e, adminPermission)
                        }
                      />
                      <span>{adminPermission.permission}</span>
                    </div>
                  );
                })}
              <div
                className={
                  checked.length === adminPermissions.length
                    ? "addedCat-item checked"
                    : "addedCat-item"
                }
              >
                <input
                  type="checkbox"
                  value="6"
                  onChange={handleCheckAllChange}
                  checked={checked.length === adminPermissions.length}
                />
                <span>All</span>
              </div>
            </div>
          </div>
        </Form.Group>
        <div className="">
          <input type="submit" className="onboard-btn-f" value="Submit" />
        </div>
      </Form>
    </>
  );
};
export default AdminOnboard;
