import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "../dashboard.css";

import CourseService from "../../../../services/course.service";
import AuthService from "../../../../services/auth.service";

Chart.register(...registerables);

const CourseOverview = () => {
  const [activeCourses, setActiveCourses] = useState("0");
  const [inactiveCourses, setInactiveCourses] = useState("0");
  const [draftCourses, setDraftCourses] = useState("0");

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    CourseService.getCourse(user.user)
      .then((response) => {
        const activeCount = response.data.filter(
          (course) => course.status === "1" && course.pubsts === "1"
        ).length;
        const inactiveCount = response.data.filter(
          (course) => course.status === "0"
        ).length;
        const draftCount = response.data.filter(
          (course) => course.pubsts === "0"
        ).length;

        setActiveCourses(
          ((activeCount / response.data.length) * 100).toFixed(0)
        );
        setInactiveCourses(
          ((inactiveCount / response.data.length) * 100).toFixed(0)
        );
        setDraftCourses(((draftCount / response.data.length) * 100).toFixed(0));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const data = {
    labels: ["Inactive Courses", "Courses in Draft", "Active Courses"],
    datasets: [
      {
        label: "Manage Courses",
        data: [inactiveCourses, draftCourses, activeCourses],
        backgroundColor: ["#3DFEC9", "#D96DFF", "#34CEFF"],
        borderColor: ["transparent"],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    cutout: 90,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
  };

  return (
    <>
      <div className="error-report">
        <div className="my__progress-chart">
          <div className="chart">
            <Doughnut
              data={data}
              options={options}
              width="200px"
              height="200px"
            />
          </div>
          <div className="chart__data">
            <div className="chart__point">
              <div className="chart__color four"></div>
              <div className="chart__text">
                <span>Inactive Courses</span>
              </div>
              <div className="chart__data">
                <span>{inactiveCourses}%</span>
              </div>
            </div>
            <div className="chart__point">
              <div className="chart__color five"></div>
              <div className="chart__text">
                <span>Courses in Draft</span>
              </div>
              <div className="chart__data">
                <span>{draftCourses}%</span>
              </div>
            </div>
            <div className="chart__point">
              <div className="chart__color six"></div>
              <div className="chart__text">
                <span>Active Courses</span>
              </div>
              <div className="chart__data">
                <span>{activeCourses}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseOverview;
