import React, { useEffect } from "react";

const GoogleTranslate = () => {
  useEffect(() => {
    // Function to initialize Google Translate
    function googleTranslateElementInit() {
      new window.google.translate.TranslateElement(
        { pageLanguage: "en", includedLanguages: "en,ar,de,es" },
        "google_translate_element"
      );
    }

    // Load the Google Translate script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.head.appendChild(script);

    // Clean up function to remove the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Run this effect only once when the component mounts

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
