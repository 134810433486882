import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "../dashboard.css";
import ErrorreqService from "../../../../services/errorreq.service";

Chart.register(...registerables);

const ErrorReports = () => {
  const [resolvedReq, setResolvedReq] = useState("0");
  const [unresolvedReq, setUnresolvedReq] = useState("0");
  const [inprogressReq, setInprogressReq] = useState("0");

  var style = getComputedStyle(document.body);
  var whiteColor = style.getPropertyValue("--whiteColor");

  useEffect(() => {
    ErrorreqService.getErrorReq()
      .then((response) => {
        const resolvedCount = response.data.filter(
          (errorReq) => errorReq.status === "2"
        ).length;
        const unresolvedCount = response.data.filter(
          (errorReq) => errorReq.status === "0"
        ).length;
        const inprogressCount = response.data.filter(
          (errorReq) => errorReq.status === "1"
        ).length;

        setResolvedReq(
          ((resolvedCount / response.data.length) * 100).toFixed(0)
        );
        setUnresolvedReq(
          ((unresolvedCount / response.data.length) * 100).toFixed(0)
        );
        setInprogressReq(
          ((inprogressCount / response.data.length) * 100).toFixed(0)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const data = {
    labels: ["Unresolved", "In Progress", "Resolved"],
    datasets: [
      {
        label: "Error Reports",
        data: [unresolvedReq, inprogressReq, resolvedReq],
        backgroundColor: ["#FE6D3D", "#EA1CB3", "#00FF0F"],
        borderColor: ["transparent"],
        hoverOffset: 4,
      },
    ],
  };

  const centerText = {
    id: "",
  };

  const options = {
    cutout: 90,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      centerText: [centerText],
    },
  };

  return (
    <>
      <div className="error-report">
        <div className="my__progress-chart">
          <div className="chart">
            <Doughnut
              data={data}
              options={options}
              width="200px"
              height="200px"
            />
          </div>
          <div className="chart__data">
            <div className="chart__point">
              <div className="chart__color one"></div>
              <div className="chart__text">
                <span>Unresolved</span>
              </div>
              <div className="chart__data">
                <span>{unresolvedReq}%</span>
              </div>
            </div>
            <div className="chart__point">
              <div className="chart__color two"></div>
              <div className="chart__text">
                <span>In Progress</span>
              </div>
              <div className="chart__data">
                <span>{inprogressReq}%</span>
              </div>
            </div>
            <div className="chart__point">
              <div className="chart__color three"></div>
              <div className="chart__text">
                <span>Resolved</span>
              </div>
              <div className="chart__data">
                <span>{resolvedReq}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorReports;
