import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import CourseDetails from "./CourseDetails";
import Prerequisites from "./Prerequisites";
import Certificate from "./Certificate";
import UploadCourse from "./UploadCourse";
import OverviewPublish from "./OverviewPublish";

import CourseFlex from "./CourseFlex";

const CourseDraft = () => {
  const location = useLocation();
  const { cid } = location.state;

  const [step, setStep] = useState(2);

  const values = {};

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const skip = () => {
    setStep(step + 2);
  };

  const skipBack = () => {
    setStep(step - 2);
  };

  // const handleChange = (count) => {
  //   setStep(count);
  // }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer addUser createCourse">
              <div className="box-inner">
                <div className="heading">
                  <h1>Create Course</h1>
                </div>
                <CourseFlex step={step} />
                {step === 1 ? (
                  <>
                    <CourseDetails
                      nextStep={nextStep}
                      values={values}
                      cid={cid}
                    />
                  </>
                ) : step === 2 ? (
                  <>
                    <Prerequisites
                      prevStep={prevStep}
                      nextStep={nextStep}
                      skip={skip}
                      cid={cid}
                    />
                  </>
                ) : step === 3 ? (
                  <>
                    <UploadCourse
                      prevStep={prevStep}
                      nextStep={nextStep}
                      cid={cid}
                    />
                  </>
                ) : step === 4 ? (
                  <>
                    <Certificate
                      prevStep={prevStep}
                      nextStep={nextStep}
                      cid={cid}
                    />
                  </>
                ) : step === 5 ? (
                  <>
                    <OverviewPublish
                      prevStep={prevStep}
                      nextStep={nextStep}
                      cid={cid}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseDraft;
