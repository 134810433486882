
import http from "../http-common";
import authHeader from './auth-header';

const token = JSON.parse(localStorage.getItem('token'));

const getOrg = (baps) => {
  return http.get("/api/onboardingdata?baps=" + baps, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createOrg = (data) => {
    return http.post("/api/onboarding", data, { headers: {'Authorization': 'Bearer ' + token, "Content-Type": 'multipart/form-data'}});
};

const OrganisationService = {
    getOrg,
    createOrg
};

export default OrganisationService;