import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AdminUser from "../assets/images/admin-user.png";

const Conversation = ({showChat}) => {

    return (
        <>
            <div className="inner-box-flex">
                <div className="inner-box left">
                    <div className="search-bar">
                        <input type="text" placeholder="Search..." />
                    </div>
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                    >
                        <Tab eventKey="home" title="All">
                            <div className="user-list">
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="People">
                            <div className="user-list">
                                <div className="user-flex" onClick={showChat} >
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex" onClick={showChat}>
                                    <div className="user-pic">
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Group">
                            <div className="user-list">
                                <div className="user-flex">
                                    <div className="user-pic" onClick={showChat}>
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                                <div className="user-flex">
                                    <div className="user-pic" onClick={showChat}>
                                        <img src={AdminUser} width="40px" alt="" />
                                    </div>
                                    <div className="user-info">
                                        <span className="name">
                                            Name
                                        </span>
                                        <span className="catname">
                                            You: Preview of message
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default Conversation;