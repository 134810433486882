import React from "react";
import Form from "react-bootstrap/Form";
import "./Onboarding.css";

const Onboarding = ({ steps, handleChange }) => {
  return (
    <>
      <div className="onboard-list">
        <div className="onboard-people">
          <div className="cut-btn">
            <span>People</span>
          </div>
          <div className="onboard-act">
            <ul>
              <li className={steps === 1 ? "active" : ""}>Organization</li>
              <li
                className={steps === 2 ? "active" : steps === 3 ? "active" : ""}
              >
                Occupation Catageories
              </li>
              <li className={steps === 4 ? "active" : ""}>Permissions</li>
            </ul>
          </div>
        </div>
        <div className="onboard-courses">
          <div className="cut-btn">
            <span>Courses</span>
          </div>
          <div className="onboard-act">
            <ul>
              <li className={steps === 5 ? "active" : ""}>Categories</li>
              <li className={steps === 6 ? "active" : ""}>Badges</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
