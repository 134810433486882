
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getOccupCat = (baps) => {
  return http.get("/api/getoccupationcat?baps=" + baps, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createOccupCat = (data) => {
    return http.post("/api/occupcategory", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const updateOccupCat = (data) => {
  return http.post("/api/occupcategory", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const deleteOccupCat = (did) => {
  return http.delete("/api/deloccupationcat?ocatid=" + did, { headers: {'Authorization': 'Bearer ' + token}});
};

const getSubOccupCat = (catid) => {
  return http.get(`/api/getsuboccupationcat?catid=${catid}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createSubOccupCat = (usp, data) => {
  return http.post(`/api/storesuboccupationcat?usp=${usp}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
}
const deleteSubOccupCat = (subcatid) => {
  return http.delete("/api/delsuboccupationcat?subcatid=" + subcatid, { headers: {'Authorization': 'Bearer ' + token}});
};

const OccupcatService = {
    getOccupCat,
    createOccupCat,
    updateOccupCat,
    deleteOccupCat,
    getSubOccupCat,
    createSubOccupCat,
    deleteSubOccupCat
};

export default OccupcatService;