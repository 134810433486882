import React, { useEffect, useState } from "react";
import "./Settings.css";
import "./responsive.css";
import { Form, Alert } from "react-bootstrap";
import Select from "react-select";
import EditInput from "../../../assets/images/edit-input.png";
import TagsInput from "react-tagsinput";

import SettingsService from "../../../services/settings.service";
import AuthService from "../../../services/auth.service";

const ServerSettings = () => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [validated, setValidated] = useState(false);

  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [chnageData, setChangeData] = useState(false);

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [serverSettings, setServerSettings] = useState({
    domain_ip: "",
    whitelisted: [],
  });

  const [uid, setUid] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUid(user.user);

    SettingsService.getServerSettings(user.user)
      .then((response) => {
        let whilelistIp = response.data[0].whitelisted.split(",");

        setServerSettings({
          domain_ip: response.data[0].domain_ip,
          whitelisted: whilelistIp,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setServerSettings({ ...serverSettings, [name]: value });
    setChangeData(true);
  };

  const handleChange = (tags) => {
    setServerSettings({ ...serverSettings, whitelisted: tags });
    setChangeData(true);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = [
      {
        domain_ip: serverSettings.domain_ip,
        whitelisted: serverSettings.whitelisted,
      },
    ];

    if (chnageData) {
      SettingsService.createServerSettings(uid, data)
        .then((response) => {
          setMessage("Server Settings added/updated Successfully!");

          setDataUpload(true);

          setTimeout(() => {
            setDataUpload(false);
            setChangeData(false);
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer addUser server">
              <div className="box-inner">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="heading">
                    <h1>Server Settings</h1>
                  </div>
                  {dataUpload ? (
                    <>
                      <Alert className="m-auto mb-5" variant="success">
                        {message}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <>
                      <Alert className="m-auto mb-5" variant="danger">
                        {errMessage}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="add-user-form">
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Add Domain IP Address</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="text"
                          placeholder="Enter Add Domain"
                          name="domain_ip"
                          value={serverSettings.domain_ip}
                          onChange={handleInputChange}
                        />
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20.199 20.201"
                          >
                            <g
                              id="Group_11227"
                              data-name="Group 11227"
                              transform="translate(-2402.326 -6200.833)"
                            >
                              <path
                                id="Path_11890"
                                data-name="Path 11890"
                                d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                transform="translate(752.656 5510.435)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <path
                                id="Path_11892"
                                data-name="Path 11892"
                                d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                transform="translate(751.221 5511.583)"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="mb-3 flex-end" controlId="formBasicName">
                    <input type="button" className="btn" value="Verify" />
                  </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Whitelist IP Address</Form.Label>
                      <div className="input-box">
                        <TagsInput
                          value={serverSettings.whitelisted}
                          name="whiteListIp"
                          onChange={handleChange}
                          inputProps={{ placeholder: "Begin Typing...." }}
                        />
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 20.199 20.201"
                          >
                            <g
                              id="Group_11227"
                              data-name="Group 11227"
                              transform="translate(-2402.326 -6200.833)"
                            >
                              <path
                                id="Path_11890"
                                data-name="Path 11890"
                                d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                transform="translate(752.656 5510.435)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <path
                                id="Path_11892"
                                data-name="Path 11892"
                                d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                transform="translate(751.221 5511.583)"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="act-btn">
                    <button disabled={!chnageData}>Submit</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServerSettings;
