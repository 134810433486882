import React, { useState, useEffect } from "react";
import "./UserManagement.css";
import { Modal, Button, Alert } from "react-bootstrap";

import Filters from "./Filters";
import ActionFlex from "./ActionFlex";

import PermissionService from "../../../services/permission.service";

import ViewPermission from "./ViewPermission";
import EditPermission from "./EditPermission";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import AuthService from "../../../services/auth.service";

const PresetRoles = () => {
  // const [key, setKey] = useState('home');

  const [allRoles, setAllRoles] = useState([]);

  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [vid, setVid] = useState("");
  const [did, setDid] = useState("");
  const [eid, setEid] = useState("");

  const [userid, setUserid] = useState("");
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleViewClose = () => setView(false);
  const handleViewShow = (e) => {
    setView(true);
    setVid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleEditClose = () => setEdit(false);
  const handleEditShow = (e) => {
    setEdit(true);
    setEid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const getAllPerm = (uid) => {
    PermissionService.getAllRoles(uid)
      .then((response) => {
        setAllRoles(response.data);

        if (!$.fn.DataTable.isDataTable("#roles")) {
          function filterGlobal() {
            $("#roles")
              .DataTable()
              .search(
                $("#global_filter").val(),
                $("#global_regex").prop("checked"),
                $("#global_smart").prop("checked")
              )
              .draw();
          }
          function filterColumn(i) {
            $("#roles")
              .DataTable()
              .column(i)
              .search(
                $("#col" + i + "_filter").val(),
                $("#col" + i + "_regex").prop("checked"),
                $("#col" + i + "_smart").prop("checked")
              )
              .draw();
          }

          $(document).ready(function () {
            setTimeout(function () {
              $("#roles").DataTable({
                pagingType: "full_numbers",
                responsive: true,
                pageLength: 10,
                select: true,
                bDestroy: true,
                lengthMenu: [
                  [10, 20, 30, 50, -1],
                  [10, 20, 30, 50, "All"],
                ],
                columnDefs: [
                  {
                    targets: 0,
                    render: function (data, type, row, meta) {
                      return type === "export" ? meta.row + 1 : data;
                    },
                  },
                ],
              });

              $("input.global_filter").on("keyup click", function () {
                filterGlobal();
              });

              $("input.column_filter").on("keyup click", function () {
                filterColumn($(this).parents("tr").attr("data-column"));
              });
            }, 0);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setUserid(user.user);
      getAllPerm(user.user);
    }
  }, []);

  const deleteRoles = (e) => {
    e.preventDefault();

    PermissionService.deleteRole(userid, did)
      .then((response) => {
        toast.warn("Role deleted successfully.!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setShow(false);
        setAllRoles(allRoles.filter((allRole) => allRole.id !== did));
        setShow(false);

        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex />
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>

                <div className="heading">
                  <h1>All Roles</h1>
                </div>

                <ToastContainer />

                <div className="admin-users">
                  <div className="table-responsive">
                    <table className="table" id="roles">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Roles</th>
                          <th>Users Assigned</th>
                          <th>Permissions</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(allRoles) &&
                          allRoles.map((roles, index) => {
                            return (
                              <>
                                <tr className="">
                                  <td>{index + 1}</td>
                                  <td>
                                    <span>{roles.name}</span>
                                  </td>
                                  <td>
                                    <span>{roles.user_count}</span>
                                  </td>
                                  <td>
                                    <div className="table-act">
                                      <button
                                        onClick={handleViewShow}
                                        data-bs-target={roles.id}
                                        className="view"
                                      >
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            id="Isolation_Mode"
                                            data-name="Isolation Mode"
                                            viewBox="0 0 24 24"
                                            width="18"
                                            height="18"
                                          >
                                            <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                            <circle cx="12" cy="12" r="4" />
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    {roles.preset === "0" ? (
                                      <>
                                        <div className="table-act">
                                          <button
                                            onClick={handleEditShow}
                                            data-bs-target={roles.id}
                                            className="edit"
                                          >
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 22.58 22.583"
                                              >
                                                <g
                                                  id="Group_11449"
                                                  data-name="Group 11449"
                                                  transform="translate(-2402.326 -6200.833)"
                                                >
                                                  <path
                                                    id="Path_11890"
                                                    data-name="Path 11890"
                                                    d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                    transform="translate(752.656 5510.995)"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                  />
                                                  <path
                                                    id="Path_11892"
                                                    data-name="Path 11892"
                                                    d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                    transform="translate(751.921 5511.583)"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </button>
                                          <button
                                            onClick={handleShow}
                                            data-bs-target={roles.id}
                                            className="delete"
                                          >
                                            <span>
                                              <svg
                                                id="delete"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="15"
                                                viewBox="0 0 16.079 20.664"
                                              >
                                                <path
                                                  id="Path_13633"
                                                  data-name="Path 13633"
                                                  d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                                  transform="translate(-57 512)"
                                                />
                                                <path
                                                  id="Path_13634"
                                                  data-name="Path 13634"
                                                  d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                                  transform="translate(-83.869 402.606)"
                                                />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      <>Preset roles</>
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={view} onHide={handleViewClose} centered size="lg">
        <Modal.Header>
          <Modal.Title>View Assigned Permissions</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleViewClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <ViewPermission vid={vid} />
        </Modal.Body>
      </Modal>
      <Modal show={edit} onHide={handleEditClose} centered size="lg">
        <Modal.Header>
          <Modal.Title>Edit Permissions</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleEditClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <EditPermission eid={eid} />
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Role</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this cohort?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteRoles}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PresetRoles;
