import React from "react";
import "./learn.css";
import "./Cart.css";
import CourseImg from "../../assets/images/course-placeholder.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CartService from "../../services/cart.service";
import AuthService from "../../services/auth.service";

import Loading from "../../assets/images/loading.png";

const CourseCart = ({ cart, showCart, cartCourses, fetchCartCourseData }) => {
  const [paymentDetail, setPaymentDetail] = useState({
    prize: "",
    corsid: "",
    uid: "",
  });

  const [user, setUser] = useState("");

  const [paymentLink, setPaymentLink] = useState("");

  const [loading, setLoading] = useState(false);

  const [guestCartCourses, setGuestCartCourses] = useState([]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setUser(user.user);
    }
    const storedData = localStorage.getItem("myDataArray");

    if (storedData) {
      // Parse the JSON string from localStorage
      const parsedData = JSON.parse(storedData);

      // console.log("parsedData", parsedData);

      // Set the initial state with the parsed data
      setGuestCartCourses(parsedData);
    }
  }, []);

  // console.log("courseCart", guestCartCourses);

  const handleDeleteCartCourse = (cartid, cid) => {
    const user = AuthService.getCurrentUser();
    setGuestCartCourses([]);
    if (user) {
      const data = {
        usp: user.user,
        crteid: cartid,
        cid: cid,
      };

      CartService.deleteCartCourse(data)
        .then((resp) => {})
        .catch((e) => console.log(e));
    }
  };

  const buyCourse = async (cid, price) => {
    const user = AuthService.getCurrentUser();

    const numericPrice = parseFloat(price);

    if (isNaN(numericPrice)) {
      // console.error("Invalid price format");
      return;
    }

    const coursePrice = numericPrice.toFixed(2);

    const data = {
      amt: coursePrice,
      csid: cid,
      usid: user.user,
    };

    try {
      const [buyCourseResponse, paymentStatusResponse] = await Promise.all([
        CartService.buyCartCourse(data),
        CartService.getPaymentStatus(), // Adjust parameters if needed
      ]);

      const buyCourseResData = buyCourseResponse.data;
      const plink = buyCourseResData.split(" ");
      const uplink = plink[26].replace('}\n}\n{"plink":"', "");
      const flink = uplink.replace("}", "");
      const nflink = flink.replace('"', "");

      setPaymentLink(nflink);

      window.location.href = nflink;

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <div className={cart ? "cart-menu active" : "cart-menu"}>
      <div className="deleteIcon deleteIconCart" onClick={showCart}>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 16 16"
          >
            <g id="incorrect" transform="translate(-42.855 468.853)">
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
              />
            </g>
          </svg>
        </span>
      </div>
      <div className="cart-container">
        {user ? (
          <>
            {Array.isArray(cartCourses) ? (
              cartCourses.map((cartCourse, index) => (
                <div className="list-container-cart mb-2" key={index}>
                  <div className="list-item-cart">
                    {cartCourse.cimg ? (
                      <>
                        <img
                          className="cart-img"
                          src={
                            "https://aeonlms.aeonsoftware.net/cit/" +
                            cartCourse.cimg
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img className="cart-img" src={CourseImg} alt="" />
                      </>
                    )}
                    <div className="cart-course-details">
                      <div className="cart-courses">
                        <span className="cart-text">
                          {cartCourse.coursename}
                        </span>
                        <span className="cart-review">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11.555"
                            viewBox="0 0 12.129 11.555"
                          >
                            <g id="Object" transform="translate(0 0)">
                              <path
                                id="Path_78124"
                                data-name="Path 78124"
                                d="M237.254,246.768l1.723,3.492a.194.194,0,0,0,.147.106l3.853.56a.195.195,0,0,1,.108.332l-2.788,2.718a.194.194,0,0,0-.056.172l.658,3.839a.195.195,0,0,1-.282.205l-3.447-1.812a.2.2,0,0,0-.181,0l-3.447,1.812a.195.195,0,0,1-.282-.205l.659-3.839a.194.194,0,0,0-.056-.172l-2.789-2.718a.195.195,0,0,1,.108-.332l3.853-.56a.194.194,0,0,0,.147-.106l1.723-3.492a.195.195,0,0,1,.349,0Z"
                                transform="translate(-231.015 -246.66)"
                                fill="#f3cc30"
                              />
                              <path
                                id="Path_78125"
                                data-name="Path 78125"
                                d="M278.97,296.42l-1.536,2.975a.515.515,0,0,1-.41.276l-3.334.3,3.523.165a.515.515,0,0,0,.506-.335Z"
                                transform="translate(-272.906 -295.506)"
                                fill="#f7db5e"
                              />
                              <path
                                id="Path_78126"
                                data-name="Path 78126"
                                d="M353.087,475.4l-.076.441a.195.195,0,0,0,.282.205l3.447-1.812a.2.2,0,0,1,.181,0l3.447,1.812a.195.195,0,0,0,.282-.2L359.992,472a.194.194,0,0,1,.056-.172l2.788-2.718a.195.195,0,0,0-.108-.332l-1.291-.188A14.88,14.88,0,0,1,353.087,475.4Z"
                                transform="translate(-350.767 -464.512)"
                                fill="#f3cc31"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="cart-price-details">
                        <span className="price-text">
                          Price- €{cartCourse.price ? cartCourse.price : 0}
                        </span>
                        {/* <a
                        href="https://test.benefit-gateway.bh/payment/paymentpage.htm?PaymentID=302202333099497868"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="buy-btn"
                      >
                        <button className="cart-buy-now-btn">Buy now</button>
                      </a> */}
                        <button
                          className="cart-buy-now-btn"
                          onClick={() =>
                            buyCourse(cartCourse.csid, cartCourse.price)
                          }
                        >
                          Buy now
                        </button>
                      </div>
                    </div>
                    <span
                      className="delete-cart-icon"
                      onClick={() =>
                        handleDeleteCartCourse(
                          cartCourse.cartid,
                          cartCourse.csid
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        fill="white"
                      >
                        <path d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,1,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,0,0,18,15.878Z" />
                      </svg>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="loading">
                  <h1>No Courses has been added to cart yet</h1>
                  <img src={Loading} width="200px" alt="" />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {Array.isArray(guestCartCourses) ? (
              guestCartCourses.map((guestCartCourse, index) => (
                <div className="list-container-cart mb-2" key={index}>
                  <div className="list-item-cart">
                    {guestCartCourse.cimg ? (
                      <>
                        <img
                          className="cart-img"
                          src={
                            "https://aeonlms.aeonsoftware.net/cit/" +
                            guestCartCourse.cimg
                          }
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img className="cart-img" src={CourseImg} alt="" />
                      </>
                    )}
                    <div className="cart-course-details">
                      <div className="cart-courses">
                        <span className="cart-text">
                          {guestCartCourse.coursename}
                        </span>
                        <span className="cart-review">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11.555"
                            viewBox="0 0 12.129 11.555"
                          >
                            <g id="Object" transform="translate(0 0)">
                              <path
                                id="Path_78124"
                                data-name="Path 78124"
                                d="M237.254,246.768l1.723,3.492a.194.194,0,0,0,.147.106l3.853.56a.195.195,0,0,1,.108.332l-2.788,2.718a.194.194,0,0,0-.056.172l.658,3.839a.195.195,0,0,1-.282.205l-3.447-1.812a.2.2,0,0,0-.181,0l-3.447,1.812a.195.195,0,0,1-.282-.205l.659-3.839a.194.194,0,0,0-.056-.172l-2.789-2.718a.195.195,0,0,1,.108-.332l3.853-.56a.194.194,0,0,0,.147-.106l1.723-3.492a.195.195,0,0,1,.349,0Z"
                                transform="translate(-231.015 -246.66)"
                                fill="#f3cc30"
                              />
                              <path
                                id="Path_78125"
                                data-name="Path 78125"
                                d="M278.97,296.42l-1.536,2.975a.515.515,0,0,1-.41.276l-3.334.3,3.523.165a.515.515,0,0,0,.506-.335Z"
                                transform="translate(-272.906 -295.506)"
                                fill="#f7db5e"
                              />
                              <path
                                id="Path_78126"
                                data-name="Path 78126"
                                d="M353.087,475.4l-.076.441a.195.195,0,0,0,.282.205l3.447-1.812a.2.2,0,0,1,.181,0l3.447,1.812a.195.195,0,0,0,.282-.2L359.992,472a.194.194,0,0,1,.056-.172l2.788-2.718a.195.195,0,0,0-.108-.332l-1.291-.188A14.88,14.88,0,0,1,353.087,475.4Z"
                                transform="translate(-350.767 -464.512)"
                                fill="#f3cc31"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="cart-price-details">
                        <span className="price-text">
                          Price- €
                          {guestCartCourse.price ? guestCartCourse.price : 0}
                        </span>
                        {/* <a
                        href="https://test.benefit-gateway.bh/payment/paymentpage.htm?PaymentID=302202333099497868"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="buy-btn"
                      >
                        <button className="cart-buy-now-btn">Buy now</button>
                      </a> */}
                        <button
                          className="cart-buy-now-btn"
                          onClick={() =>
                            buyCourse(
                              guestCartCourse.csid,
                              guestCartCourse.price
                            )
                          }
                        >
                          Buy now
                        </button>
                      </div>
                    </div>
                    <span
                      className="delete-cart-icon"
                      onClick={() =>
                        handleDeleteCartCourse(
                          guestCartCourse.cartid,
                          guestCartCourse.csid
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        fill="white"
                      >
                        <path d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,1,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,0,0,18,15.878Z" />
                      </svg>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="loading">
                  <h1>No Courses has been added to cart yet</h1>
                  <img src={Loading} width="200px" alt="" />
                </div>
              </>
            )}
          </>
        )}
        {}
      </div>
    </div>
  );
};

export default CourseCart;
