// rootReducer.js
import { combineReducers } from "redux";
import themeReducer from "./themeReducer";
import userDataReducer from "./userDataReducer";
import courseReducer from "../redux/slice/course";
import cartReducer from "./cartReducer";

const rootReducer = combineReducers({
  themeReducer,
  userDataReducer,
  courseReducer,
  cartReducer
});

export default rootReducer;
