
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getAllPermission = (baps) => {
  return http.get(`/api/permissions?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getPermCata = (baps) => {
  return http.get(`/api/getpermcata?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};



const getAllRoles = (baps) => {
    return http.get(`/api/getrole?baps=${baps}`, { headers: {'Authorization': 'Bearer ' + token}});
};

const getRolePermission = (pid, baps) => {
  return http.get(`/api/rolepermissions?pid=${pid}&baps=${baps}`, { headers: {'Authorization': 'Bearer ' + token}});
}

const createRole = (data) => {
  return http.post("/api/addrelep", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
}

const updateRole = (data) => {
  return http.put("/api/uprpermissions", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
}

const deleteRole = (baps, rid) => {
  return http.delete(`/api/drole?baps=${baps}&rid=${rid}`, { headers: {'Authorization': 'Bearer ' + token}});
};


const PermissionService = {
  getAllPermission,
  getPermCata,
  getAllRoles,
  getRolePermission,
  createRole,
  updateRole,
  deleteRole
};

export default PermissionService;