import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import Select from "react-select";

const AddFilter = ({ prevStep, rpid }) => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [validated, setValidated] = useState(false);
  // const [options, setOptions] = useState({});
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [filter, setFilter] = useState({
    field: "",
    label: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const options = [
    {
      value: "1",
      label: "Date Enrolled",
    },
    {
      value: "2",
      label: "Fisrt Name",
    },
    {
      value: "3",
      label: "Last Name",
    },
    {
      value: "4",
      label: "Email",
    },
    {
      value: "5",
      label: "Course Name",
    },
  ];

  useEffect(() => {}, []);

  const handleSelectOptionChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
    const value = selectedOption ? selectedOption.value : "";
    const label = selectedOption ? selectedOption.label : "";
    setFilter({
      field: value,
      label: label,
    });
  };

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      field: filter.field,
      label: filter.label,
    };
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="add-user-form">
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Column to compare</Form.Label>
            <div className="input-box">
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--dark-bg)"
                      : "var(--dark-bg)",
                    height: "45px",
                  }),
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={handleSelectOptionChange}
                value={selectedOptions}
                options={options}
                issearchable="true"
                placeholder="Select Course Category"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Label</Form.Label>
            <div className="input-box">
              <Form.Control
                type="text"
                placeholder="Enter Report Name"
                name="label"
                value={filter.label}
                onChange={handleInputChange}
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20.199 20.201"
                >
                  <g
                    id="Group_11227"
                    data-name="Group 11227"
                    transform="translate(-2402.326 -6200.833)"
                  >
                    <path
                      id="Path_11890"
                      data-name="Path 11890"
                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                      transform="translate(752.656 5510.435)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Path_11892"
                      data-name="Path 11892"
                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                      transform="translate(751.221 5511.583)"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </Form.Group>
        </div>
        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            onClick={prevPage}
          />
          <input type="submit" className="onboard-btn-f" value="Add" />
        </div>
      </Form>
      <div className="admin-users addedd-filters">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>Date Enrolled</span>
                </td>
                <td>
                  <div className="table-act">
                    <button className="edit">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 22.58 22.583"
                        >
                          <g
                            id="Group_11449"
                            data-name="Group 11449"
                            transform="translate(-2402.326 -6200.833)"
                          >
                            <path
                              id="Path_11890"
                              data-name="Path 11890"
                              d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                              transform="translate(752.656 5510.995)"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_11892"
                              data-name="Path 11892"
                              d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                              transform="translate(751.921 5511.583)"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                    <button
                      // onClick={handleShow}
                      // data-bs-target={learner.user_id}
                      className="delete"
                    >
                      <span>
                        <svg
                          id="delete"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="15"
                          viewBox="0 0 16.079 20.664"
                        >
                          <path
                            id="Path_13633"
                            data-name="Path 13633"
                            d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                            transform="translate(-57 512)"
                          />
                          <path
                            id="Path_13634"
                            data-name="Path 13634"
                            d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                            transform="translate(-83.869 402.606)"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AddFilter;
