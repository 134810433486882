import React, { useEffect } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import "./Onboarding.css";
import { useState } from "react";
import { Link } from "react-router-dom";

import "react-tagsinput/react-tagsinput.css";

import PermissionService from "../../../services/permission.service";
import AuthService from "../../../services/auth.service";

const Permission = ({ prevStep, nextStep, skipBack }) => {
  const [showForm, setShowForm] = useState("0");
  const [validated, setValidated] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [show, setShow] = useState(false);
  const [did, setDid] = useState("");
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [chnageData, setChangeData] = useState(false);

  const [presetRole, setPresetRole] = useState(false);

  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [userid, setUserId] = useState("");

  const [occpCat, setOccpCat] = useState({
    sts: "0",
  });

  const [role, setRole] = useState({
    usp: "",
    roleName: "",
    permission: [],
  });

  const [roleUpdate, setRoleUpdate] = useState({
    usp: "",
    roleName: "",
    roleid: "",
    permission: [],
  });

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUserId(user.user);

    // setRole({ ...role, usp: user.user });

    // setRoleUpdate({ ...roleUpdate, usp: user.user });

    PermissionService.getAllPermission(user.user)
      .then((response) => {
        setPermissions(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    PermissionService.getAllRoles(user.user)
      .then((response) => {
        setAllRoles(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRole({ ...role, [name]: value });
  };

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();

    // if (occpCat.sts === "0") {
    //   skipBack();
    // } else {
    //   prevStep();
    // }
  };

  const [rolesPermission, setRolesPermission] = useState([]);

  function getRoleName(roleId, allRoles) {
    const roleName = allRoles.find((r) => r.id === roleId);
    return roleName ? roleName.name : "Unknown role";
  }

  const showPermission = (roleid) => {
    const user = AuthService.getCurrentUser();
    PermissionService.getRolePermission(roleid, user.user)
      .then((response) => {
        const rolePerm = response.data[0].permissions.split(",");
        setShowForm("1");

        if (response.data[0].prests === "1") {
          setPresetRole(true);
        } else {
          setPresetRole(false);
        }

        if (rolePerm !== undefined) {
          setRolesPermission(rolePerm);

          setRoleUpdate({
            roleName: getRoleName(response.data[0].role_id, allRoles),
            roleid: response.data[0].role_id,
            permission: rolePerm,
          });
        } else {
          setRolesPermission();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { permission } = role;

    const isPermissionSelected = rolesPermission.includes(value);

    if (isPermissionSelected) {
      // If the permission is already selected, remove it from the rolesPermission array
      setRolesPermission((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== value)
      );
    } else {
      // If the permission is not selected, add it to the rolesPermission array
      setRolesPermission((prevPermissions) => [...prevPermissions, value]);
    }

    if (checked) {
      setRole({ ...role, permission: [...permission, value] });
      setRoleUpdate({ ...roleUpdate, permission: [...permission, value] });
    } else {
      setRole({ ...role, permission: permission.filter((e) => e !== value) });
      setRoleUpdate({
        ...roleUpdate,
        permission: permission.filter((e) => e !== value),
      });
    }

    setChangeData(true);
  };

  const updateRole = (e) => {
    const { value, checked } = e.target;
    const { permission } = role;

    const isPermissionSelected = rolesPermission.includes(value);

    if (isPermissionSelected) {
      // If the permission is already selected, remove it from the rolesPermission array
      setRolesPermission((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== value)
      );
    } else {
      // If the permission is not selected, add it to the rolesPermission array
      setRolesPermission((prevPermissions) => [...prevPermissions, value]);
    }

    if (checked) {
      setRoleUpdate({ ...roleUpdate, permission: [...permission, value] });
    } else {
      setRoleUpdate({
        ...roleUpdate,
        permission: permission.filter((e) => e !== value),
      });
    }

    setChangeData(true);
  };

  const addRoles = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      usp: userid,
      roleName: role.roleName,
      permission: rolesPermission,
    };

    if (chnageData) {
      PermissionService.createRole(data)
        .then((response) => {
          setDataUpload(true);
          setMessage("Role Added Successfully!");

          PermissionService.getAllRoles(userid)
            .then((response) => {
              setAllRoles(response.data);
            })
            .catch((e) => {
              console.log(e);
            });

          setTimeout(() => {
            nextStep();
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setError(true);
      setErrMessage("*Please fill all required fields!");
      setTimeout(() => {
        setError(false);
      }, 3000);
    }

    // nextStep();
  };

  const updateRoles = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      usp: userid,
      roleid: roleUpdate.roleid,
      permission: rolesPermission,
    };

    if (chnageData) {
      PermissionService.updateRole(data)
        .then((response) => {
          setDataUpload(true);
          setMessage("Role Update Successfully!");

          PermissionService.getAllRoles(userid)
            .then((response) => {
              setAllRoles(response.data);
            })
            .catch((e) => {
              console.log(e);
            });

          setTimeout(() => {
            setDataUpload(false);
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setError(true);
      setErrMessage("There is no changes for updation!");
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleClose = () => setShow(false);

  const deleteRoles = (e) => {
    e.preventDefault();

    PermissionService.deleteRole(userid, did)
      .then((response) => {
        setAllRoles(allRoles.filter((allRole) => allRole.id !== did));
        setShow(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {dataUpload ? (
        <>
          <Alert variant="success">{message}</Alert>
        </>
      ) : (
        <></>
      )}
      {error ? (
        <>
          <Alert className="m-auto mb-5" variant="danger">
            {errMessage}
          </Alert>
        </>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col-xxl-9 col-xl-8 col-lg-8">
          <div
            className={showForm === "0" ? "createRoles active" : "createRoles"}
          >
            <Form noValidate validated={validated} onSubmit={addRoles}>
              <Form.Label>Add New Role</Form.Label>
              <Form.Group className="mb-3" controlId="formBasicOrgName">
                <Form.Label>Name of Role *</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="text"
                    placeholder="Begin Typing...."
                    name="roleName"
                    onChange={handleInputChange}
                    value={role.roleName}
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20.199 20.201"
                    >
                      <g
                        id="Group_11227"
                        data-name="Group 11227"
                        transform="translate(-2402.326 -6200.833)"
                      >
                        <path
                          id="Path_11890"
                          data-name="Path 11890"
                          d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                          transform="translate(752.656 5510.435)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_11892"
                          data-name="Path 11892"
                          d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                          transform="translate(751.221 5511.583)"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrgName">
                <Form.Label>Permissions</Form.Label>
                <div className="permission-flex flex-column active">
                  <div className="grid">
                    {Array.isArray(permissions) &&
                      permissions.map((permission, index) => {
                        return (
                          <>
                            <div className="check-box" key={index}>
                              <input
                                type="checkbox"
                                value={permission.permission_code}
                                onChange={handleChange}
                              />
                              <div className="round"></div>
                              <span>{permission.permission_name}</span>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </Form.Group>
              <div className="flex-end">
                <input type="submit" className="onboard-btn" value="Apply" />
              </div>
            </Form>
          </div>
          <div
            className={showForm === "1" ? "createRoles active" : "createRoles"}
          >
            <Form noValidate validated={validated} onSubmit={updateRoles}>
              <Form.Label>View/Edit Role Permissions</Form.Label>
              <Form.Group className="mb-3" controlId="formBasicOrgName">
                <Form.Label>Name of Role</Form.Label>
                <div className="input-box">
                  <Form.Control
                    type="text"
                    placeholder="Begin Typing...."
                    name="roleName"
                    onChange={handleInputChange}
                    value={roleUpdate.roleName}
                  />
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 20.199 20.201"
                    >
                      <g
                        id="Group_11227"
                        data-name="Group 11227"
                        transform="translate(-2402.326 -6200.833)"
                      >
                        <path
                          id="Path_11890"
                          data-name="Path 11890"
                          d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                          transform="translate(752.656 5510.435)"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          id="Path_11892"
                          data-name="Path 11892"
                          d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                          transform="translate(751.221 5511.583)"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicOrgName">
                <Form.Label>Permissions</Form.Label>
                <div className="permission-flex flex-column active">
                  <div className="grid">
                    {Array.isArray(permissions) &&
                      permissions.map((permission, index) => {
                        return (
                          <>
                            <div className="check-box" key={index}>
                              <input
                                type="checkbox"
                                value={permission.permission_code}
                                onChange={updateRole}
                                checked={rolesPermission.includes(
                                  permission.permission_code
                                )}
                              />
                              <div className="round"></div>
                              <span>{permission.permission_name}</span>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </Form.Group>
              {presetRole ? (
                <></>
              ) : (
                <>
                  <div className="flex-end">
                    <input
                      type="submit"
                      className="onboard-btn"
                      value="Apply"
                    />
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
        <div className="col-xxl-3 col-xl-4 col-lg-4">
          <Form.Label>Preset Roles</Form.Label>
          <div className="presetRoles">
            {Array.isArray(allRoles) &&
              allRoles.map((allRole, index) => {
                return (
                  <div className="roles active">
                    <div className="role-name">
                      <span>{allRole.name}</span>
                    </div>
                    <div className="preset-permission">
                      <Link onClick={() => showPermission(allRole.id)}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Isolation_Mode"
                            data-name="Isolation Mode"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                            <circle cx="12" cy="12" r="4" />
                          </svg>
                        </span>
                      </Link>
                    </div>
                    {allRole.preset !== "1" ? (
                      <>
                        <div className="deleteIcon">
                          <button
                            onClick={handleShow}
                            data-bs-target={allRole.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="incorrect"
                                transform="translate(-42.855 468.853)"
                              >
                                <path
                                  id="Path_11894"
                                  data-name="Path 11894"
                                  d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
                                />
                              </g>
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            {/* <Form.Label>Custom Roles</Form.Label> */}
          </div>
        </div>
      </div>
      <div className="flex-row">
        <input
          type="button"
          className="onboard-btn-o"
          value="Back"
          onClick={prevPage}
        />
        <input
          type="button"
          className="onboard-btn-f"
          value="Next"
          onClick={nextStep}
        />
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Roles</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this role?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteRoles}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Permission;
