import React, { useState, useEffect } from "react";
import Event from "./Event";

const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [month, setMonth] = useState("");
  const [monthDays, setMonthDays] = useState([]);

  const renderWeekdays = () => {
    const weekdays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

    return weekdays.map((day) => (
      <span key={day} className="weekday">
        {day}
      </span>
    ));
  };

  const renderCalendar = () => {
    const monthDays = [];

    const lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    const prevLastDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const firstDayIndex = date.getDay();

    const lastDayIndex = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDay();

    const nextDays = 7 - lastDayIndex - 1;

    const year = date.getFullYear();

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    setMonth(`${months[date.getMonth()]} ${year}`);

    for (let x = firstDayIndex; x > 0; x--) {
      monthDays.push(
        <span key={`prev-${prevLastDay - x + 1}`} className="prev-date">
          {prevLastDay - x + 1}
        </span>
      );
    }

    for (let i = 1; i <= lastDay; i++) {
      if (
        i === new Date().getDate() &&
        date.getMonth() === new Date().getMonth() &&
        date.getFullYear() === new Date().getFullYear()
      ) {
        monthDays.push(
          <span
            key={`today-${i}`}
            className="today"
            onClick={showEventBox}
            data-id={i}
            id={i}
          >
            <div>{i}</div>
          </span>
        );
      } else {
        monthDays.push(
          <span
            key={`day-${i}`}
            className="day"
            onClick={openLeaveAppModal}
            data-id={i}
            id={i}
          >
            {i}
          </span>
        );
      }
    }

    for (let j = 1; j <= nextDays; j++) {
      monthDays.push(
        <span key={`next-${j}`} className="next-date">
          {j}
        </span>
      );
    }

    // return monthDays;

    setMonthDays(monthDays);
  };

  useEffect(() => {
    renderCalendar();
  }, [date]);

  const showEventBox = () => {
    // Implement your showEventBox function
  };

  const openLeaveAppModal = () => {
    // Implement your openLeaveAppModal function
  };

  const handlePrevClick = () => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1);
    setDate(newDate);
  };

  const handleNextClick = () => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    setDate(newDate);
  };

  return (
    <div className="calendar">
      <div className="month">
        <i className="uil uil-angle-left prev" onClick={handlePrevClick}></i>
        <div className="date">
          <h2>{month}</h2>
        </div>
        <i className="uil uil-angle-right next" onClick={handleNextClick}></i>
      </div>
      <div className="weekdays">{renderWeekdays()}</div>
      <div className="days">{monthDays}</div>
    </div>
  );
};

export default Calendar;
