// themeReducer.js
import * as types from "../actions/themeActionTypes";

const initialState = {
  themeMode: "0", // Set an initial theme mode
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_THEME:
      return {
        ...state,
        themeMode: action.payload,
      };
    default:
      return state;
  }
};

export default themeReducer;
