import React, { useState, useEffect } from "react";

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const ModulesGradeEngagement = () => {
  
  const data = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    datasets: [
      {
        label: "course Grades",
        data: [50, 75, 60, 0, 50, 80, 25, 40, 70, 55],
        backgroundColor: "#18E22F",
        borderColor: "#18E22F",
        color: "#FFFFFF",
        fill: false,
        borderWidth: 1,
      }
    ],
  };


  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Modules",
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        }
      },
      y: {
        type: "linear",
        beginAtZero: true,
        title: {
          display: true,
          text: "Grades",
        },
        grid: {
          display: false,
          drawOnChartArea: true,
          color: "#FFFFFF",
        },
        ticks: {
          color: "#FFFFFF",
        }
      },
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  return (
    <>
      <Line data={data} options={options} />
    </>
  );
};

export default ModulesGradeEngagement;
