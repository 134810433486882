import React, { useState, useEffect, useRef } from "react";
import { Form, Alert, Accordion } from "react-bootstrap";
import ReportService from "../../services/report.service";

const AddColumns = ({ prevStep, nextStep, rpid }) => {
  const [validated, setValidated] = useState(false);
  const [checked, setChecked] = useState([]);
  const [unchecked, setUnchecked] = useState([]);

  const [selectedDataFields, setSelectedDataFields] = useState([]);

  const [selectedFields, setSelectedFields] = useState([]);

  const [allFields, setAllFields] = useState([]);

  const [userFields, setUserFields] = useState([]);
  const [courseFields, setCourseFields] = useState([]);
  const [activityFields, setActivityFields] = useState([]);
  const [pathwayFields, setPathwayFields] = useState([]);

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    ReportService.getReportsFields().then((resp) => {
      setAllFields(resp.data);
      setUserFields(resp.data.filter((field) => field.fieldtype === "1"));
      setCourseFields(resp.data.filter((field) => field.fieldtype === "2"));
      setActivityFields(resp.data.filter((field) => field.fieldtype === "3"));
      setPathwayFields(resp.data.filter((field) => field.fieldtype === "4"));
    });
  }, []);

  const handleCheckbox = (e, l) => {
    if (e.target.checked) {
      setChecked([...checked, l]);
    } else {
      setChecked(checked.filter((item) => item !== l));
    }
  };

  const handleRemoveCheckbox = (e, l) => {
    if (e.target.checked) {
      setUnchecked([...unchecked, l]);
      setChecked(checked.filter((item) => item !== l));
    } else {
      setUnchecked(unchecked.filter((item) => item !== l));
      setChecked([...checked, l]);
    }
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...selectedFields];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSelectedFields(copyListItems);
  };

  const addSelectedField = (e) => {
    e.preventDefault();

    const selectFields = allFields.filter((allField) =>
      checked.includes(allField.id)
    );
    const selectedDatafields = selectFields.map((item) => item.datafields);

    setSelectedFields(selectFields);
    setSelectedDataFields(selectedDatafields);
    setUnchecked([]);
  };

  const removeSelectedField = (e) => {
    e.preventDefault();

    const selectFields = allFields.filter((allField) =>
      checked.includes(allField.id)
    );
    const selectedDatafields = selectFields.map((item) => item.datafields);

    setSelectedFields(selectFields);
    setSelectedDataFields(selectedDatafields);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      datafields: selectedDataFields,
    };

    nextStep();
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="add-columns">
          <label htmlFor="">
            Drag columns to arrange them, as preferred in the report
          </label>
          <div className="row">
            <div className="col-xl-5 col-lg-5">
              <div className="draggable-items">
                <div className="info">
                  <span>Chose any of the columns to add to your report</span>
                </div>
                <div className="search-bar"></div>
                <div className="items-list">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>User</Accordion.Header>
                      <Accordion.Body>
                        <div className="list">
                          {Array.isArray(userFields)
                            ? userFields.map((field) => (
                                <div
                                  key={field.id}
                                  className={
                                    checked.includes(field.id)
                                      ? "list-item checked"
                                      : "list-item"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={checked.includes(field.id)}
                                    onChange={(e) =>
                                      handleCheckbox(e, field.id)
                                    }
                                  />
                                  <span>{field.fields}</span>
                                </div>
                              ))
                            : null}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Courses</Accordion.Header>
                      <Accordion.Body>
                        <div className="list">
                          {Array.isArray(courseFields)
                            ? courseFields.map((field) => (
                                <div
                                  key={field.id}
                                  className={
                                    checked.includes(field.id)
                                      ? "list-item checked"
                                      : "list-item"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={checked.includes(field.id)}
                                    onChange={(e) =>
                                      handleCheckbox(e, field.id)
                                    }
                                  />
                                  <span>{field.fields}</span>
                                </div>
                              ))
                            : null}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Activity</Accordion.Header>
                      <Accordion.Body>
                        <div className="list">
                          {Array.isArray(activityFields)
                            ? activityFields.map((field) => (
                                <div
                                  key={field.id}
                                  className={
                                    checked.includes(field.id)
                                      ? "list-item checked"
                                      : "list-item"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={checked.includes(field.id)}
                                    onChange={(e) =>
                                      handleCheckbox(e, field.id)
                                    }
                                  />
                                  <span>{field.fields}</span>
                                </div>
                              ))
                            : null}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Pathway</Accordion.Header>
                      <Accordion.Body>
                        <div className="list">
                          {Array.isArray(pathwayFields)
                            ? pathwayFields.map((field) => (
                                <div
                                  key={field.id}
                                  className={
                                    checked.includes(field.id)
                                      ? "list-item checked"
                                      : "list-item"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={checked.includes(field.id)}
                                    onChange={(e) =>
                                      handleCheckbox(e, field.id)
                                    }
                                  />
                                  <span>{field.fields}</span>
                                </div>
                              ))
                            : null}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-xl-2">
              <div className="action">
                <button onClick={addSelectedField}>
                  <span>Add</span>
                  <span className="icon right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="14"
                      viewBox="0 0 11.556 19.715"
                    >
                      <path
                        id="Path_13256"
                        data-name="Path 13256"
                        d="M96.75-208a1.09,1.09,0,0,0-.555,1.2c.037.223.567.806,4.706,5.211,5.07,5.388,4.78,5.113,5.366,5.008.2-.039.752-.6,4.9-5.008s4.669-4.988,4.706-5.211a1.073,1.073,0,0,0-.567-1.2C115.006-208.133,97.027-208.133,96.75-208Z"
                        transform="translate(208.1 115.889) rotate(-90)"
                      />
                    </svg>
                  </span>
                </button>
                <button onClick={removeSelectedField}>
                  <span className="icon left">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="14"
                      viewBox="0 0 11.557 19.715"
                    >
                      <path
                        id="Path_13257"
                        data-name="Path 13257"
                        d="M.575,11.458a1.09,1.09,0,0,1-.555-1.2c.037-.223.567-.806,4.706-5.211C9.8-.341,9.506-.065,10.092.04c.2.039.752.6,4.9,5.008s4.669,4.988,4.706,5.211a1.073,1.073,0,0,1-.567,1.2C18.831,11.589.853,11.589.575,11.458Z"
                        transform="translate(0 19.715) rotate(-90)"
                      />
                    </svg>
                  </span>
                  <span>Remove</span>
                </button>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5">
              <div className="dropable-items">
                <div className="info">
                  <span>Columns currently in the report</span>
                </div>
                <div className="items-list">
                  <div className="accordion-body">
                    <div className="list">
                      {Array.isArray(selectedFields)
                        ? selectedFields.map((field, index) => {
                            return (
                              <div
                                // className="list-item"
                                className={
                                  unchecked.includes(field.id)
                                    ? "list-item checked"
                                    : "list-item"
                                }
                                key={index}
                                draggable
                                onDragStart={(e) => dragStart(e, index)}
                                onDragEnter={(e) => dragEnter(e, index)}
                                onDragEnd={drop}
                              >
                                <input
                                  type="checkbox"
                                  checked={unchecked.includes(field.id)}
                                  onChange={(e) =>
                                    handleRemoveCheckbox(e, field.id)
                                  }
                                />
                                <span>
                                  <i className="uil uil-draggabledots"></i>
                                </span>
                                <span>{field.fields}</span>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            onClick={prevPage}
          />
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
    </>
  );
};

export default AddColumns;
