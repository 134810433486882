import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportService from "../../services/report.service";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

const ReportOverview = ({ nextStep, rpid }) => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [validated, setValidated] = useState(false);
  const [options, setOptions] = useState({});
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [changeData, setChangeData] = useState(false);

  let navigate = useNavigate();

  const [report, setReport] = useState({
    reportname: "",
    reportcat: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReport({ ...report, [name]: value });
    setChangeData(true);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (rpid) {
      ReportService.getReportById(user.user, rpid).then((resp) => {
        setReport({
          reportname: resp.data[0].reportname,
          reportcat: resp.data[0].reportcat,
          description: resp.data[0].description,
        });
      });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast.warn("*Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const user = AuthService.getCurrentUser();

    const data = {
      usp: user.user,
      reportname: report.name,
      reportcat: report.reportcat,
      description: report.description,
    };

    if (changeData) {
      ReportService.addReportOverview(data)
        .then((resp) => {
          const rpid = resp.data;

          toast.success("Report Created Successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate("/EditReport/", { state: { rpid } });
            nextStep();
          }, 2000);
        })
        .catch((e) => console.log(e));
    } else {
      nextStep();
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="add-user-form">
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Report Name *</Form.Label>
            <div className="input-box">
              <Form.Control
                type="text"
                placeholder="Enter Report Name"
                name="reportname"
                value={report.reportname}
                onChange={handleInputChange}
                required
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20.199 20.201"
                >
                  <g
                    id="Group_11227"
                    data-name="Group 11227"
                    transform="translate(-2402.326 -6200.833)"
                  >
                    <path
                      id="Path_11890"
                      data-name="Path 11890"
                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                      transform="translate(752.656 5510.435)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_11892"
                      data-name="Path 11892"
                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                      transform="translate(751.221 5511.583)"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Category *</Form.Label>
            <div className="input-box">
              <select
                name="reportcat"
                id=""
                className="form-select"
                required
                onChange={handleInputChange}
                value={report.reportcat}
              >
                <option value="0">Select Category</option>
                <option value="1">Users</option>
                <option value="2">Courses</option>
                <option value="3">Activity</option>
                <option value="4">Pathway</option>
              </select>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicDesignation">
            <Form.Label>Description</Form.Label>
            <div className="input-box">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                name="description"
                value={report.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </Form.Group>
        </div>
        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            // onClick={prevPage}
            style={{ visibility: "hidden" }}
          />
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
    </>
  );
};

export default ReportOverview;
