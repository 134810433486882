import React from "react";

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
import { useEffect } from "react";
import { useState } from "react";
Chart.register(...registerables);

const StudentEngagement = ({
  studentEngagementData,
  activeLearnerCount,
  themeMode,
}) => {
  var style = getComputedStyle(document.body);

  if (themeMode === "0") {
    var whiteColor = "#f5f5f5";
  } else if (themeMode === "1") {
    var whiteColor = "#010001";
  } else {
    var whiteColor = style.getPropertyValue("--whiteColor");
  }

  const data = {
    labels: studentEngagementData,
    datasets: [
      {
        label: "Active",
        data: activeLearnerCount,
        backgroundColor: "#08C341",
        borderColor: "#08C341",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
      // {
      //   label: "Inactive",
      //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 55, 45, 60, 35],
      //   backgroundColor: "#FF3030F2",
      //   borderColor: "#FF3030F2",
      //   fill: false,
      //   borderWidth: 2,
      //   tension: 0.5,
      // },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: (ctx) => "Student Engagement",
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  return (
    <>
      <div className="line-area">
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default StudentEngagement;
