import React, { useState } from 'react';
import { Page, Text, View, Image, Document, StyleSheet, PDFViewer  } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";

const CertificatePreview = () => {

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    section: {
      width: "100%",
      display: "block",
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "AntonFamily",
    },
    text: {
      display: "block",
      margin: 12,
      fontSize: 14,
      textAlign: "center",
      fontFamily: "AntonFamily",
      color: "#ffffff",
    },
    image: {
      display: "block",
      width: 75, 
      margin: 10,
    },

  });

  return (
    <div>
      <Document>
        <Page style={styles.bgimg}>
          <img src={logo} style={styles.image} />
          <View style={styles.section}>
            <Text style={styles.text}>Certificate of Completion</Text>
            <Text style={styles.text}>This certifies that</Text>
            <Text style={styles.text}>Learner Name</Text>
            <Text style={styles.text}>has successfully completed the course</Text>
            <Text style={styles.text}>Course Name</Text>
            <Text style={styles.text}>Instruture Signature</Text>
          </View>
          
        </Page>
      </Document>
    </div>
  );
}

export default CertificatePreview