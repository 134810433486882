import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

function CourseOverviewChart({
  courseEnrolmentData,
  learneringOverviewValue,
  mode,
  chnageMode,
}) {
  var style = getComputedStyle(document.body);
  var whiteColor = style.getPropertyValue("--whiteColor");

  //gradient code
  const gradient = (context) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      // This can happen when the chart collapses to 0 height
      return null;
    }

    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );

    if (chnageMode === "light") {
      // gradient.addColorStop(0, "#FFFFFF38"); // Start color
      gradient.addColorStop(0, "rgba(55, 104, 243, 0.3)"); // Start color
      gradient.addColorStop(1, "rgba(55, 104, 243, 1)"); // End color (transparent)
    } else {
      gradient.addColorStop(0, "rgb(46, 46, 87)"); // Start color
      gradient.addColorStop(1, "blue"); // End color (transparent)
    }

    return gradient;
  };

  const data = {
    labels: courseEnrolmentData, //x-axis entry
    datasets: [
      {
        label: "Learning Overview",
        data: learneringOverviewValue.map((time) => {
          const timeParts = time.split(":");
          return +timeParts[0] * 3600 + +timeParts[1] * 60 + +timeParts[2];
        }),
        backgroundColor: gradient,
        borderColor: "white",
        color: "#FFFFFF",
        fill: true,
        borderWidth: 1,
      },
    ],
  };

  const secondsToHMS = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const options = {
    responsive: true,
    tension: 0.4,
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        mode: "index",
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            return `Time: ${secondsToHMS(value)}`;
          },
        },
      },
      legend: {
        display: true,
        labels: {
          color: `${chnageMode === "light" ? "#010001" : "#f5f5f5"}`,
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            return secondsToHMS(value);
          },
        },
        title: {
          display: true,
          text: "Time",
        },
        grid: {
          drawOnChartArea: false,
        },
        stacked: true,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `Time: ${secondsToHMS(value)}`;
        },
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
      style={{ height: "235px", width: "500px" }}
    />
  );
}

export default CourseOverviewChart;
