import React, { useState, useEffect } from "react";

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CoursesStatistic = ({
  yAxisData,
  corsid,
  courseEnrolmentValue,
  courseComplitionValue,
  themeMode,
}) => {
  const [completion, setCompletion] = useState();
  const [enrollment, setEnrollment] = useState();

  var style = getComputedStyle(document.body);

  if (themeMode === "0") {
    var whiteColor = "#f5f5f5";
  } else if (themeMode === "1") {
    var whiteColor = "#010001";
  } else {
    var whiteColor = style.getPropertyValue("--whiteColor");
  }

  useEffect(() => {
    // course filter
    if (corsid == 1) {
      setCompletion([
        10, 12, 20, 20, 12, 15, 15, 20, 15, 20, 15, 20, 20, 15, 20,
      ]);
      // setEnrollment([
      //   20, 25, 26, 24, 20, 25, 20, 24, 30, 24, 24, 30, 15, 28, 24,
      // ]);
    } else if (corsid == 2) {
      setCompletion([
        10, 12, 20, 20, 12, 15, 15, 20, 15, 20, 15, 20, 20, 15, 20,
      ]);
      // setEnrollment([
      //   20, 25, 26, 24, 20, 25, 20, 24, 30, 24, 24, 30, 15, 28, 24,
      // ]);
    } else if (corsid == 3) {
      setCompletion([
        10, 12, 20, 20, 12, 15, 15, 20, 15, 20, 15, 20, 20, 15, 20,
      ]);
      // setEnrollment([
      //   20, 25, 26, 24, 20, 25, 20, 24, 30, 24, 24, 30, 15, 28, 24,
      // ]);
    }
  }, []);

  const data = {
    labels: yAxisData,
    datasets: [
      {
        label: "Completion",
        data: courseComplitionValue,
        backgroundColor: "#F98436",
        borderColor: "#F98436",
        fill: false,
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Enrollment",
        data: courseEnrolmentValue,
        backgroundColor: "#63EC88",
        borderColor: "#63EC88 ",
        fill: false,
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        // text: (ctx) => 'Student Course Enrollment'
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <>
      <Line data={data} options={options} />
    </>
  );
};

export default CoursesStatistic;
