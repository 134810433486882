import React, { useState } from "react";

import { PDFViewer } from "@react-pdf/renderer";

import certificate from "../assets/images/certificate-bg.jpg";
import { renderToStaticMarkup } from "react-dom/server";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CertService from "../services/cert.service";
// import "./certificate.css";

import CertificatePreview from "./CertificatePreview";

import { ColorRing } from "react-loader-spinner";

const Preview = ({
  cerImgSrc,
  stageWidth,
  stageHeight,
  addedElements,
  currentUser,
  courseName,
  allCerts,
  cid,
  userId,
  certLoading,
  catid,
}) => {
  let courseShortName = "";

  if (allCerts && allCerts.course_name) {
    const words = allCerts.course_name.split(" ");
    const initials = words.map((word) => word[0]).join("");
    courseShortName = initials.toUpperCase();
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = allCerts ? new Date(allCerts.awarded_on) : new Date();
  const monthName = months[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const formattedDate = `${monthName} ${day}, ${year}`;

  const shareFile = (e, file, url) => {
    const formdata = new FormData();

    formdata.append("corsid", cid);
    formdata.append("uid", userId);
    formdata.append("cert", file, `${currentUser}-${courseName}.pdf`);
    formdata.append("certname", `${currentUser}-${courseName}.pdf`);

    CertService.uploadCert(formdata)
      .then((resp) => {})
      .catch((e) => console.log(e));
  };

  return (
    <div className="cert-flex">
      {certLoading ? (
        <>
          <div className="loading load-anim">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </>
      ) : (
        <>
          <PDFViewer width="100%" height="410px">
            <CertificatePreview
              cerImgSrc={cerImgSrc}
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              addedElements={addedElements}
              currentUser={currentUser}
              courseName={courseName}
              formattedDate={formattedDate}
              allCerts={allCerts}
              courseShortName={courseShortName}
              catid={catid}
            />
          </PDFViewer>
          <PDFDownloadLink
            document={
              <CertificatePreview
                cerImgSrc={cerImgSrc}
                stageWidth={stageWidth}
                stageHeight={stageHeight}
                addedElements={addedElements}
                currentUser={currentUser}
                courseName={courseName}
                formattedDate={formattedDate}
                allCerts={allCerts}
                courseShortName={courseShortName}
                catid={catid}
              />
            }
            fileName={`${currentUser}-${courseName}.pdf`}
            className="cert-down"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading..."
              ) : (
                <button onClick={(e) => shareFile(e, blob, url)}>
                  Download Certificate
                </button>
              )
            }
          </PDFDownloadLink>
        </>
      )}
    </div>
  );
};

export default Preview;
