import React, { useState, useEffect } from "react";
import SettingsService from "../../services/settings.service";
import AuthService from "../../services/auth.service";
import InfiniteScroll from "react-infinite-scroll-component";

const ActivityLogNew = () => {
  const [activityLogs, setActivityLogs] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const user = AuthService.getCurrentUser();
      const response = await SettingsService.getActivityLog(user.user);

      // Append the new data to the existing activityLogs
      setActivityLogs((prevActivityLogs) => [
        ...prevActivityLogs,
        ...response.data,
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={activityLogs.length}
        next={fetchData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        // below props only if you need pull down functionality
        refreshFunction={fetchData} // Use the fetchData function directly
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      >
        {activityLogs.event}
      </InfiniteScroll>
    </>
  );
};

export default ActivityLogNew;
