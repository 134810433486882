import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./login.css";

import AuthService from "../../services/auth.service";
import VerigyOTPService from "../../services/verifyotp.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ChangePassword = () => {
  // const { vid } = useParams();

  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  const [vid, setVid] = useState("");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [showOTP, setShowOTP] = useState(false);

  const [verifyOtp, setVerifyOtp] = useState(false);
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const onChangeOTP = (e) => {
    const otp = e.target.value;
    setOtp(otp);
  };

  const showOTPBox = () => {
    setShowOTP(true);
  };

  const onChangeNewPassword = (e) => {
    const password = e.target.value;
    setNewPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
  };

  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const verifyOTP = () => {
    const email = localStorage.getItem("email");
    const data = {
      emla: email,
      vaota: otp,
    };

    if (data.vaota !== "") {
      VerigyOTPService.verifyOTP(data)
        .then((res) => {
          if (res.data.vid) {
            setVid(res.data.vid);
            setDataUpload(true);
            setMessage(
              "OTP Verified succesfully you can now change your password."
            );
            setVerifyOtp(true);

            setTimeout(() => {
              setDataUpload(false);
              localStorage.removeItem("email");
            }, 3000);
          } else {
            setError(true);
            setErrMessage("*Please enter a valid otp!");

            setTimeout(() => {
              setError(false);
            }, 3000);
          }
        })
        .catch((e) => console.log(e));
    } else {
      setError(true);
      setErrMessage("*Please enter otp!");

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const changePassword = () => {
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword == confirmPassword) {
        const data = {
          evid: vid,
          pasres: confirmPassword,
        };
        VerigyOTPService.changePassword(data)
          .then((res) => {
            setDataUpload(true);
            setMessage("Password changed Successfully.");

            setTimeout(() => {
              setDataUpload(false);
              navigate("/");
            }, 3000);
          })
          .catch((e) => console.log(e));
      } else {
        setError(true);
        setErrMessage("Password not matched!");

        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } else {
      setError(true);
      setErrMessage("*Please fill all required fields!");

      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <Form>
                <div className="login active">
                  <div className="heading">
                    {verifyOtp ? (
                      <h1>Create New Password</h1>
                    ) : (
                      <h1>Verify OTP</h1>
                    )}
                  </div>
                  {dataUpload ? (
                    <>
                      <Alert className="m-auto mb-5" variant="success">
                        {message}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <>
                      <Alert className="m-auto mb-5" variant="danger">
                        {errMessage}
                      </Alert>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="form">
                    {verifyOtp ? (
                      <>
                        <div className="form-group togglePassword">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="input password"
                            required
                            value={newPassword}
                            onChange={onChangeNewPassword}
                            validations={[required]}
                          />
                          <label htmlFor="">New Password *</label>
                          <i
                            className={
                              showNewPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleNewPassword}
                          ></i>
                        </div>
                        <div className="form-group togglePassword">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="input password"
                            required
                            value={confirmPassword}
                            onChange={onChangeConfirmPassword}
                            validations={[required]}
                          />
                          <label htmlFor="">Confirm Password *</label>
                          <i
                            className={
                              showConfirmPassword
                                ? "uil uil-eye"
                                : "uil uil-eye-slash"
                            }
                            id="eye-icon"
                            onClick={toggleConfirmPassword}
                          ></i>
                        </div>
                        <div className="form-group action">
                          <div className="flex_form-group">
                            <button
                              className="fill_btn"
                              type="button"
                              name="btn_login"
                              onClick={changePassword}
                            >
                              <span>Submit</span>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group togglePassword otp active">
                          <input
                            type="text"
                            id="otp"
                            name="otp"
                            className="input password"
                            required
                            value={otp}
                            onChange={onChangeOTP}
                            validations={[required]}
                          />
                          <label htmlFor="">OTP</label>
                        </div>
                        <div className="form-group action">
                          <div className="flex_form-group">
                            <button
                              className="fill_btn"
                              type="button"
                              name="btn_login"
                              onClick={verifyOTP}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>
            <div className="col-xl-6 col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
