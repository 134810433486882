import React from "react";
import AdminUser from "../assets/images/admin-user.png";

const ChatBot = ({chat, hideChat}) => {

    return (
        <>
            <div className={chat ? 'inner-box right active' : 'inner-box right'}>
                <div className="top">
                    <div className="user-flex">
                        <div className="user-pic">
                            <img src={AdminUser} width="30px" alt="" />
                        </div>
                        <div className="user-info">
                            <span className="name">
                                Name
                            </span>
                        </div>
                    </div>
                    <div className="deleteIcon" onClick={hideChat}>
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 16 16"
                            >
                                <g
                                    id="incorrect"
                                    transform="translate(-42.855 468.853)"
                                >
                                    <path
                                        id="Path_11894"
                                        data-name="Path 11894"
                                        d="M49.823-468.8a8.028,8.028,0,0,0-6.569,5.449,8.179,8.179,0,0,0-.353,3.393,8.029,8.029,0,0,0,6.978,7.057,10.241,10.241,0,0,0,1.858.015,8.016,8.016,0,0,0,6.306-4.453,8.037,8.037,0,0,0,.394-6.076,8.068,8.068,0,0,0-4.2-4.7,8.362,8.362,0,0,0-2.5-.707A11.241,11.241,0,0,0,49.823-468.8Zm-1.438,4.4c.068.034.653.586,1.3,1.229L50.855-462l1.175-1.172c.642-.643,1.224-1.195,1.287-1.229a.752.752,0,0,1,.92.162.754.754,0,0,1,.161.921c-.034.064-.586.646-1.227,1.289L52-460.855l1.171,1.172c.642.646,1.2,1.236,1.235,1.308a.986.986,0,0,1,.008.7,1.245,1.245,0,0,1-.405.4,1.019,1.019,0,0,1-.676-.019c-.071-.038-.661-.594-1.3-1.236l-1.175-1.172-1.171,1.172c-.646.643-1.227,1.2-1.291,1.229a.752.752,0,0,1-.92-.162.754.754,0,0,1-.161-.921c.034-.064.586-.646,1.227-1.293l1.171-1.172-1.171-1.176c-.642-.643-1.194-1.225-1.227-1.289a.821.821,0,0,1,.488-1.142A.935.935,0,0,1,48.385-464.4Z"
                                    />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="body">
                    <div className="left-m">
                        <div className="user-pic">
                            <img src={AdminUser} width="20px" alt="" />
                        </div>
                        <div className="message box3 sb14">
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                        </div>
                    </div>
                    <div className="right-m">
                        <div className="message box3 sb13">
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                        </div>
                        <div className="user-pic">
                            <img src={AdminUser} width="20px" alt="" />
                        </div>
                    </div>
                    <div className="footer">
                        <div className="input-area">
                            <input type="text" placeholder="Type..." />
                            <div className="icon">
                                <i className="uil uil-paperclip"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default ChatBot;