export const setUserData = (userdata) => {
    return {
      type: "USERDATA",
      payload: userdata,
    };
};


export const getCartCourses = (coursedata) => {
  return {
    type: "COURSEDATA",
    payload: coursedata,
  };
};