import React from "react";
import { Link, useLocation } from "react-router-dom";

const ReportFlex = ({ step }) => {
  const location = useLocation();

  return (
    <>
      <div className="action-flex report">
        <div
          className={step === 1 ? "action-item one active" : "action-item one"}
        >
          <div className="reportflex">
            <span className="title">1. Report Overview</span>
            <span className="desc">(Add general information)</span>
          </div>
        </div>
        <div className="circles">
          <div className="circle"></div>
          <div className="circle active"></div>
          <div className="circle"></div>
        </div>
        <div
          className={step === 2 ? "action-item two active" : "action-item two"}
        >
          <div className="reportflex">
            <span className="title">2.Add Columns</span>
            <span className="desc">
              (Choose the columns that you want to use in report)
            </span>
          </div>
        </div>
        <div className="circles">
          <div className="circle"></div>
          <div className="circle active"></div>
          <div className="circle"></div>
        </div>
        <div
          className={
            step === 3 ? "action-item three active" : "action-item three"
          }
        >
          <div className="reportflex">
            <span className="title">3.Add Filters</span>
            <span className="desc">(Create filters)</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportFlex;
