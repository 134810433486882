import React, { useState } from "react";
import "./certificate.css";
import { Form } from "react-bootstrap";
import EditInput from "../../../assets/images/edit-input.png";
import DeleteIcon from "../../../assets/images/close.png";
import ChangeColor from "../../../assets/images/color-picker.png";
import CertificateOne from "../../../assets/images/certificate-one.jpg";
import CertificateTwo from "../../../assets/images/certificate-two.jpg";
import CertificateThree from "../../../assets/images/certificate-three.jpg";
import CertificateFour from "../../../assets/images/certificate-four.jpg";

import Preview from "./Preview";

const SetCertificateTemplate = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();
  };

  return (
    <>
      <div className="container-fluid certificate box-outer">
        <div className="row">
          <div className="col-xl-5 col-lg-5">
            <Form>
              <Form.Group className="mb-3 uploadimg">
                <Form.Label>Upload Image as Background</Form.Label>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="57.914"
                    height="54.915"
                    viewBox="0 0 57.914 54.915"
                  >
                    <g id="image" transform="translate(-47.996 463.875)">
                      <path
                        id="Path_12955"
                        data-name="Path 12955"
                        d="M374.1-463.7a39.81,39.81,0,0,0-5.57,5.723c-.056.223-.111.487-.139.585a2.494,2.494,0,0,0,.641,1.629,2.222,2.222,0,0,0,3.328-.125l.348-.376.07,3.62c.07,3.4.084,3.648.362,4.024a2.213,2.213,0,0,0,3.732,0c.278-.376.292-.627.362-4.024l.07-3.62.348.376a2.207,2.207,0,0,0,3.286.153,2.373,2.373,0,0,0,.682-1.657c-.028-.1-.084-.362-.139-.585a38.657,38.657,0,0,0-5.6-5.723A2.326,2.326,0,0,0,374.1-463.7Z"
                        transform="translate(-275.77 0)"
                        fill="#fff"
                      />
                      <path
                        id="Path_12956"
                        data-name="Path 12956"
                        d="M55.864-442.8a9.993,9.993,0,0,0-6.962,5.57c-.961,2.061-.905.947-.905,20.26s-.056,18.2.905,20.26a10.268,10.268,0,0,0,6.405,5.458c.794.237,1.81.251,21.583.251,19.341,0,20.8-.014,21.625-.251a10.136,10.136,0,0,0,6.433-5.319c1.016-2.117.989-1.8.947-13.6-.042-10.443-.042-10.541-.334-10.931a2.213,2.213,0,0,0-3.732,0c-.278.376-.292.571-.334,5.57l-.042,5.166L94.6-417.221c-7.269-7.255-7.45-7.408-8.731-7.408s-1.406.1-9.566,8.243c-7.645,7.631-7.686,7.672-8.243,7.672s-.668-.1-2.367-1.754a21.917,21.917,0,0,0-2.256-1.991,3.58,3.58,0,0,0-2.632,0c-.265.139-2.256,2.019-4.414,4.163l-3.941,3.927v-14.2c0-8.981.056-14.523.139-15.08a5.072,5.072,0,0,1,3.829-4.567c.766-.223,1.949-.251,14.092-.32,13.075-.084,13.256-.084,13.646-.376a2.567,2.567,0,0,0,.947-2.158,2.592,2.592,0,0,0-1.573-1.8C82.7-443.092,56.922-443.037,55.864-442.8Z"
                        transform="translate(0 -17.959)"
                        fill="#fff"
                      />
                      <path
                        id="Path_12957"
                        data-name="Path 12957"
                        d="M146.826-346.687a3.753,3.753,0,0,0-2.729,3.537,3.645,3.645,0,0,0,1.949,3.3,3.78,3.78,0,0,0,4.512-.794,3.388,3.388,0,0,0,.877-2.7,3.507,3.507,0,0,0-2.66-3.328A2.689,2.689,0,0,0,146.826-346.687Z"
                        transform="translate(-82.719 -100.719)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </span>
              </Form.Group>
              <div className="flex-start">
                <Form.Group className="mb-3">
                  <Form.Label>Change Color</Form.Label>
                  <div className="color-areas">
                    <input type="color" value="#26AC96" />
                  </div>
                </Form.Group>
                <div className="action">
                  <button>
                    <span>Set Pre-set Templates</span>
                  </button>
                  <button>Upload Templates</button>
                </div>
              </div>
            </Form>
          </div>
          <div className="col-xl-7 col-lg-7">
            <div className="">
              <div className="heading">
                <h2>Certificate Preview</h2>
              </div>
              <div className="preview">
                <Preview />
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12">
            <Form.Group className="mb-3">
              <Form.Label>Pre set Templates</Form.Label>
            </Form.Group>
            <div className="templates-list">
              <div className="template-item">
                <img src={CertificateOne} className="img-fluid" alt="" />
              </div>
              <div className="template-item">
                <img src={CertificateTwo} className="img-fluid" alt="" />
              </div>
              <div className="template-item">
                <img src={CertificateThree} className="img-fluid" alt="" />
              </div>
              <div className="template-item">
                <img src={CertificateFour} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="act-btn">
              <button>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SetCertificateTemplate;
