import http from "../http-common";
import authHeader from "./auth-header";

const token = JSON.parse(localStorage.getItem('token'));

const addEvent = (data) => {
  return http.post("api/insEnv", data, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }});
};

const getEventById = (baps) => {
  return http.get(`api/getEnv?baps=${baps}`, {
    headers: { 'Authorization': 'Bearer ' + token},
  });
};

const getEventByEId = (baps, eid) => {
  return http.get(`api/getEnv?baps=${baps}&eid=${eid}`, {
    headers: { 'Authorization': 'Bearer ' + token},
  });
};

const delEvent = (data) => {
  return http.post("api/evnDelto", data, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }});
};




const EventService = {
  addEvent,
  getEventById,
  getEventByEId,
  delEvent
};

export default EventService;
