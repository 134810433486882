import React, { useRef, useEffect } from "react";
import Iframe from "react-iframe";
import { useState } from "react";
import CourseService from "../services/course.service";
import AuthService from "../services/auth.service";
import LoadingGif from "../../src/assets/images/Loading.gif";
import ReportService from "../services/report.service";

const DemoPlayer = ({
  aid,
  cid,
  getCourseActivities,
  getCourseCompSts,
  activitySts,
  attemptCount,
  attempt,
  fetchActivityResults,
  file_name,
  activity_type,
  comp_sts,
  activity_name,
  enrolid,
}) => {
  console.log("player enrollid", enrolid);
  const iframeRef = useRef(null);
  const [isFunctionRunning, setIsFunctionRunning] = useState(false);
  const videoRef = useRef(null);
  const [duration, setDuration] = useState(0);
  // const [currentTime, setCurrentTime] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [mediaStatus, setMediaStatus] = useState("");
  const [activityResult, setActivityResult] = useState({
    strscore_max: "",
    strscore_min: "",
    strscore_raw: "",
    strsession_time: "",
    strlesson_status: "",
    course_id: cid,
    activity_id: aid,
    activity_typeid: "",
    student_id: "",
    enrollid: enrolid,
  });

  const isSafari = navigator.appVersion.indexOf("Safari") >= 0;
  const isChromeless = false;
  const isResizeable = false;
  const contentWidth = 1300;
  const contentHeight = 791;

  const [startPage, setStartPage] = useState(
    "https://aeonlms.aeonsoftware.net/cit/uploads/extracted/MNN_xAPI/index_lms.html"
  );
  const browserSize = "optimal";

  const launchContent = () => {
    let width = window.screen.availWidth;
    let height = window.screen.availHeight;

    if (
      width > contentWidth &&
      height > contentHeight &&
      browserSize !== "fullscreen"
    ) {
      width = contentWidth;
      height = contentHeight;

      if (!isSafari && !isChromeless) {
        width += 17;
      }
    }

    let options = `width=${width},height=${height}`;
    options += isResizeable ? ",resizable=yes" : ",resizable=no";

    if (isChromeless) {
      options += ",status=0,toolbar=0,location=0,menubar=0,scrollbars=0";
    } else {
      options += ",status=1,toolbar=1,location=1,menubar=1,scrollbars=1";
    }

    if (isSafari) {
      const newWindow = window.open("", "_blank", options);
      newWindow.location = startPage;
      window.onfocus = function () {
        // console.log("Window is focused!");
        console.log("Please do not open new tab while running course.");
        newWindow.close();
      };
    } else {
      const newWindow = window.open(startPage, "_blank", options);
      const script = newWindow.document.createElement("script");
      script.innerHTML = `
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      });
    `;
      newWindow.document.head.appendChild(script);
    }
  };

  const scromRun = (cid, aid, user, enrolid) => {
    let activityRes = {};

    setActivityResult((prevState) => ({
      ...prevState,
      enrollid: enrolid,
    }));

    const tspInit = (window, storage, prefix, callback) => {
      const data = {
        csid: cid,
        actid: aid,
      };

      // CourseService.launchCourse(data)
      //   .then((resp) => {})
      //   .catch((e) => console.log(e));

      prefix = typeof prefix !== "undefined" ? prefix : "";
      callback = typeof callback !== "undefined" ? callback : console.log;

      window.API = {};

      window.scormStatus = {
        lesson_status: "",
        score_raw: 0,
        score_max: 100,
        score_min: 0,
        session_time: 0,
        detailed_answers: {},
        lesson_location: "",
        student_id: user.user,
        course_id: cid,
        enrollid: enrolid,
      };

      window.API.LMSInitialize = function () {
        console.log("LMSInitialize");
      };
      window.API.LMSTerminate = function () {
        console.log("LMSTerminate");
      };

      window.API.LMSGetValue = function (varname) {
        varname = prefix + varname;
        var ret = storage.getItem(varname);
        if (
          ret == null &&
          varname.indexOf("_count", this.length - "_count".length) !== -1
        ) {
          ret = 0;
          storage.setItem(varname, ret);
        }

        return ret;
      };

      window.API.LMSSetValue = function (varname, varvalue) {
        varname = prefix + varname;

        var m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.id/);
        if (m != null) {
          storage.setItem(
            "{{scorm.id}}.cmi.interactions._count",
            parseInt(m[2]) + 1
          );
        }

        m = varname.match(/([0-9]+)\.cmi\.interactions\.([0-9]+)\.result/);
        if (m != null) {
          var key = storage.getItem(
            prefix + "cmi.interactions." + parseInt(m[2]) + ".id"
          );
          window.scormStatus.detailed_answers[key] = varvalue;
        }

        if (varname === prefix + "cmi.core.lesson_status")
          window.scormStatus.lesson_status = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });
        if (varname === prefix + "cmi.core.score.raw")
          window.scormStatus.score_raw = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_raw: window.scormStatus.score_raw,
        });
        if (varname === prefix + "cmi.core.score.max")
          window.scormStatus.score_max = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_max: window.scormStatus.score_max,
        });
        if (varname === prefix + "cmi.core.score.min")
          window.scormStatus.score_min = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_min: window.scormStatus.score_min,
        });
        if (varname === prefix + "cmi.core.session_time")
          window.scormStatus.session_time = varvalue;
        setActivityResult({
          ...activityResult,
          strsession_time: window.scormStatus.session_time,
        });

        if (varname === prefix + "cmi.core.lesson_location")
          window.scormStatus.lesson_location = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.scormStatus.lesson_status,
        });

        if (varname === prefix + "cmi.core.student_id") {
          window.scormStatus.student_id = varvalue;
          setActivityResult({
            ...activityResult,
            student_id: window.scormStatus.student_id,
          });
        }

        if (varname === prefix + "cmi.core.course_id") {
          window.scormStatus.course_id = varvalue;
          setActivityResult({
            ...activityResult,
            student_id: window.scormStatus.course_id,
          });
        }

        storage.setItem(varname, varvalue);
      };

      window.API.LMSCommit = function () {
        // console.log("LMSCommit");
        callback(window.scormStatus);
        return true;
      };

      window.API.LMSFinish = function () {
        // console.log("LMSFinish");
        const keys = Object.keys(localStorage);
        const newData = {};
        keys.forEach((key) => {
          if (key.startsWith(`CORS.${cid}.ACT.${aid}.UID.${user.user}.`)) {
            const storedValue = localStorage.getItem(key);
            newData[key] = storedValue;
          }
        });

        const scromdata = {
          enrollid: enrolid,
          userid: user.user,
          csid: cid,
          activity_id: aid,
          scrdata: newData,
        };

        ReportService.uploadScromData(scromdata)
          .catch((resp) => {
            console.log(resp.data);
          })
          .catch((e) => console.log(e));

        uploadResult(activityRes);
      };

      window.API.LMSGetLastError = function () {};
      window.API.LMSGetErrorString = function () {};
      window.API.LMSGetDiagnostic = function () {};
    };

    const supports_html5_storage = () => {
      try {
        return "localStorage" in window && window["localStorage"] !== null;
      } catch (e) {
        return false;
      }
    };

    if (!supports_html5_storage()) {
      window.localStorage = {};
    }

    tspInit(
      window,
      window.localStorage,
      `CORS.${cid}.ACT.${aid}.UID.${user.user}.`,
      function (progress) {
        var progressString = JSON.stringify(progress);
        localStorage.setItem("std_progress", progressString);
        activityRes = {
          strscore_max: progress.score_max,
          strscore_min: progress.score_min,
          strscore_raw: progress.score_raw,
          strsession_time: progress.session_time,
          strlesson_status: progress.lesson_status,
          course_id: cid,
          activity_id: aid,
          student_id: user.user,
          activity_typeid: "1",
          enrollid: enrolid,
        };
      }
    );
  };

  const xapiRun = (cid, aid, user, enrolid) => {
    let activityRes = {};

    setActivityResult((prevState) => ({
      ...prevState,
      enrollid: enrolid,
    }));

    const tspInit = (window, storage, prefix, callback) => {
      const data = {
        csid: cid,
        actid: aid,
      };

      // CourseService.launchCourse(data)
      //   .then((resp) => {})
      //   .catch((e) => console.log(e));

      prefix = typeof prefix !== "undefined" ? prefix : "";
      callback = typeof callback !== "undefined" ? callback : console.log;

      const lrsEndpoint = "https://cloud.scorm.com/lrs/8NLK8B8O4G/";
      const auth = "Basic " + btoa("b-wR8K2HiRlb0DV0Q9Y:k4YjZmNvTs7bcHsTEt4");

      const xapiSendStatement = (statement) => {
        fetch(lrsEndpoint + "/statements", {
          method: "POST",
          headers: {
            Authorization: auth,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(statement),
        })
          .then((resp) => console.log("Statement sent"))
          .catch((err) => console.log(err));
      };

      window.xapiStatus = {
        lesson_status: "",
        score_raw: 0,
        score_max: 100,
        score_min: 0,
        session_time: 0,
        detailed_answers: {},
        lesson_location: "",
        student_id: user.user,
        course_id: cid,
        enrollid: enrolid,
      };

      // xAPI: Record a statement for initialized action
      const initializedStatement = {
        actor: {
          mbox: `mailto:${user.email}`,
          name: user.name,
        },
        verb: {
          id: "http://adlnet.gov/expapi/verbs/initialized",
          display: { "en-US": "initialized" },
        },
        object: {
          id: `${startPage}`,
          definition: {
            name: { "en-US": "Course Initialization" },
          },
        },
        result: {
          score: {
            raw: window.xapiStatus.score_raw,
            max: window.xapiStatus.score_max,
            min: window.xapiStatus.score_min,
          },
        },
      };
      xapiSendStatement(initializedStatement);

      window.API = {};

      window.API.LMSInitialize = function () {
        console.log(
          "LMSInitialize (xAPI does not require explicit initialization)"
        );
      };

      window.API.LMSTerminate = function () {
        console.log("LMSTerminate");
        const terminatedStatement = {
          actor: {
            mbox: `mailto:${user.email}`,
            name: user.name,
          },
          verb: {
            id: "http://adlnet.gov/expapi/verbs/terminated",
            display: { "en-US": "terminated" },
          },
          object: {
            id: `http://your-domain.com/courses/${cid}`,
            definition: {
              name: { "en-US": "Course Terminated" },
            },
          },
        };
        xapiSendStatement(terminatedStatement);
      };

      window.API.LMSSetValue = function (varname, varvalue) {
        varname = prefix + varname;

        if (varname === prefix + "cmi.core.lesson_status")
          window.xapiStatus.lesson_status = varvalue;
        setActivityResult({
          ...activityResult,
          strlesson_status: window.xapiStatus.lesson_status,
        });

        if (varname === prefix + "cmi.core.score.raw")
          window.xapiStatus.score_raw = varvalue;
        setActivityResult({
          ...activityResult,
          strscore_raw: window.xapiStatus.score_raw,
        });

        if (varname === prefix + "cmi.core.session_time")
          window.xapiStatus.session_time = varvalue;
        setActivityResult({
          ...activityResult,
          strsession_time: window.xapiStatus.session_time,
        });

        // Record a statement for value changes
        const updatedStatement = {
          actor: {
            mbox: `mailto:${user.email}`,
            name: user.name,
          },
          verb: {
            id: "http://adlnet.gov/expapi/verbs/interacted",
            display: { "en-US": "interacted" },
          },
          object: {
            id: `${startPage}`,
            definition: {
              name: { "en-US": `Updated: ${varname}` },
            },
          },
          result: {
            score: {
              raw: window.xapiStatus.score_raw,
              max: window.xapiStatus.score_max,
              min: window.xapiStatus.score_min,
            },
            duration: window.xapiStatus.session_time,
          },
        };
        xapiSendStatement(updatedStatement);

        storage.setItem(varname, varvalue);
      };

      window.API.LMSCommit = function () {
        callback(window.xapiStatus);
        return true;
      };

      window.API.LMSFinish = function () {
        console.log("LMSFinish");
        const finishStatement = {
          actor: {
            mbox: `mailto:${user.email}`,
            name: user.name,
          },
          verb: {
            id: "http://adlnet.gov/expapi/verbs/completed",
            display: { "en-US": "completed" },
          },
          object: {
            id: `${startPage}`,
            definition: {
              name: { "en-US": "Course Finished" },
            },
          },
        };
        xapiSendStatement(finishStatement);

        uploadResult(activityRes);
      };

      window.API.LMSGetLastError = function () {};
      window.API.LMSGetErrorString = function () {};
      window.API.LMSGetDiagnostic = function () {};
    };

    const supports_html5_storage = () => {
      try {
        return "localStorage" in window && window["localStorage"] !== null;
      } catch (e) {
        return false;
      }
    };

    if (!supports_html5_storage()) {
      window.localStorage = {};
    }

    tspInit(
      window,
      window.localStorage,
      `CORS.${cid}.ACT.${aid}.UID.${user.user}.`,
      function (progress) {
        var progressString = JSON.stringify(progress);
        localStorage.setItem("std_progress", progressString);
        activityRes = {
          strscore_max: progress.score_max,
          strscore_min: progress.score_min,
          strscore_raw: progress.score_raw,
          strsession_time: progress.session_time,
          strlesson_status: progress.lesson_status,
          course_id: cid,
          activity_id: aid,
          student_id: user.user,
          activity_typeid: "1",
          enrollid: enrolid,
        };
      }
    );
  };

  const handlePlay = () => {
    const video = videoRef.current;
    if (video) {
      let durationInMinutes = Math.floor(video.duration / 60);
      // let durationInSeconds = durationInMinutes % 60;
      setDuration(Math.floor(video.duration));
      const intervalId = setInterval(() => {
        // const unit = "seconds";
        // setCurrentTime(Math.round(video.currentTime) + " " + unit);
        setTotalPlayTime((prevTotalPlayTime) => prevTotalPlayTime + 1);
      }, 1000);

      video.onpause = video.onended = () => {
        clearInterval(intervalId);
      };
    }
  };

  const handlePause = () => {
    uploadMediaRes();
  };

  const uploadResult = (data) => {
    const user = AuthService.getCurrentUser();

    console.log(data);

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    console.log("datanew", datanew);
    console.log("fetchResdata", fetchResdata);

    CourseService.uploadScromResult(data)
      .then((res) => {
        if (res.data !== false) {
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => console.log(e));
  };

  const uploadMediaRes = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: totalPlayTime,
      strlesson_status: mediaStatus,
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        if (res.data !== false) {
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleIframeLoad = () => {
    const user = AuthService.getCurrentUser();

    const datanew = {
      enrollid: enrolid,
      course_id: cid,
      activity_id: aid,
      student_id: user.user,
    };

    const fetchResdata = {
      enrollid: enrolid,
      corsid: cid,
      acrto: aid,
      baps: user.user,
    };

    const mediadata = {
      strscore_max: "",
      strscore_min: "",
      strscore_raw: "",
      strsession_time: "",
      strlesson_status: "completed",
      course_id: cid,
      activity_id: aid,
      activity_typeid: "2",
      duration: duration,
      student_id: user.user,
    };

    CourseService.uploadScromResult(mediadata)
      .then((res) => {
        if (res.data !== false) {
          setIsFunctionRunning(true);
          fetchActivityResults(datanew, fetchResdata);
          getCourseActivities();
          getCourseCompSts(user.user, cid);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsFunctionRunning(false);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setActivityResult({ ...activityResult, student_id: user.user });
    xapiRun(cid, aid, user, enrolid);

    if (totalPlayTime >= duration) {
      setMediaStatus("completed");
    } else {
      setMediaStatus("incomplete");
    }

    const disableScreenshot = (e) => {
      navigator.clipboard.writeText("");
      console.log("screenshot disabled");
    };

    document.addEventListener("keyup", disableScreenshot);

    return () => {
      document.removeEventListener("keyup", disableScreenshot);
    };
  }, [aid]);

  return (
    <>
      {/* <button
        onClick={() => {
          launchContent();
          setStartPage(
            "https://aeonlms.aeonsoftware.net/cit/uploads/extracted/MNN_xAPI/index_lms.html"
          );
        }}
        className="launch"
      >
        Launch
      </button> */}

      <Iframe
        url={
          "https://aeonlms.aeonsoftware.net/cit/uploads/extracted/MNN_xAPI/index_lms.html"
        }
        id="pdfPlayer"
        width="640px"
        height="320px"
        display="block"
        position="relative"
        ref={iframeRef}
        className="mb-3"
      />
    </>
  );
};
export default DemoPlayer;
