

// import http from 'http';
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getAllCerts = (baps) => {
  return http.get(`/api/fetchcert?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getCertEleByCorsId = (corsid) => {
  return http.get(`/api/fetCeraplele?corsid=${corsid}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getCertBycond = (data) => {
  return http.post(`/api/fetstcert`, JSON.stringify(data), { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};


const uploadCert = (data) => {
  return http.post(`/api/fetdfdCert`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data'}});
}



const CertService = {
    getAllCerts,
    getCertEleByCorsId,
    getCertBycond,
    uploadCert
};

export default CertService;