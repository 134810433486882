import React from "react";
import "./CourseManagement.css";
import "./publishCourse.css";
import Filters from "./Filters";
import ActionFlex from "./ActionFlex";
import { useLocation } from "react-router-dom";
import CoursesList from "../../../common/CoursesList";
import { useState } from "react";
import EstoreCoursesList from "../../../common/EstoreCourseList";

const ManageCourses = ({ permissions, roleid }) => {
  // const location = useLocation();
  // const {  } = location.state;
  const [selectedOccuCat, setSelectedOccuCat] = useState("");
  const [selectedCourseCat, setSelectedCourseCat] = useState("");
  const [isLms, setIsLms] = useState(true);

  const handleOccCat = (e) => {
    setSelectedOccuCat(e.target.value);
  };

  const handleCourseCat = (e) => {
    setSelectedCourseCat(e.target.value);
  };

  const handleFilterSubmit = () => {
    // handleFilterSubmit
  };

  const handleLms = () => {
    setIsLms(true);
  };

  const handleEstore = () => {
    setIsLms(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters
              handleOccCat={handleOccCat}
              handleCourseCat={handleCourseCat}
              handleFilterSubmit={handleFilterSubmit}
            />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex permissions={permissions} roleid={roleid} />
                <div className="search-container-courses">
                  <div className="searchBarCourses">
                    <input
                      type="text"
                      className="global_filter"
                      id="global_filter"
                      placeholder="Start typing to search..."
                    />
                  </div>
                  <div className="estoreLms-btn-course">
                    <button
                      className={isLms ? "lms-btn active" : "lms-btn"}
                      onClick={handleLms}
                    >
                      LMS
                    </button>
                    <button
                      className={!isLms ? "lms-btn active" : "lms-btn"}
                      onClick={handleEstore}
                    >
                      E-store
                    </button>
                  </div>
                </div>
                {isLms ? (
                  <CoursesList
                    permissions={permissions}
                    roleid={roleid}
                    selectedOccuCat={selectedOccuCat}
                    selectedCourseCat={selectedCourseCat}
                  />
                ) : (
                  <EstoreCoursesList
                    permissions={permissions}
                    roleid={roleid}
                    selectedOccuCat={selectedOccuCat}
                    selectedCourseCat={selectedCourseCat}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCourses;
