
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getSubOrg = (baps) => {
  return http.get(`/api/suborgdata?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getSubOrgById = (sorgid, baps) => {
  return http.get(`/api/suborgbid?sorgid=${sorgid}&baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createSubOrg = (data) => {
    return http.post("/api/suborgins", data, { headers: { 'Authorization': 'Bearer ' + token, "Content-Type": 'multipart/form-data'}});
};

const updateSubOrg = (data) => {
  return http.post("/api/upsuborg",  data,  { headers: { 'Authorization': 'Bearer ' + token, "Content-Type": 'multipart/form-data'}});
};

const deleteSubOrg = (baps, dsorgid) => {
  return http.delete(`/api/delsuborg?baps=${baps}&dsorgid=${dsorgid}`,  { headers: { 'Authorization': 'Bearer ' + token}});
};

const SubOrganisationService = {
    getSubOrg,
    getSubOrgById,
    createSubOrg,
    updateSubOrg,
    deleteSubOrg

};

export default SubOrganisationService;