import React, { useState, useEffect } from "react";
import "./Reports.css";
import { Modal, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

import "../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import UserService from "../../services/user.service";
import ReportService from "../../services/report.service";
import AuthService from "../../services/auth.service";

const Reports = () => {
  const [selectedOption, setSelectedOption] = useState();
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disableBulkDelete, setDisableBulkDelete] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const [did, setDid] = useState("");
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");

  const [roleid, setRoleid] = useState("");

  const [learners, setLearners] = useState([]);

  const [options, setOptions] = useState([]);

  const [activeLearners, setActiveLearners] = useState("0");
  const [inactiveLearners, setInactiveLearners] = useState("0");
  const [unassigned, setUnassigned] = useState("0");

  const [courses, setCourses] = useState({
    selectedUser: [],
    delid: [],
    courseId: [],
  });

  const [allReports, setAllReports] = useState([]);

  const getAllReports = (uid) => {
    ReportService.getAllReports(uid).then((resp) => {
      setAllReports(resp.data);
    });
  };

  // const reports = [
  //   {
  //     id: "1",
  //     name: "Activity Log Report",
  //     category: "Users",
  //     schdeule: "",
  //   },
  // ];

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#allLearners")) {
      function filterGlobal() {
        $("#allLearners")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#allLearners")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#allLearners").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
    const user = AuthService.getCurrentUser();
    if (user) {
      getAllReports(user.user);
      setRoleid(user.role_id);
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleDeleteClose = () => setDeleteSelected(false);

  const handleDeleteShow = (e) => {
    setDeleteSelected(true);
  };

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    setSelectedValues(values);
    const valSplit = values.split(",");

    if (valSplit[0] !== "") {
      setCourses({ ...courses, courseId: valSplit });
    } else {
      setCourses({ ...courses, courseId: [] });
    }
  };

  const deleteSelectedUser = (e) => {
    e.preventDefault();
    const data = {
      delid: courses.delid,
    };

    UserService.deleteMultipleLearners(data)
      .then((response) => {
        setMessage("Selected Learners deleted successfully!");
        setDataUpload(true);
        setShow(false);
        setLearners(learners.filter((learner) => learner.user_id !== did));

        setDeleteSelected(false);
        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteLearner = (e) => {
    e.preventDefault();

    UserService.deleteLearners(did)
      .then((response) => {
        setMessage("Learner deleted successfully!");
        setDataUpload(true);
        setShow(false);
        setLearners(learners.filter((learner) => learner.user_id !== did));

        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer right">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="heading">
                    <h1>Reports</h1>
                  </div>
                  <div className="courses-act">
                    {/* <Link to="/AddReport" className="action-item">
                      <div>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 28.345 28.343"
                          >
                            <g id="add" transform="translate(-72.15 439.827)">
                              <path
                                id="Path_13773"
                                data-name="Path 13773"
                                d="M85.636-439.709a2.284,2.284,0,0,0-.663.447c-.594.594-.578.385-.578,6.375v5.3h-5.3c-5.99,0-5.782-.015-6.375.578a1.889,1.889,0,0,0,0,2.7c.594.594.385.578,6.375.578h5.3v5.3c0,5.99-.015,5.782.578,6.375a1.889,1.889,0,0,0,2.7,0c.594-.594.578-.385.578-6.375v-5.3h5.3c5.99,0,5.782.015,6.375-.578a1.889,1.889,0,0,0,0-2.7c-.594-.594-.385-.578-6.375-.578h-5.3v-5.3c0-5.99.015-5.782-.578-6.375A1.921,1.921,0,0,0,85.636-439.709Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        &nbsp;&nbsp;
                        <span>Add Report</span>
                      </div>
                    </Link> */}
                  </div>
                </div>
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>
                {dataUpload ? (
                  <>
                    <Alert className="m-auto mb-5" variant="success">
                      {message}
                    </Alert>
                  </>
                ) : (
                  <></>
                )}
                <div className="admin-users">
                  <div className="table-responsive">
                    <table className="table" id="allLearners">
                      <thead>
                        <tr>
                          <th>Report Name</th>
                          <th>Reports Category</th>
                          <th>Report Schedule</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleid === "1" || roleid === "2" ? (
                          <>
                            <tr>
                              <td>
                                <div className="user-flex">
                                  <div className="user-info">
                                    <span className="name">
                                      Activity Log Report
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span>Users</span>
                              </td>
                              <td>
                                <span>NA</span>
                              </td>
                              <td>
                                <div className="table-act">
                                  <button className="view">
                                    <Link to={"/ActivityLog"}>
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          id="Isolation_Mode"
                                          data-name="Isolation Mode"
                                          viewBox="0 0 24 24"
                                          width="18"
                                          height="18"
                                        >
                                          <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                          <circle cx="12" cy="12" r="4" />
                                        </svg>
                                      </span>
                                    </Link>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <td>
                            <div className="user-flex">
                              <div className="user-info">
                                <span className="name">
                                  Course Enrollment Report
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>Courses</span>
                          </td>
                          <td>
                            <span>NA</span>
                          </td>
                          <td>
                            <div className="table-act">
                              <button className="view">
                                <Link to={"/CourseEnrollment"}>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Isolation_Mode"
                                      data-name="Isolation Mode"
                                      viewBox="0 0 24 24"
                                      width="18"
                                      height="18"
                                    >
                                      <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                      <circle cx="12" cy="12" r="4" />
                                    </svg>
                                  </span>
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="user-flex">
                              <div className="user-info">
                                <span className="name">
                                  User Enrollment Report
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>Users</span>
                          </td>
                          <td>
                            <span>NA</span>
                          </td>
                          <td>
                            <div className="table-act">
                              <button className="view">
                                <Link to={"/UserEnrollment"}>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Isolation_Mode"
                                      data-name="Isolation Mode"
                                      viewBox="0 0 24 24"
                                      width="18"
                                      height="18"
                                    >
                                      <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                      <circle cx="12" cy="12" r="4" />
                                    </svg>
                                  </span>
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="user-flex">
                              <div className="user-info">
                                <span className="name">
                                  Activity Result Report
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>Activity</span>
                          </td>
                          <td>
                            <span>NA</span>
                          </td>
                          <td>
                            <div className="table-act">
                              <button className="view">
                                <Link to={"/ActivityResult"}>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Isolation_Mode"
                                      data-name="Isolation Mode"
                                      viewBox="0 0 24 24"
                                      width="18"
                                      height="18"
                                    >
                                      <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                      <circle cx="12" cy="12" r="4" />
                                    </svg>
                                  </span>
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="user-flex">
                              <div className="user-info">
                                <span className="name">
                                  Student Certificate Report
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>Course</span>
                          </td>
                          <td>
                            <span>NA</span>
                          </td>
                          <td>
                            <div className="table-act">
                              <button className="view">
                                <Link to={"/CertificateReports"}>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Isolation_Mode"
                                      data-name="Isolation Mode"
                                      viewBox="0 0 24 24"
                                      width="18"
                                      height="18"
                                    >
                                      <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                      <circle cx="12" cy="12" r="4" />
                                    </svg>
                                  </span>
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="user-flex">
                              <div className="user-info">
                                <span className="name">
                                  Course Feedback Report
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>Course</span>
                          </td>
                          <td>
                            <span>NA</span>
                          </td>
                          <td>
                            <div className="table-act">
                              <button className="view">
                                <Link to={"/FeedbackReport"}>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Isolation_Mode"
                                      data-name="Isolation Mode"
                                      viewBox="0 0 24 24"
                                      width="18"
                                      height="18"
                                    >
                                      <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                      <circle cx="12" cy="12" r="4" />
                                    </svg>
                                  </span>
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="user-flex">
                              <div className="user-info">
                                <span className="name">Payment History</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>Course</span>
                          </td>
                          <td>
                            <span>NA</span>
                          </td>
                          <td>
                            <div className="table-act">
                              <button className="view">
                                <Link to={"/PaymentHistory"}>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Isolation_Mode"
                                      data-name="Isolation Mode"
                                      viewBox="0 0 24 24"
                                      width="18"
                                      height="18"
                                    >
                                      <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                      <circle cx="12" cy="12" r="4" />
                                    </svg>
                                  </span>
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Learner</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this learner?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteLearner}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteSelected} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Selected Learners</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleDeleteClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete selected Learners?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleDeleteClose}>
            No
          </button>
          <button className="yes" onClick={deleteSelectedUser}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Reports;
