import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CourseService from "../../services/course.service";

export const fetchCourses = createAsyncThunk("fetchCourses", async (uid) => {
    const response = await CourseService.getCourse(uid);
    return response.data;
})

const courseSlice = createSlice({
    name: 'course',
    initialState: {
        isLoding: false,
        data: null,
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCourses.pending, (state, action) => {
            state.isLoding = true
        });
        builder.addCase(fetchCourses.fulfilled, (state, action) => {
            state.isLoding = false
            state.data = action.payload
        });
        builder.addCase(fetchCourses.rejected, (state, action) => {
            console.log('Error', action.payload)
            state.isError = true
        });
    }
});

export default courseSlice.reducer;