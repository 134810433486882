import React, { useEffect, useState } from "react";
import "./UserManagement.css";
import "./responsive.css";
import { Form } from "react-bootstrap";
import EditInput from "../../../assets/images/edit-input.png";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserService from "../../../services/user.service";
import PermissionService from "../../../services/permission.service";

import AuthService from "../../../services/auth.service";

const EditSystemManagers = () => {
  const location = useLocation();
  const { id } = location.state;

  const [validated, setValidated] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [userType, setUserType] = useState("2");

  const [allRoles, setAllRoles] = useState([]);

  const [userId, setUserId] = useState("");

  const [options, setOptions] = useState({});

  const [changeData, setChangeData] = useState(false);

  const [user, setUser] = useState({
    user_id: id,
    first_name: "",
    last_name: "",
    email: "",
    designation: "",
    role_id: "",
  });

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUserId(user.user);

    PermissionService.getAllRoles(user.user)
      .then((response) => {
        setAllRoles(response.data);
        setOptions(
          response.data.map((role) => ({
            value: role.id,
            label: role.name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });

    UserService.getSystemManagerById(user.user, id)
      .then((response) => {
        setUser(response.data[0]);
        setSelectedValues(response.data[0].role_id);
        setSelectedOptions(response.data[0].role_id);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const userInputChange = (event) => {
    // const { name, value } = event.target;
    // setUser({ ...user, [name]: value });
    setChangeData(true);
    const { name, value } = event.target;
    if (name !== "email" && name !== "organization") {
      if (/^[a-zA-Z.]*$/.test(value)) {
        setUser({ ...user, [name]: value });
      }
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const handleSelectOptionChange = (event) => {
    setSelectedOptions(event);
    const value = event ? event.value : "";

    setSelectedValues(value);
    setUser({ ...user, role_id: value });
    setChangeData(true);
  };

  const updateSystemManagers = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast.warn("*Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const data = {
      usp: userId,
      user_id: user.user_id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      designation: user.designation,
      organization: user.organization,
      role_id: user.role_id,
    };

    UserService.updateSystemManagers(data)
      .then((response) => {
        toast.success(response.data.massage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setChangeData(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer addUser">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="heading">
                    <h1>Edit System Manager</h1>
                  </div>
                  <div className="courses-act">
                    <Link to="/SystemManagers" className="action-item">
                      <div>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 19.379 19.382"
                          >
                            <g
                              id="left-arrow-in-circular-button-black-symbol"
                              transform="translate(-0.6 511.567)"
                            >
                              <path
                                id="Path_13057"
                                data-name="Path 13057"
                                d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        &nbsp;&nbsp;
                        <span>Back</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={updateSystemManagers}
                >
                  <ToastContainer />
                  <div className="add-user-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>First Name</Form.Label>
                          <div className="input-box">
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              name="first_name"
                              value={user.first_name}
                              onChange={userInputChange}
                              required
                            />
                            <span className="icon">
                              <img src={EditInput} width="14px" alt="" />
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Last Name</Form.Label>
                          <div className="input-box">
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              name="last_name"
                              value={user.last_name}
                              onChange={userInputChange}
                              required
                            />
                            <span className="icon">
                              <img src={EditInput} width="14px" alt="" />
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={user.email}
                          // onChange={userInputChange}
                          readOnly
                          required
                        />
                        <span className="icon">
                          <img src={EditInput} width="14px" alt="" />
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicDesignation"
                    >
                      <Form.Label>Designation</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="text"
                          placeholder="Enter Designation"
                          name="designation"
                          value={user.designation}
                          onChange={userInputChange}
                          required
                        />
                        <span className="icon">
                          <img src={EditInput} width="14px" alt="" />
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Roles</Form.Label>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: "none",
                            outline: "none",
                            borderRadius: "10px",
                            backgroundColor: state.isFocused
                              ? "var(--chips-color)"
                              : "var(--chips-color)",
                            color: "var(--whiteColor)",
                          }),
                          option: (baseStyles, { isFocused, isSelected }) => ({
                            ...baseStyles,
                            backgroundColor: isSelected
                              ? "var(--primary-color)"
                              : isFocused
                              ? "var(--secondary-color)"
                              : "var(--dark-bg)",
                            color: isSelected
                              ? "var(--dark-bg)"
                              : isFocused
                              ? "var(--dark-bg)"
                              : "var(--whiteColor)",
                          }),
                        }}
                        // className="react-select-container"
                        // classNamePrefix="react-select"
                        onChange={handleSelectOptionChange}
                        // value={selectedOptions}
                        options={options}
                        issearchable="true"
                        required
                        placeholder="Add Roles"
                        value={
                          Array.isArray(options)
                            ? options.filter(
                                (option) => user.role_id === option.value
                              )
                            : []
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="roles">
                    <div className="roles-flex">
                      <div className="define-role"></div>
                    </div>
                  </div>
                  <div className="act-btn">
                    <button disabled={!changeData}>Submit</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSystemManagers;
