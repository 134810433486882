import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));




const getOnboardStepData = () => {
    return http.get(`/api/fetchstdonboard`, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } });
};


const onboardStepOne = (data) => {
    return http.post(`/api/stdonboard`, data, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } });
};

const onboardStepTwo = (data) => {
    return http.post(`/api/stdboardsf`, data, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } });
};

const onboardSts = (data) => {
    return http.post(`/api/stdboardsts`, data, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } });
};


const StdOnboardService = {
    getOnboardStepData,
    onboardStepOne,
    onboardStepTwo,
    onboardSts
};

export default StdOnboardService;