
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getPathway = (baps) => {
  return http.get(`/api/pathdata?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getPathwayById = (pht) => {
  return http.get("/api/phwaydatabid?pht=" + pht, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createPathway = (baps, data) => {
    return http.post(`/api/phwayins?baps=${baps}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const updatePathway = (baps, data) => {
  return http.put(`/api/upphway?baps=${baps}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const deletePathway = (baps, dpathid) => {
  return http.delete(`/api/delphway?baps=${baps}&dpathid=${dpathid}`, { headers: {'Authorization': 'Bearer ' + token}});
};

const deleteSelectedPathway = (data) => {
  return http.post("/api/delmulphway", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const addStudentInPathway = (data) => {
  return http.post(`/api/pathstd`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const removeStudentInPathway = (data) => {
  return http.post(`/api/rmvpathstd`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};



const PathwayService = {
  getPathway,
  getPathwayById,
  createPathway,
  updatePathway,
  deletePathway,
  deleteSelectedPathway,
  addStudentInPathway,
  removeStudentInPathway
};

export default PathwayService;