import React, { useEffect, useState } from "react";
import "./UserManagement.css";
import "./responsive.css";
import { Form } from "react-bootstrap";
import EditInput from "../../../assets/images/edit-input.png";
import { Link, useParams, useLocation } from "react-router-dom";

import UserService from "../../../services/user.service";
import PermissionService from "../../../services/permission.service";

import AuthService from "../../../services/auth.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditLearner = () => {
  const location = useLocation();
  const { id } = location.state;

  const [validated, setValidated] = useState(false);

  const [allRoles, setAllRoles] = useState([]);

  const [options, setOptions] = useState({});

  const [userId, setUserId] = useState("");

  const [changeData, setChangeData] = useState(false);

  const [learner, setLearner] = useState({
    usp: "",
    user_id: "",
    first_name: "",
    last_name: "",
    email: "",
    qualification: "",
    organization: "",
  });

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setUserId(user.user);

    PermissionService.getAllRoles(user.user)
      .then((response) => {
        setAllRoles(response.data);
        setOptions(
          response.data.map((role) => ({
            value: role.id,
            label: role.name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });

    UserService.getLearnerById(user.user, id)
      .then((response) => {
        setLearner(response.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const learnerInputChange = (event) => {
    setChangeData(true);
    const { name, value } = event.target;
    if (name !== "email" && name !== "organization") {
      if (/^[a-zA-Z.]*$/.test(value)) {
        setLearner({ ...learner, [name]: value });
      }
    } else {
      setLearner({ ...learner, [name]: value });
    }
  };

  const updateLearners = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      toast.warn("*Please fill all required fields!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const data = {
      usp: userId,
      user_id: learner.user_id,
      first_name: learner.first_name,
      last_name: learner.last_name,
      email: learner.email,
      qualification: learner.qualification,
      organization: learner.organization,
    };

    UserService.updateLearners(data)
      .then((response) => {
        toast.success(response.data.massage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setChangeData(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer addUser">
              <div className="box-inner">
                <div className="action-flex">
                  <div className="heading">
                    <h1>Edit Learner</h1>
                  </div>
                  <div className="courses-act">
                    <Link to="/AllLearners" className="action-item">
                      <div>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 19.379 19.382"
                          >
                            <g
                              id="left-arrow-in-circular-button-black-symbol"
                              transform="translate(-0.6 511.567)"
                            >
                              <path
                                id="Path_13057"
                                data-name="Path 13057"
                                d="M9.381-511.546A9.911,9.911,0,0,0,4.162-509.4a11.83,11.83,0,0,0-1.457,1.468,10.165,10.165,0,0,0-1.878,3.88A8.145,8.145,0,0,0,.6-501.873a8.145,8.145,0,0,0,.228,2.181,10.088,10.088,0,0,0,1.931,3.945,11.828,11.828,0,0,0,1.468,1.457,10.164,10.164,0,0,0,3.88,1.878,8.146,8.146,0,0,0,2.181.228,8.146,8.146,0,0,0,2.181-.228,10.541,10.541,0,0,0,3.141-1.339A9.712,9.712,0,0,0,19-497.606a9.626,9.626,0,0,0,.914-2.94,12.941,12.941,0,0,0,0-2.655,9.626,9.626,0,0,0-.914-2.94,9.19,9.19,0,0,0-1.889-2.625,9.01,9.01,0,0,0-2.553-1.813,9.581,9.581,0,0,0-2.9-.91A14.607,14.607,0,0,0,9.381-511.546Zm1.1,5.618a.61.61,0,0,1,.25.766c-.03.068-.622.69-1.328,1.392L8.126-502.5l3.315.019c3.277.019,3.319.019,3.421.1a.536.536,0,0,1,.262.508.536.536,0,0,1-.262.508c-.1.08-.144.08-3.421.1l-3.315.019L9.4-499.977c.706.7,1.3,1.324,1.328,1.392a.607.607,0,0,1-.77.808c-.14-.053-3.683-3.588-3.751-3.744a1.386,1.386,0,0,1-.015-.683c.053-.133,3.581-3.679,3.736-3.751A.637.637,0,0,1,10.477-505.928Z"
                                transform="translate(0 0)"
                              />
                            </g>
                          </svg>
                        </span>
                        &nbsp;&nbsp;
                        <span>Back</span>
                      </div>
                    </Link>
                  </div>
                </div>

                <Form
                  noValidate
                  validated={validated}
                  onSubmit={updateLearners}
                >
                  <ToastContainer />
                  <div className="add-user-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>First Name</Form.Label>
                          <div className="input-box">
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              name="first_name"
                              value={learner.first_name}
                              onChange={learnerInputChange}
                              required
                            />
                            <span className="icon">
                              <img src={EditInput} width="14px" alt="" />
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Label>Last Name</Form.Label>
                          <div className="input-box">
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              name="last_name"
                              value={learner.last_name}
                              onChange={learnerInputChange}
                              required
                            />
                            <span className="icon">
                              <img src={EditInput} width="14px" alt="" />
                            </span>
                          </div>
                        </Form.Group>
                      </div>
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={learner.email}
                          // onChange={learnerInputChange}
                          readOnly
                          required
                        />
                        <span className="icon">
                          <img src={EditInput} width="14px" alt="" />
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicDesignation"
                    >
                      <Form.Label>Qualification</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="text"
                          placeholder="Enter Qualification"
                          name="qualification"
                          value={learner.qualification}
                          onChange={learnerInputChange}
                          required
                        />
                        <span className="icon">
                          <img src={EditInput} width="14px" alt="" />
                        </span>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicOrganization"
                    >
                      <Form.Label>Affiliation / organization</Form.Label>
                      <div className="input-box">
                        <Form.Control
                          type="text"
                          placeholder="Enter Organization"
                          name="organization"
                          value={learner.organization}
                          onChange={learnerInputChange}
                          required
                        />
                        <span className="icon">
                          <img src={EditInput} width="14px" alt="" />
                        </span>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="act-btn">
                    <button disabled={!changeData}>Submit</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLearner;
