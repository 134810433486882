import http from "../http-common";

const token = JSON.parse(localStorage.getItem("token"));

const getCartCourses = (data) => {
  return http.post("/api/stdcartinfo", data, {
    headers: { Authorization: "Bearer " + token, 'Content-Type': 'application/json' },
  });
};

const addCourseToCart = (data) => {
  return http.post("/api/storestdcart", data, {
    headers: { Authorization: "Bearer " + token, 'Content-Type': 'application/json' },
  });
};

const deleteCartCourse = (data) => {
  return http.post("/api/stdcartdel", data, {
    headers: { Authorization: "Bearer " + token, 'Content-Type': 'application/json' },
  });
};


const buyCartCourse = (data) => {
  return http.post(`/api/requesto`, data, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getPaymentStatus = () => {
  return http.post("/api/processData", {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
};

const getAllPayHistory = () => {
  return http.get(`/api/stdtrans`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getPayHistory = () => {
  return http.get(`/api/stdtransbid`, { headers: { 'Authorization': 'Bearer ' + token}});
};






const CartService = {
  getCartCourses,
  addCourseToCart,
  deleteCartCourse,
  buyCartCourse,
  getPaymentStatus,
  getAllPayHistory,
  getPayHistory
};

export default CartService;
