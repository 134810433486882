
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getCourseCat = () => {
  return http.get("/api/coursecategorydata", { headers: { 'Authorization': 'Bearer ' + token}});
};

const createCourseCat = (data) => {
    return http.post("/api/addcoursecategorydata", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};


const deleteCourseCat = (did) => {
  return http.delete("/api/delcoursecat/" + did, { headers: {'Authorization': 'Bearer ' + token}});
};

const onboardingSts = () => {
  return http.get("/api/onsts", { headers: {'Authorization': 'Bearer ' + token}});
}

const CoursecatService = {
  getCourseCat,
  createCourseCat,
  deleteCourseCat,
  onboardingSts
};

export default CoursecatService;