
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getServerSettings = (baps) => {
  return http.get(`/api/vrfiadd?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createServerSettings = (baps, data) => {
    return http.post(`/api/vrfd?baps=${baps}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const getThemeSettings = (baps) => {
  return http.get(`/api/thmdata?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createThemeSettings = (baps, data) => {
  return http.post(`/api/thmaindata?baps=${baps}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const getNotification = (baps) => {
  return http.get(`/api/natf?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const clearNotification = (baps) => {
  return http.delete(`/api/delnote?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const addCertificateelements = (baps, data) => {
  return http.post(`/api/cerfins?baps=${baps}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const getCertificateElements = (baps) => {
  return http.get(`/api/fetchcerfins?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const deleteCertificateElement = (baps, cerid) => {
  return http.delete(`/api/dcerdata?baps=${baps}&cerid=${cerid}`, { headers: {'Authorization': 'Bearer ' + token}});
};

const getActivityLog = (baps) => {
  return http.get(`/api/getactlog?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getCertTemps = (baps) => {
  return http.get(`/api/tempFetch?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const uploadCertTemps = (data) => {
  return http.post("/api/addtemp", data, { headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data'}});
};

const getTempByID = (tid, baps) => {
  return http.get(`/api/tempFetchbid?tid=${tid}&baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};







const SettingsService = {
  getServerSettings,
  createServerSettings,
  getThemeSettings,
  createThemeSettings,
  getNotification,
  clearNotification,
  addCertificateelements,
  getCertificateElements,
  deleteCertificateElement,
  getActivityLog,
  getCertTemps,
  uploadCertTemps,
  getTempByID,
};

export default SettingsService;