import React, { useState, useEffect } from "react";

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CourseTimeEngagement = () => {

  const data = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    datasets: [
      {
        label: "Course 1",
        data: [12, 16, 16, 12, 14, 10, 10, 14, 12, 15, 15, 10, 15, 16, 12],
        backgroundColor: "#56D364",
        borderColor: "#56D364",
        color: "#FFFFFF",
        fill: true,
        borderWidth: 1,
      },
      {
        label: "Course 2",
        data: [18, 20, 20, 16, 18, 18, 20, 18, 18, 22, 22, 20, 24, 24, 18],
        backgroundColor: "#1158C7",
        borderColor: "#1158C7",
        color: "#FFFFFF",
        fill: true,
        borderWidth: 1,
      },
      
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Activity",
        },
        grid: {
          drawOnChartArea: false
        }
      },
      y: {
        type: "linear",
        beginAtZero: true,
        title: {
          display: true,
          text: "Time",
        },
        grid: {
          drawOnChartArea: false
        }
      },
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  return (
    <>
      <Line data={data} options={options} style={{height: '200px'}} />
    </>
  );
};

export default CourseTimeEngagement;
