// import http from 'http';
import http from "../http-common";

const token = JSON.parse(localStorage.getItem('token'));

const getCohorts = (baps) => {
  return http.get(`/api/cohotsgata?baps=${baps}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const getCohortsById = (chtid) => {
  return http.get(`/api/cohotsgatabid?chtid=${chtid}`, { headers: { 'Authorization': 'Bearer ' + token}});
};

const createCohorts = (usp, data) => {
    return http.post(`/api/cohotsins?usp=${usp}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const updateCohorts = (usp, data) => {
  return http.put(`/api/upchts?usp=${usp}`, data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const deleteCohorts = (baps, chtdid) => {
  return http.delete(`/api/delchts?baps=${baps}&chtdid=${chtdid}`, { headers: {'Authorization': 'Bearer ' + token}});
};

const deleteSelectedCohorts = (data) => {
  return http.post("/api/delmulchts", data, { headers: {'Authorization': 'Bearer ' + token}, 'Content-Type': 'application/json'});
};

const addRemoveParticipants = (data) => {
  return http.post("/api/chtstd", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const addCoursesInCohots = (data) => {
  return http.post("/api/chtcors", data, { headers: {'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'}});
};

const CohortsService = {
  getCohorts,
  getCohortsById,
  createCohorts,
  updateCohorts,
  deleteCohorts,
  deleteSelectedCohorts,
  addRemoveParticipants,
  addCoursesInCohots
};

export default CohortsService;